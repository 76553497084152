import axios from 'axios';

class ProductModel {
  async packageList({ page, size, search_text, order_option }) {
    const {
      data: {
        code,
        data: { product_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/product/package`, {
      params: { page, size, search_text, order_option },
    });
    return { code, product_set };
  }

  async packageFavorite({ package_id }) {
    const {
      data: {
        code,
        data: { is_favorite, favorite_count, account_favorite_count },
      },
    } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/product/package/${package_id}/favorite`,
    );
    return { code, is_favorite, favorite_count, account_favorite_count };
  }

  async packageDetail({ package_id, event_code }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/product/package/${package_id}`, {
      params: { event_code },
    });
    let product = code === 200 ? data.product : null;
    let event = code === 200 ? data.event : null;
    return { code, product, event };
  }

  async kitList({ page, size, search_text }) {
    const {
      data: {
        code,
        data: { kit_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/product/kit`, {
      params: { page, size, search_text },
    });
    return { code, kit_set };
  }

  async kitFavorite({ kit_id }) {
    const {
      data: {
        code,
        data: { is_favorite, favorite_count, account_favorite_count },
      },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/product/kit/${kit_id}/favorite`);
    return { code, is_favorite, favorite_count, account_favorite_count };
  }

  async kitDetail({ kit_id, append_kit_id }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/product/kit/${kit_id}`, {
      params: { append_kit_id },
    });
    let kit = code === 200 ? data.kit : null;
    let append_kit_set = code === 200 ? data.append_kit_set : null;
    return { code, kit, append_kit_set };
  }
}

export default new ProductModel();
