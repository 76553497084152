import React, { useEffect, useState } from 'react';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { Widget } from '@typeform/embed-react/build';
import { useDispatch } from 'react-redux';
import '../../assets/scss/page/survey.scss';
import { Helmet } from 'react-helmet';
import CommonModel from '../../model/CommonModel';
import { alert_and_redirct } from '../../common';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';

const Survey = (props) => {
  const [survey_id, setSurveyId] = useState(null);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const { alert } = useDialogContext();

  useEffect(() => {
    setSurveyId(props.match.params.survey_id);
    CommonModel.typeform({ survey_id: props.match.params.survey_id }).then(({ code, data }) => {
      if (code === 200) {
        setTitle(data.title);
        dispatch(set_footer_type(0));
        dispatch(
          set_header_type(0, 2, {
            no_footer: true,
            no_top_btn: true,
            no_channel_talk_button: true,
            no_search_btn: true,
            title: data?.title,
          }),
        );
      } else {
        alert_and_redirct(alert, props.history, '잘못된 접근입니다.');
      }
    });
    props.ready();
  }, []);

  return (
    <div className='survey-container'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <input type='hidden' name='event_type' value={props.event_type} />
      {survey_id ? (
        <Widget
          id={survey_id}
          className='survey-form td-scroll'
          onSubmit={() => {
            if (props.event_type) {
              window.dataLayer.push({
                event: props.event_type,
              });
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default Survey;
