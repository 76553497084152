import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonModel from '../../../model/CommonModel';
import { stored_static_code } from '../../../redux/common/action';
import PropTypes from 'prop-types';
import Select from 'react-select';
import InputLabel from './InputLabel';
import { useMount } from '../../../util/BearuHooks';

const propTypes = {
  require: PropTypes.bool, // require 표시 여부
  force_error: PropTypes.bool, // error 상황 처리
  on_data_changed: PropTypes.func, // text, validated
};

const JobSelectBox = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const [isMount] = useMount();

  const [job_select_set, setJobSelectSet] = useState([]);
  const [is_error, setIsError] = useState(false);
  const [is_selected, setIsSelected] = useState(props?.default_value ? props.default_value : '');
  const require = props?.require ?? true;

  useEffect(() => {
    if (!CommonStore.static_code.job_class_set.length) {
      CommonModel.staticCode({ code_name: 'job_class_set' }).then(({ code_set }) => {
        if (isMount()) {
          dispatch(stored_static_code('job_class_set', code_set));
          setJobSelectSet(code_set);
        }
      });
    } else {
      setJobSelectSet(CommonStore.static_code.job_class_set);
    }
  }, []);

  useEffect(() => {
    if (props.force_error) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [props.force_error]);

  const new_job_select_set = job_select_set.map((job) => {
    return { value: job.id, label: job.name };
  });

  const custom_style = {
    option: (styles, state) => ({
      ...styles,
      textDecoration: state.isDisabled ? 'line-through' : 'inherit',
      color: '#ccc',
      cursor: state.isDisabled ? 'default' : 'pointer',
      pointerEvents: state.isDisabled ? 'none' : 'inherit',
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? '1px solid #242424' : '1px solid #CDCDCD',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid none',
      },
    }),
  };

  return (
    <div className='select-box-wrap'>
      <InputLabel label={'직종'} require={require} />
      <Select
        styles={{ ...custom_style }}
        value={new_job_select_set.find((option) => {
          return option.value === is_selected;
        })}
        placeholder='선택하세요'
        isSearchable={false}
        maxMenuHeight={142}
        options={new_job_select_set}
        onChange={(event) => {
          const text = event.value;
          setIsSelected(text);
          setIsError(false);
          props.on_data_changed && props.on_data_changed(text);
        }}
      />
      {is_error && <p className='default-msg'>필수항목입니다</p>}
    </div>
  );
};

JobSelectBox.propTypes = propTypes;

export default JobSelectBox;
