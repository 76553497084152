import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { stored_account_info } from '../../../redux/account/action';
import { useDialogContext } from './DialogContextProvider';
import AccountModel from '../../../model/AccountModel';

const ProfilePhotoModal = (props) => {
  //Dialog Context
  const { confirm, alert } = useDialogContext();
  const dialog_utils = props.dialog_utils || {};
  const dialog_props = props.dialog_props || {};

  //Redux
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);

  //Img Ref
  const file_ref = useRef(null);
  const profile_img_ref = useRef(null);

  //Img State
  const [img, setImg] = useState(null);
  const [preview, setPreview] = useState(null);
  const [is_del_img, setIsDelImg] = useState(0);

  useEffect(() => {
    if (AccountStore && AccountStore?.info?.picture_thumbnail_image_url) {
      setPreview(AccountStore.info.picture_thumbnail_image_url);
    } else {
      setPreview(`${process.env.REACT_APP_IMG_URL}/static/png/common/profile-default.png`);
    }
  }, []);

  const onCloseModalBtn = async () => {
    if (img || is_del_img) {
      if (await confirm('변경사항이 있습니다.\n창을 닫으시겠습니까?')) {
        await alert('변경사항이 저장되지 않았습니다.');
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
      }
    } else {
      dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    }
  };

  useEffect(() => {
    if (!img) {
      return;
    }
    const object_url = URL.createObjectURL(img);
    setPreview(object_url);
    return () => URL.revokeObjectURL(object_url);
  }, [img]);

  const onProfileImageChange = (e) => {
    setImg(e.target.files[0]);
    setIsDelImg(0);
  };

  const onProfileImageEdit = async () => {
    AccountModel.profileEdit({
      ...AccountStore.info,
      profile_img: img,
      is_del_img: is_del_img,
    }).then(async ({ account }) => {
      dispatch(stored_account_info(account));
      await alert('변경되었습니다.');
      dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
      await window.location.replace('/user/info');
    });
  };

  const onProfileImageRemove = () => {
    setImg(null);
    setPreview(`${process.env.REACT_APP_IMG_URL}/static/png/common/profile-default.png`);
    setIsDelImg(1);
    const input = document.querySelector('#profile-image');
    input.value = '';
  };

  return (
    <div className='td-modal'>
      <div className='td-dimmed' onClick={onCloseModalBtn} />
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>사진 수정 / 삭제하기</h3>
          <div
            className='modal-close-btn'
            style={{
              background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`,
            }}
            onClick={onCloseModalBtn}
          />
        </div>
        <div className='modal-section'>
          <div className='profile-edit-wrap'>
            <div
              className='user-profile-img'
              style={{ background: `url(${preview}) no-repeat center center/cover` }}
            />
            <ul className='control-btn-group'>
              <li onClick={onProfileImageRemove}>
                <div>
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/svg/mypage/icon-trash-can.svg`}
                    alt='프로필 이미지 삭제'
                  />
                </div>
                <h6>삭제</h6>
              </li>
              <li
                onClick={() => {
                  file_ref.current.click();
                }}
              >
                <div>
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/svg/mypage/icon-img-regist.svg`}
                    alt='프로필 이미지 등록/수정'
                    ref={profile_img_ref}
                  />
                </div>
                <h6 style={{ color: '#6236ff' }}>사진선택</h6>
              </li>
            </ul>
            <input
              onChange={onProfileImageChange}
              type='file'
              style={{ display: 'none' }}
              ref={file_ref}
              id='profile-image'
              accept='.jpg, .png'
            />
          </div>
          <div
            className='modal-button-container coupon-choice-btn-wrap'
            style={{ position: 'inherit' }}
          >
            <div className='coupon-choice-btn td-btn active' onClick={onProfileImageEdit}>
              수정완료
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProfilePhotoModal);
