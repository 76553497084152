import React, { useState } from 'react';
import { useObserver } from '../../../util/BearuHooks';
import { useSelector } from 'react-redux';

const TopButton = () => {
  const [is_top, setIsTop] = useState(false);
  const CommonStore = useSelector((state) => state.CommonStore);
  const no_top_btn = CommonStore.header.header_set?.no_top_btn;

  const on_ref = useObserver(
    { threshold: 0 },
    () => {
      if (!no_top_btn) setIsTop(true);
    },
    null,
    [no_top_btn],
  );

  const off_ref = useObserver(
    { threshold: 0 },
    () => {
      if (!no_top_btn) setIsTop(false);
    },
    null,
    [no_top_btn],
  );

  const onTopBtnClick = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <div style={no_top_btn ? { display: 'none' } : {}}>
      <div
        style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '1px' }}
        ref={off_ref}
      />
      <div
        className={'td-top-btn' + (is_top ? ' fade-in-right-anim' : ' fade-out-right-anim')}
        onClick={onTopBtnClick}
      />
      <div
        ref={on_ref}
        style={{
          position: 'absolute',
          left: 0,
          top: window.innerHeight + 100,
          width: '100%',
          height: '1px',
        }}
      />
    </div>
  );
};

export default TopButton;
