import React from 'react';
import '../../assets/scss/page/ieerror.scss';

const IEError = () => {
  return (
    <div className='td-wrapper error_container'>
      <img
        style={{ width: '140px' }}
        src={`${process.env.REACT_APP_IMG_URL}/static/png/common/logo_black.png`}
        alt='베어유'
      />
      <p style={{ fontWeight: 'bold', marginTop: '30px', fontSize: '24px', marginLeft: '10px' }}>
        베어유는 해당 브라우저를 더 이상 지원하지 않습니다.
      </p>
      <p style={{ marginTop: '16px', marginLeft: '10px' }}>
        베어유를 이용하려면 하단의 브라우저로 이용해 주세요!
      </p>
      <div className='browser-list'>
        <a href='https://www.google.com/chrome'>
          <div className='browser-card'>
            <img
              alt='chrome-logo'
              src='https://drv987r6zjtyw.cloudfront.net/static/svg/common/chrome-logo.svg'
            />
            <p style={{ marginTop: '8px', fontWeight: 'bold' }}>Chrome</p>
            <p style={{ marginTop: '8px' }}>다운로드</p>
          </div>
        </a>
        <a href='https://whale.naver.com/download'>
          <div className='browser-card'>
            <img
              alt='chrome-logo'
              src='https://drv987r6zjtyw.cloudfront.net/static/png/common/whale_logo.png'
            />
            <p style={{ marginTop: '8px', fontWeight: 'bold' }}>Whale</p>
            <p style={{ marginTop: '8px' }}>다운로드</p>
          </div>
        </a>
        <a href='https://www.microsoft.com/ko-kr/edge'>
          <div className='browser-card'>
            <img
              alt='chrome-logo'
              src='https://drv987r6zjtyw.cloudfront.net/static/svg/common/edge_logo.svg'
            />
            <p style={{ marginTop: '8px', fontWeight: 'bold' }}>Edge</p>
            <p style={{ marginTop: '8px' }}>다운로드</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default IEError;
