import React, { useEffect, useRef, useState } from 'react';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import '../../assets/scss/page/curation.scss';
import CommonModel from '../../model/CommonModel';
import CourseCard2 from './component/CourseCard2';
import { alert_and_redirct } from '../../common';
import PackageCard2 from './component/PackageCard2';
import { useDialogContext } from './dialog/DialogContextProvider';

const Curation = (props) => {
  const dispatch = useDispatch();
  const [curation, setCuration] = useState(null);
  const _is_mount = useRef(false);
  const list_ref = useRef(null);
  const { alert } = useDialogContext();

  useEffect(() => {
    _is_mount.current = true;
    dispatch(set_header_type(3, null, null));
    dispatch(set_footer_type(0));
    CommonModel.curationDetail({ id: props.match.params.id }).then(({ code, curation }) => {
      if (_is_mount.current) {
        if (curation && (curation.course_set.length > 0 || curation.product_set.length > 0)) {
          setCuration(curation);
          props.ready();
          list_ref.current.style.minHeight = 'inherit';
        } else {
          alert_and_redirct(alert, props.history, '잘못된 접근입니다.');
        }
      }
    });
    return () => {
      _is_mount.current = false;
    };
  }, []);

  return (
    <div className='curation-container'>
      <Helmet>
        <title>{curation?.title}</title>
      </Helmet>

      <div className='curation-top-header'>
        <div className='td-wrapper-v2 curation'>
          {curation ? (
            <>
              <div className='title-wrap'>
                <h2>{curation?.title}</h2>
                <p>{curation?.short_description}</p>
                <ul className='box-wrap'>
                  {curation?.course_set.length ? (
                    <li>
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/icon-play-box-purple.png`}
                        alt='단과 강의'
                      />
                      강의 <span>{curation?.course_set.length}개</span>
                    </li>
                  ) : null}
                  {curation?.product_set.length ? (
                    <li>
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/icon-play-box-purple.png`}
                        alt='패키지 강의'
                      />
                      패키지 <span>{curation?.product_set.length}개</span>
                    </li>
                  ) : null}
                  {curation?.avg_rating ? (
                    <li>
                      <img
                        src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/icon-star-purple.png`}
                        alt='평점'
                      />
                      평점 <span>{curation?.avg_rating.toFixed(1)}점</span>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className='bg-img-icon-wrap'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/bg-asset-1.png`}
                  alt='큐레이션 배경 아이콘'
                />
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/bg-asset-2.png`}
                  alt='큐레이션 배경 아이콘'
                />
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/bg-asset-3.png`}
                  alt='큐레이션 배경 아이콘'
                />
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/bg-asset-4.png`}
                  alt='큐레이션 배경 아이콘'
                />
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/curation/bg-asset-5.png`}
                  alt='큐레이션 배경 아이콘'
                />
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className='curation-wrapper' ref={list_ref} style={{ minHeight: 500 }}>
        <div className='curation-focus'>
          <h4>FOCUS</h4>
          <p>{curation?.description}</p>
        </div>

        {curation?.product_set && curation?.product_set.length > 0 && (
          <div className='curation-list'>
            <h3 className='content-title type1'>패키지 목록</h3>
            <div className='course-list overview-list'>
              {curation.product_set.map((product) => {
                return <PackageCard2 product={product} key={product.id} is_add_button={true} />;
              })}
            </div>
          </div>
        )}

        {curation?.course_set && curation?.course_set.length > 0 && (
          <div className='curation-list'>
            <h3 className='content-title type1'>강의 목록</h3>
            <div className='course-list overview-list'>
              {curation.course_set.map((course) => {
                return <CourseCard2 course={course} key={course.id} is_add_button={true} />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Curation;
