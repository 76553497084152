import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import '../../assets/scss/page/sign_in.scss';
import AccountModel from '../../model/AccountModel';
import { stored_account_info } from '../../redux/account/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import { useMount } from '../../util/BearuHooks';
import { encryptData } from '../../util/crypto-utils';
import TemplateInput from '../_common/_template/TemplateInput';
import InputLabel from '../_common/Input/InputLabel';

const ForceSignIn = (props) => {
  const dispatch = useDispatch();
  const { alert } = useDialogContext();
  const [isMount] = useMount();

  const createSessionData = (account) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const jsonData = JSON.stringify({
      sessionId: uuidv4().replaceAll('-', ''),
      account: account,
    });
    return encryptData(jsonData, secretKey);
  };

  const [accountId, setAccountId] = useState('');

  const onSignIn = async () => {
    const { code, account } = await AccountModel.forceSignIn(accountId);

    if (isMount()) {
      if (code === 200) {
        dispatch(stored_account_info(account));
        Cookies.set('ebookAuth', `${account.id}`, { domain: '.bear-u.com', path: '/', expires: 3 });

        AccountModel.bgdCheck().then(({ data }) => {
          if (data?.bgd?.length > 0) {
            const isBgdSessionId = Cookies.get('bgdSessionId');
            if (isBgdSessionId) {
              Cookies.remove('bgdSessionId', { domain: '.bear-u.com', path: '/' });
            }
            const sessionData = createSessionData(account);
            Cookies.set('bgdSessionId', `${sessionData}`, {
              domain: '.bear-u.com',
              path: '/',
              expires: 14,
            });
          }
        });
      } else if (code === 4505) {
        await alert('탈퇴한 계정입니다.');
      } else if (code === 4506) {
        await alert('이용정지 계정입니다.');
      } else if (code === 4500) {
        await alert('이메일 혹은 비밀번호를 확인해주세요.');
      } else {
        await alert('오류가 발생하였습니다.');
      }
    }
  };

  return (
    <div className={'user-container init-login'}>
      <div className='td-wrapper'>
        <h2 className='common-center-title type3'>로그인</h2>
        <div className='template-input-wrap'>
          <form>
            <InputLabel label='접속할 계정번호' require={require} />
            <TemplateInput
              input_props={{
                id: 'accountId',
                placeholder: '계정번호를 입력해주세요',
                defaultValue: props?.default_value,
                autoFocus: props?.auto_focus,
                ref: props?.input_list_ref,
                onChange: (text) => {
                  setAccountId(text);
                },
              }}
            />
            <div className='login-btn'>
              <input
                type='button'
                value='로그인'
                className='td-btn'
                onClick={() => {
                  onSignIn();
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForceSignIn;
