import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { init_comma } from '../../../common';
import { dialog_type_custom, useDialogContext } from './DialogContextProvider';
import PropTypes from 'prop-types';
import AccountModel from '../../../model/AccountModel';
import CouponModal from './CouponModal';
import moment from 'moment';

const propTypes = {
  purchase_type: PropTypes.string,
  course_id: PropTypes.number,
  ebook_id: PropTypes.number,
  package_id: PropTypes.number,
  selectedCouponIds: PropTypes.array,
  setCouponIds: PropTypes.func,
  is_extend_course: PropTypes.bool,
};

const CouponChoiceModal = (props) => {
  //Modal
  const { showDialog, confirm, alert } = useDialogContext();
  const dialog_props = props.dialog_props || {};
  const dialog_utils = props.dialog_utils || {};

  //Coupon
  const [is_active, setIsActive] = useState(false);
  const [coupon_set, setCouponSet] = useState([]);
  const [select_index, setSelectIndex] = useState(0);
  const _is_mount = useRef(false);
  const AccountStore = useSelector((state) => state.AccountStore);

  useEffect(() => {
    _is_mount.current = true;
    AccountModel.myCouponList().then(({ coupon_set }) => {
      if (_is_mount.current) {
        setCouponSet(coupon_set);
      }
    });
  }, []);

  const productTypeMap = {
    course: 1,
    package: 2,
    kit: 3,
    ebook: 4,
  };
  const onSelectCoupon = async () => {
    let product_type = productTypeMap[props.purchase_type] || 2; // 2는 기존 코드와 호환하기 위한 방어코드
    let is_course_product_only_check = false;
    let validate = false;
    if (coupon_set[select_index].coupon_course_set.length) {
      is_course_product_only_check = true;
      coupon_set[select_index].coupon_course_set.forEach((data) => {
        if (data.course_id === props.course_id) {
          validate = true;
        }
      });
    }
    if (coupon_set[select_index].coupon_product_set.length) {
      is_course_product_only_check = true;
      coupon_set[select_index].coupon_product_set.forEach((data) => {
        if (data.product_id === props.package_id) {
          validate = true;
        }
      });
    }
    if (coupon_set[select_index].coupon_ebook_set.length) {
      is_course_product_only_check = true;
      coupon_set[select_index].coupon_ebook_set.forEach((data) => {
        if (data.ebook_id === props.ebook_id) {
          validate = true;
        }
      });
    }

    if (!is_course_product_only_check) {
      if (!coupon_set[select_index].allow_set.length) {
        validate = true;
      }
      coupon_set[select_index].allow_set.forEach((allow) => {
        if (allow.product_type_id === product_type) {
          validate = true;
        }
      });
    }

    // 연장하기 결제이면서 연장하기가 불가능한 경우
    if (props.is_extend_course && !coupon_set[select_index].can_use_at_extend_course) {
      validate = false;
    }

    if (validate) {
      if (await confirm('해당 쿠폰을 적용시키겠습니까?')) {
        dialog_props.component_props.setCouponIds([coupon_set[select_index].id]);
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
      }
    } else {
      await alert('해당 제품 유형에 사용할 수 없는 쿠폰 입니다.');
    }
  };

  const onCouponListClick = (target_index) => {
    setSelectIndex(target_index);
    if (target_index === select_index) setIsActive(!is_active);
    else setIsActive(true);
  };

  const onCloseModalBtn = () => {
    dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
  };

  return (
    <>
      <div className='td-modal coupon-choice-modal'>
        <div className='td-dimmed' onClick={onCloseModalBtn} />
        <div className='modal-content'>
          <div className='modal-header'>
            <h3>할인 쿠폰</h3>
            <div
              className='modal-close-btn'
              style={{
                background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`,
              }}
              onClick={onCloseModalBtn}
            />
          </div>
          <div className='modal-section'>
            <div
              className='coupon-enrollment-btn-wrap'
              onClick={() => {
                showDialog({
                  type: dialog_type_custom,
                  component: CouponModal,
                });
              }}
            >
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-add-purple.svg`}
                alt='수강코드/쿠폰 등록하기'
              />
              <span>쿠폰 등록하기</span>
            </div>
            <ul className='coupon-list td-scroll'>
              {AccountStore.coupon_count ? (
                <>
                  {coupon_set.map((coupon, index) => {
                    const target_select_index = select_index === index;
                    const isSelected = props.selectedCouponIds.includes(coupon.id);
                    const couponAllow = [
                      ...(coupon.allow_set
                        ?.filter((allow) => allow.product_type_id <= 4)
                        .map((allow) => allow.product_type_name) || []),
                      ...(coupon.coupon_course_set?.map((course) => course.course_short_title) ||
                        []),
                      ...(coupon.coupon_product_set?.map(
                        (product) => product.product_short_title,
                      ) || []),
                      ...(coupon.coupon_ebook_set?.map((ebook) => ebook.ebook_title) || []),
                    ].join(', ');
                    const allCanUse =
                      !coupon.coupon_course_set?.length &&
                      !coupon.coupon_product_set?.length &&
                      !coupon.coupon_ebook_set?.length &&
                      !coupon.allow_set?.length;
                    const canUseCoupon =
                      !isSelected &&
                      (coupon.coupon_course_set?.find((c) => c.course_id === props.course_id) ||
                        coupon.coupon_product_set?.find((p) => p.product_id === props.package_id) ||
                        coupon.coupon_ebook_set?.find((e) => e.ebook_id === props.ebook_id) ||
                        coupon.allow_set?.some(
                          (a) => a.product_type_id === productTypeMap[props.purchase_type],
                        ) ||
                        allCanUse);

                    return (
                      <li
                        key={coupon.id}
                        onClick={() => (canUseCoupon ? onCouponListClick(index) : null)}
                        className={`${target_select_index && is_active ? 'selected' : ''} ${canUseCoupon ? '' : 'disabled'}`}
                      >
                        <h6 className='coupon-discount-amount'>
                          {coupon.price_display_name ?? init_comma(coupon.discounts) + '원'}
                        </h6>
                        <div className='coupon-info-wrap'>
                          <div className='coupon-basic-info'>
                            <span className='coupon-title'>{coupon.title}</span>
                            <span className='coupon-category'>
                              {allCanUse ? '전체' : ''}
                              {couponAllow.length > 0 && `${couponAllow} 전용`}
                            </span>
                          </div>
                          <div className='coupon-expire-date'>
                            {`${moment(coupon.expire_time).diff(moment(), 'days')}일 뒤 만료 `}(
                            {moment(coupon.expire_time).subtract(1, 'second').format('YYYY-MM-DD')})
                          </div>
                        </div>
                        <div className='coupon-checkbox'>
                          {target_select_index && is_active ? (
                            <img
                              src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-checkbox-check.svg`}
                              alt=''
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-checkbox.svg`}
                              alt=''
                            />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </>
              ) : (
                <li className='empty-coupon-list'>
                  <p>적용 가능한 쿠폰이 없습니다.</p>
                </li>
              )}
            </ul>
          </div>
          <div className='modal-button-container coupon-choice-btn-wrap'>
            <div
              className={'coupon-choice-btn td-btn' + (is_active ? ' active' : '')}
              onClick={onSelectCoupon}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CouponChoiceModal.propTypes = propTypes;

export default withRouter(CouponChoiceModal);
