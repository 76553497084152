import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stored_account_info } from '../../../redux/account/action';
import { useDialogContext } from './DialogContextProvider';
import { withRouter } from 'react-router-dom';
import PurchaseModel from '../../../model/PurchaseModel';
import CommonModel from '../../../model/CommonModel';
import AccountModel from '../../../model/AccountModel';
import { history_push } from '../../../common';

const CouponModal = (props) => {
  const CommonStore = useSelector((state) => state.CommonStore);

  //Dialog Context
  const { alert } = useDialogContext();
  const dialog_utils = props.dialog_utils || {};
  const dialog_props = props.dialog_props || {};

  //Redux
  const dispatch = useDispatch();

  //Coupon State
  const [select_index, setSelectIndex] = useState(null);
  const [is_active, setIsActive] = useState(false);
  const [code, setCode] = useState('');

  const modal_tab_set = [{ name: '수강코드' }, { name: '쿠폰' }];

  useEffect(() => {
    setSelectIndex(0);
  }, []);

  const onTabClick = (target_index) => {
    setSelectIndex(target_index);
    setCode('');
    setIsActive(false);
  };

  const onChange = (value) => {
    setCode(value);
    setIsActive(!!value);
  };

  const onCloseModalBtn = () => {
    dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
  };

  const onPublishTicket = () => {
    if (select_index === 0) {
      // 수강코드 등록
      PurchaseModel.purchase({
        purchase_type: 'ticket',
        currency_code: 'KRW',
        paymethod_id: 5,
        ticket_code: code,
      }).then(async ({ code, merchant_uid }) => {
        setCode('');
        if (code === 200) {
          history_push({
            history: props.history,
            url: '/purchase/result',
            get_object: {
              merchant_uid,
            },
          });
          await alert(
            '수강코드가 등록되었습니다. 등록된 클래스 및 전자책은 마이페이지에서 확인할 수 있습니다.',
          );
          await alert(
            '수강코드가 등록되었습니다. 등록된 클래스는 마이페이지에서 확인할 수 있습니다.',
          );
          dialog_utils.hideDialogAll && dialog_utils.hideDialogAll();
        } else if (code === 4806) {
          await alert('이미 등록된 수강코드입니다.');
        } else if (code === 4814) {
          await alert('수강코드 등록기간이 지났습니다.');
        } else {
          await alert('유효한 수강코드가 아닙니다.');
        }
      });
    } else {
      // 쿠폰 등록
      CommonModel.couponPublish({ coupon_code: code }).then(async ({ code }) => {
        setCode('');
        if (code === 200) {
          // @TODO 병곤 이거.. 왜 여깄지.. 나중에 한번 검토해보기
          AccountModel.validate({ access_token: null }).then(({ account }) => {
            dispatch(stored_account_info(account));
          });
          await alert('등록되었습니다. 등록하신 쿠폰은 마이페이지에서 확인 가능하십니다!');
        } else if (code === 4810) {
          await alert('모두 소진된 쿠폰입니다.');
        } else if (code === 4808) {
          await alert('이미 등록한 쿠폰입니다.');
        } else {
          await alert('사용 불가능한 쿠폰입니다.');
        }
      });
    }
  };

  return (
    <div className='td-modal'>
      <div className='td-dimmed' onClick={onCloseModalBtn} />
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>수강코드 / 쿠폰 등록하기</h3>
          <div
            className='modal-close-btn'
            style={{
              background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`,
            }}
            onClick={onCloseModalBtn}
          />
        </div>
        <div className='modal-section'>
          <div className='modal-tab-wrap'>
            <ul className='modal-tab-btn'>
              {modal_tab_set.map((tab, index) => {
                let is_select_index = select_index === index;
                return (
                  <li
                    key={index}
                    onClick={() => {
                      onTabClick(index);
                    }}
                    className={is_select_index ? 'selected' : ''}
                  >
                    {tab.name}
                  </li>
                );
              })}
            </ul>
            <div className='modal-tab-label'>
              <input
                type='text'
                value={code}
                placeholder={
                  select_index === 0 ? '수강코드를 입력해주세요.' : '쿠폰을 입력해주세요.'
                }
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
              <p>
                {select_index === 0
                  ? '· 전자책은 [마이페이지 > 내 전자책] 에서 확인할 수 있습니다.\n'
                  : '· 할인이 적용된 클래스에는 쿠폰 할인이 중복 적용되지 않을 수 있습니다.\n' +
                    '· 쿠폰 사용을 통한 구매 후 환불 시, 쿠폰은 재발급되지 않습니다.\n'}
                · 문의사항은 ‘{CommonStore.service.info.contact_email}’을 이용해주세요.
              </p>
            </div>
          </div>
          <div
            className='modal-button-container coupon-choice-btn-wrap'
            style={{ position: 'absolute' }}
          >
            <div
              className={'coupon-choice-btn td-btn' + (is_active ? ' active' : '')}
              onClick={onPublishTicket}
            >
              등록하기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CouponModal);
