import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

import AccountModel from '../../../model/AccountModel';
import LoadingSpinner from '../../_common/component/LoadingSpinner';
import EbookCard from '../component/EbookCard';
import { MYPAGE_EBOOK } from '../../_collections/mypage';
import EmptyList from './EmptyList';

const MyEbookList = () => {
  const [loading, setLoading] = useState(false);
  const [myEbookList, setMyEbookList] = useState(null);
  const AccountStore = useSelector((state) => state.AccountStore);

  const requestUpdateCourseList = async () => {
    setLoading(true);
    const { ebook_set } = await AccountModel.myEbookList({ access_token: null });
    setMyEbookList(ebook_set);
    setLoading(false);
  };

  useEffect(() => {
    requestUpdateCourseList();
  }, []);

  useEffect(() => {
    const IsEbookAuth = Cookies.get('ebookAuth');
    if (!IsEbookAuth && myEbookList?.length > 0) {
      Cookies.set('ebookAuth', `${AccountStore?.id}`, {
        domain: '.bear-u.com',
        path: '/',
        expires: 3,
      });
    }
  }, [myEbookList]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (myEbookList?.length === 0) return <EmptyList category={MYPAGE_EBOOK} />;

  return (
    <div className='my-ebook-list'>
      <div className='popup-desc'>
        <p>전자책을 보기 위해 팝업차단을 해제해주세요</p>
        <a
          href='https://glib-badge-aa5.notion.site/7709a41b1ce7434d92b95f76d6185358?pvs=4'
          target='_blank'
          rel='noreferrer'
        >
          팝업차단 해제 방법
        </a>
      </div>
      <div className='my-ebook-list-inner'>
        {myEbookList?.map((ebook) => (
          <div className='ebook-item' key={ebook.ebook_id}>
            <Link to={`/ebook/${ebook.ebook_id}`}>
              <EbookCard
                key={ebook.ebook_id}
                ebookCode={ebook.ebook_code}
                title={ebook.title}
                author={ebook.author_name}
                thumbnailUrl={ebook.main_image_url}
                category={ebook.category_name}
                description={<>무제한 구독중 &middot; 총 {ebook.total_pages}페이지</>}
                totalPage={ebook.total_pages}
                latestViewedPage={ebook.last_page}
                isMine
                isCompleted={ebook.is_completed}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyEbookList;
