import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDialogContext } from '../dialog/DialogContextProvider';
import PurchaseModal from '../dialog/PurchaseModal';
import CouponModal from '../dialog/CouponModal';
import ConvertRedirectUrl from '../../../util/ConvertRedirectUrl';

// nav index
export const SUPER_Z = 3;

const BottomNav = (props) => {
  const AccountStore = useSelector((state) => state.AccountStore);
  const CommonStore = useSelector((state) => state.CommonStore);
  const { findDialog, getCurrentDialogId } = useDialogContext();

  const [select_index, setSelectIndex] = useState(0);
  const [is_show, setIsShow] = useState(false);

  const no_bottom_nav = CommonStore.header.header_set?.no_bottom_nav;
  useEffect(() => {
    let dialog = findDialog(getCurrentDialogId());
    const cur_path = props.location.pathname;
    const split_path = cur_path.split('/');
    // if (cur_path === "/") {
    //     setSelectIndex(0);
    //     setIsShow(true);
    // }
    if (['overview'].includes(split_path[1])) {
      setSelectIndex(0);
      setIsShow(true);
    } else if (['ebook'].includes(split_path[1])) {
      setSelectIndex(1);
      setIsShow(true);
    } else if (['hardstudy'].includes(split_path[1])) {
      setSelectIndex(2);
      setIsShow(true);
    } else if (['user'].includes(split_path[1])) {
      if (dialog?.component === CouponModal) {
        setIsShow(false);
      } else if (dialog?.component === PurchaseModal) {
        setIsShow(false);
      } else {
        setSelectIndex(3);
        setIsShow(true);
      }
    } else if (['service'].includes(split_path[1])) {
      if (split_path.length > 2 && ['master', 'survey'].includes(split_path[2])) {
        setIsShow(false);
      } else {
        setSelectIndex(4);
        setIsShow(true);
      }
    } else if (['curation', 'event'].includes(split_path[1])) {
      setSelectIndex(4);
      setIsShow(true);
    } else if (['purchase'].includes(split_path[1])) {
      if (split_path.length > 2 && ['result'].includes(split_path[2])) {
        setSelectIndex(4);
        setIsShow(true);
      } else if (['purchase'].includes(split_path[1])) {
        setIsShow(false);
      }
    } else {
      setIsShow(false);
    }
  }, [props.location]);

  const onNavClick = (target_index) => {
    setSelectIndex(target_index);
  };

  return (
    <>
      {is_show ? (
        <div className='bottom-nav-wrap' style={no_bottom_nav ? { display: 'none' } : {}}>
          {nav_data_set.map((nav, index) => {
            return (
              <div
                key={index}
                className={'nav-item' + (select_index === index ? ' selected' : '')}
                onClick={() => {
                  onNavClick(index);
                }}
              >
                <ConvertRedirectUrl
                  is_not_bearu_link={nav.is_not_bearu_link}
                  redirect_url={
                    nav.name === 'My'
                      ? AccountStore
                        ? nav.redirect_url
                        : '/user/signin'
                      : nav.redirect_url
                  }
                >
                  {select_index === index ? (
                    <img src={nav.select_img_url} alt={nav.alt} />
                  ) : (
                    <img src={nav.default_img_url} alt={nav.alt} />
                  )}
                  <h6>{nav.name}</h6>
                </ConvertRedirectUrl>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

const nav_data_set = [
  {
    name: '클래스',
    default_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_course_dg.svg`,
    select_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_course_bl_active.svg`,
    alt: '클래스 페이지로 이동',
    redirect_url: '/overview/all',
  },
  {
    name: '전자책',
    default_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_ebook_dg.svg`,
    select_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_ebook_bl_active.svg`,
    alt: '전자책 페이지로 이동',
    redirect_url: '/ebook',
  },
  {
    name: '빡공단',
    default_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_challenge_dg.svg`,
    select_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_challenge_bl_active.svg`,
    alt: '빡공단 페이지로 이동',
    redirect_url: '/bgd',
  },
  /* //TODO:  2023-09-4, 17:47 -kwak: 0원 수강 비활성화 */
  /*{
        name: "0원 수강",
        default_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_payback_dg.svg`,
        select_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_payback_bl_active.svg`,
        alt: "0원 수강 페이지로 이동",
        redirect_url: "/package/262",
    },*/
  {
    name: 'My',
    default_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_my_dg.svg`,
    select_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_my_bl_active.svg`,
    alt: '마이페이지로 이동',
    redirect_url: '/user/mypage',
  },
  {
    name: '메뉴',
    default_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_menu_dg.svg`,
    select_img_url: `${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/ic_menu_bl_active.svg`,
    alt: '메뉴로 이동',
    redirect_url: '/service/more_info',
  },
];

export default withRouter(BottomNav);
