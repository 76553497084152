import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import { useMount } from '../../../util/BearuHooks';
import { set_tmp } from '../../../redux/common/action';
import { dialog_type_custom, useDialogContext } from '../../_common/dialog/DialogContextProvider';
import {
  MY_COURSE_FILTER_ALL,
  MY_COURSE_FILTER_EXPIRED,
  MY_COURSE_FILTER_ON_GOING,
} from '../MyPage2';
import { MYPAGE_COURSE } from '../../_collections/mypage';
import AccountModel from '../../../model/AccountModel';
import EmptyList from './EmptyList';
import PurchaseModal from '../../_common/dialog/PurchaseModal';
import CourseCertificateModal from '../../_common/dialog/CourseCertificateModal';
import { RENTAL_FOREVER_OVER_YEAR } from '../../../bearu_utils';

const MyCourseList2 = (props) => {
  //Modal
  const { showDialog } = useDialogContext();

  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);

  const [isMount] = useMount();
  const [selected_filter, setSelectedFilter] = useState(MY_COURSE_FILTER_ALL);
  const [filtered_course, setFilteredCourse] = useState(CommonStore?.tmp?.my_course_set);
  const [is_update_complete, setIsUpdateComplete] = useState(false);

  const filter_buttons = [
    { name: '전체', class_name: MY_COURSE_FILTER_ALL },
    { name: '수강중', class_name: MY_COURSE_FILTER_ON_GOING },
    { name: '수강만료', class_name: MY_COURSE_FILTER_EXPIRED },
  ];

  const expired_courses = CommonStore?.tmp?.my_course_set.filter((course) => course.is_expired);
  const on_going_courses = CommonStore?.tmp?.my_course_set.filter((course) => !course.is_expired);

  const requestUpdateCourseList = async () => {
    let { course_set } = await AccountModel.myCourseList({ access_token: null });
    if (isMount()) {
      dispatch(set_tmp('my_course_set', course_set));
      setIsUpdateComplete(true);
    }
  };

  useEffect(() => {
    requestUpdateCourseList();
  }, []);

  useEffect(() => {
    if (selected_filter === MY_COURSE_FILTER_ON_GOING) {
      setFilteredCourse(on_going_courses);
    } else if (selected_filter === MY_COURSE_FILTER_EXPIRED) {
      setFilteredCourse(expired_courses);
    } else {
      setFilteredCourse(CommonStore?.tmp?.my_course_set);
    }
  }, [selected_filter, is_update_complete]);

  return (
    <>
      {CommonStore?.tmp?.my_course_set.length === 0 ? (
        <EmptyList category={MYPAGE_COURSE} />
      ) : (
        <>
          {is_update_complete ? (
            <>
              {/*내 클래스 필터 버튼*/}
              {expired_courses.length && on_going_courses.length ? (
                <div className='my-course-filter-nav'>
                  {filter_buttons.map((filter, index) => (
                    <span
                      className={`${selected_filter === filter.class_name ? 'selected' : ''}`}
                      key={index}
                      onClick={() => setSelectedFilter(filter.class_name)}
                    >
                      {filter.name}
                    </span>
                  ))}
                </div>
              ) : null}

              <div className='my-category-wrap course'>
                {filtered_course.map((course, index) => (
                  <div className='my-course-card' key={index}>
                    <Link className='lecture-row' to={`/course/${course.id}/viewer`}>
                      <div className='flex-box'>
                        <div className='img-box'>
                          <img
                            className='around-course-img'
                            src={
                              course.main_thumbnail_image_url ||
                              `${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_bear_m3.png`
                            }
                            alt='강의 이미지'
                          />
                          {!course.is_expired && (
                            <div className='play-btn'>
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/ic_play_b+copy+7-2.png`}
                                alt='재생 버튼'
                              />
                            </div>
                          )}
                        </div>

                        <div className='title-box'>
                          <h3>{course.title}</h3>
                          <span>
                            {/*TODO 평생 수강 확인하는 공용 함수 작성*/}
                            {moment(course.rental_expire_time).format('YYYY') >
                            RENTAL_FOREVER_OVER_YEAR ? (
                              <p>평생 수강</p>
                            ) : (
                              <>
                                <p>
                                  {`${course.is_expired ? '' : '~'}${moment(
                                    course.rental_expire_time,
                                  ).format('YYYY.MM.DD HH:mm')}`}{' '}
                                  {course.is_expired ? '만료' : '수강 가능'}
                                </p>
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </Link>

                    <div className='my-course-row'>
                      <div className='progress-row'>
                        <div>
                          <div
                            className={`progress-state ${course.is_expired ? MY_COURSE_FILTER_EXPIRED : ''}`}
                          >
                            {course.is_expired ? '수강만료' : '수강중'}
                          </div>
                          <p>{`수강률 ${parseFloat((course.attendance_rate || 0) * 100).toFixed(1)}%`}</p>
                        </div>
                      </div>

                      <div className='badge-row'>
                        {course.is_completed && (
                          <span
                            className='certificate-down-btn'
                            onClick={() => {
                              showDialog({
                                type: dialog_type_custom,
                                component: CourseCertificateModal,
                                component_props: {
                                  course_id: course?.id,
                                },
                              });
                            }}
                          >
                            <span>수료증</span>
                            <img
                              src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/ic_download_l.png`}
                              alt='수료증 발급받기'
                            />
                          </span>
                        )}

                        <span>
                          {course.document_url ? (
                            <a
                              className='document-down-btn'
                              href={`${course.document_url}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              비법노트
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/ic_download_l.png`}
                                alt='다운로드 아이콘'
                              />
                            </a>
                          ) : null}
                        </span>

                        <span>
                          {moment(course.rental_expire_time).format('YYYY') <
                          RENTAL_FOREVER_OVER_YEAR ? (
                            <button
                              onClick={async () => {
                                if (course.is_extend) {
                                  showDialog({
                                    type: dialog_type_custom,
                                    component: PurchaseModal,
                                    component_props: {
                                      id: course?.id,
                                      history: props.history,
                                    },
                                  });
                                } else {
                                  await alert(
                                    '해당 강의는 연장하기를 지원하지 않습니다.\n 관리자에게 문의해주세요.',
                                  );
                                }
                              }}
                            >
                              기간 연장
                            </button>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default withRouter(MyCourseList2);
