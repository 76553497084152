import React, { useState } from 'react';
import '../../assets/scss/page/refund_policy_for_product_detail.scss';
import '../../assets/scss/page/terms_policy.scss';
import TermsItem14b from '../service/terms_item/230922/TermsItem14b';

const RefundPolicyForProductDetail = () => {
  const [is_expand, setExpand] = useState(false);

  return (
    <div className='refund-policy-item-wrap'>
      <h4>환불규정</h4>
      <div className='refund-policy-item-expand'>
        <span>단과 영상강의 및 패키지 영상강의 환불 규정</span>
        <div
          className='refund-policy-item-expand-actions'
          onClick={() => {
            setExpand(!is_expand);
          }}
        >
          <span>{is_expand ? '간략히' : '자세히 보기'}</span>
          <img
            src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/${is_expand ? 'ic_arrow_up_lg' : 'ic_arrow_down_lg'}.svg`}
            alt={'자세히 보기/간략히'}
          />
        </div>
      </div>
      <div
        style={{
          display: is_expand ? 'block' : 'none',
        }}
      >
        <div className='terms-policy-container product-detail'>
          <ul className='terms-list'>
            <TermsItem14b hide_title={true} />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicyForProductDetail;
