import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CourseModel from '../../../model/CourseModel';
import { useDialogContext } from '../dialog/DialogContextProvider';
import { ProfileImage, resetSizeTextarea } from '../section/QnaContainer';
import { ableSelectAll } from '../section/QnaContainer';
import EbookModel from '../../../model/EbookModel';

const propTypes = {
  type: PropTypes.oneOf(['course', 'ebook']),
  reply: PropTypes.object,
  user_nickname: PropTypes.string,
  visible_edit_buttons: PropTypes.bool,
  resetReplyList: PropTypes.func,
};

const ReviewReply = (props) => {
  const [edit_mode, setEditMode] = useState(false);
  const [stated_reply, setStatedReply] = useState(props.reply);

  const reply_edit_textarea_ref = useRef(null);
  const is_posting_review = useRef(false);

  const { confirm } = useDialogContext();

  const modifyReviewReply = async () => {
    let code;
    let data;

    if (props.type === 'course') {
      const result = await CourseModel.courseReviewModify({
        review_id: stated_reply.id,
        content: reply_edit_textarea_ref.current.value.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, '\n'),
        rating: null,
      });
      code = result.code;
      data = result.data;
    }
    if (props.type === 'ebook') {
      const result = await EbookModel.ebookReviewEdit({
        review_id: stated_reply.review_id,
        content: reply_edit_textarea_ref.current.value.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, '\n'),
        rating: null,
      });
      code = result.code;
      data = result.data;
    }

    if (code === 200) {
      is_posting_review.current = false;

      setStatedReply(data.review);
      setEditMode(false);
    } else {
      alert('댓글 수정 실패(네트워크 오류)');
    }
  };

  const deleteQna = async () => {
    let code;

    if (props.type === 'course') {
      const result = await CourseModel.courseReviewDelete({ review_id: stated_reply.id });
      code = result.code;
    }
    if (props.type === 'ebook') {
      const result = await EbookModel.ebookReviewDelete({ review_id: stated_reply.review_id });
      code = result.code;
    }

    if (code === 200) {
      props.resetReplyList(stated_reply.id || stated_reply.review_id);
    } else {
      alert('댓글 삭제 실패(네트워크 오류)');
    }
  };

  return (
    <div className='content reply'>
      <img
        className='reply-icon'
        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_reply.png`}
        alt='댓글 아이콘'
      />
      <div className='main-column-wrap'>
        <div className='profile-info-row'>
          <span className='profile-info-wrap'>
            <ProfileImage
              img_src={stated_reply?.account_profile_img_url}
              auth_check={props.reply}
            />
            <h4>{props.user_nickname}</h4>
          </span>

          {props.visible_edit_buttons ? (
            <span className='modify-wrap'>
              {edit_mode ? (
                <button
                  onClick={() => {
                    setEditMode(false);
                  }}
                >
                  <img
                    className='close-btn-img'
                    src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_x_lg_18px.png`}
                    alt='닫기 버튼'
                  />
                </button>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setEditMode(true);
                      setTimeout(() => {
                        resetSizeTextarea(reply_edit_textarea_ref);
                      }, 50);
                    }}
                  >
                    수정
                  </button>
                  <button
                    onClick={async () => {
                      if (await confirm('정말로 삭제 할까요?')) {
                        await deleteQna();
                      }
                    }}
                  >
                    삭제
                  </button>
                </>
              )}
            </span>
          ) : null}
        </div>
        {edit_mode ? (
          <div className='textarea-wrap edit-mode'>
            <textarea
              placeholder='내용을 입력하세요.'
              maxLength={300}
              ref={reply_edit_textarea_ref}
              onKeyDown={(e) => ableSelectAll(e)}
              onChange={() => {
                resetSizeTextarea(reply_edit_textarea_ref);
              }}
              defaultValue={stated_reply.content}
            />
            <button
              className='submit-save-btn'
              onClick={async () => {
                if (!reply_edit_textarea_ref.current.value) {
                  alert('댓글을 입력해주세요:)');
                } else if (!is_posting_review.current) {
                  is_posting_review.current = true;

                  await modifyReviewReply();
                }
              }}
            >
              <p>저장</p>
            </button>
          </div>
        ) : (
          <p className={stated_reply.reply_status_id > 2 ? 'deleted' : ''}>
            {stated_reply.content}
          </p>
        )}

        <div className='bottom-row'>
          <span>
            {`${moment(
              stated_reply.update_time ? stated_reply.update_time : stated_reply.create_time,
            ).format('YYYY.MM.DD')} ${stated_reply.update_time ? '수정됨' : ''}`}
          </span>
        </div>
      </div>
    </div>
  );
};

ReviewReply.propTypes = propTypes;

export default ReviewReply;
