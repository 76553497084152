import React, { useRef } from 'react';
import CourseModel from '../../../model/CourseModel';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { set_account_favorite_count } from '../../../redux/account/action';
import { get_past_day, replace_cloudfront_url, set_favorite_icon } from '../../../common';
import { useDialogContext } from '../dialog/DialogContextProvider';

const propTypes = {
  // course: PropTypes.object
};

const CourseCard = (props) => {
  const course = props.course;
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const badge = useRef(null);
  const _favorite_pending = useRef(false);
  const { confirm } = useDialogContext();

  const onFavorite = async () => {
    if (AccountStore) {
      if (!_favorite_pending.current) {
        _favorite_pending.current = true;
        CourseModel.courseFavorite({
          course_id: course.id,
        }).then(({ is_favorite, favorite_count, account_class_favorite_count }) => {
          dispatch(set_account_favorite_count(account_class_favorite_count));
          set_favorite_icon('course', course.id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  // 1. 오픈예정 -> course_sale_status_id == 1
  // 2. best -> badge_type_name == 'best'
  // 3. new -> write_time < 30일
  if (course.course_sale_status_id === 1) {
    // 오픈예정
    badge.current = (
      <div className='badge-wrap coming-soon-badge'>
        <p>오픈예정</p>
      </div>
    );
  } else if (course.badge_type_name === 'BEST') {
    // best
    badge.current = (
      <div className='badge-wrap best-badge'>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/static/png/common/crown-icon.png`}
          alt='베스트 클래스'
        />
        <p>{course.badge_type_name}</p>
      </div>
    );
  } else if (get_past_day(course.write_time) < 30) {
    // new
    badge.current = (
      <div className='badge-wrap new-badge'>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/static/png/common/sparkles-icon.png`}
          alt='새로운 클래스'
        />
        <p>NEW</p>
      </div>
    );
  }

  return (
    <div
      className={`gtm-data list-item course-id-${course.id}`}
      data-ai-pid={`c${course.id.toString().padStart(8, '0')}`}
      data-ai-pnm={course.title.replace(/\r\n/g, ' ')}
      data-ai-ppc={course.min_price}
      data-ai-ppb={course.brand_name}
      data-ai-pca={course.category_name}
    >
      <div className='thumbnail-wrap'>
        <img
          className={`td-favorite ${course.is_favorite ? '' : ' enp_mobon_wish'}`}
          src={
            course.is_favorite
              ? `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-select-2.svg`
              : `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-default-2.svg`
          }
          alt='클래스 저장하기'
          onClick={onFavorite}
        />
        {badge.current ? badge.current : null}
        <Link to={`/course/${course.id}`} className={'gtm-click'}>
          <div
            className='course-image'
            style={{
              background: `url("${replace_cloudfront_url(course.main_thumbnail_image_url)}") no-repeat center center/cover`,
            }}
          />
        </Link>
      </div>
      <div className='course-desc-wrap'>
        <Link to={`/course/${course.id}`} className={'gtm-click'}>
          <h4>
            [{course.short_title}] {course.title}
          </h4>
        </Link>
        <div className='bottom-wrap'>
          <p>
            {course?.tag_set?.map((tag, index) => {
              if (index < 2) {
                return (
                  <Link to={`/search?q=${tag}`} key={index}>
                    {tag}
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </p>
          <div className='favorites-wrap'>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/static/png/common/icon-favorites-2.png`}
              alt='클래스 저장'
            />
            <span>{course.favorite_count}</span>
          </div>
        </div>
      </div>
      {props.is_add_button ? (
        <Link className='link-button' to={`/course/${course.id}`}>
          강의 보러가기
        </Link>
      ) : null}
    </div>
  );
};

CourseCard.propTypes = propTypes;

export default withRouter(CourseCard);
