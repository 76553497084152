import React, { useEffect, useState } from 'react';
import AccountModel from '../../../model/AccountModel';
import { useDispatch, useSelector } from 'react-redux';
import { set_tmp } from '../../../redux/common/action';
import FavoriteCard from '../component/FavoriteCard';
import EmptyList from './EmptyList';
import { MYPAGE_FAVORITE } from '../../_collections/mypage';
import { useMount } from '../../../util/BearuHooks';

const MyFavoriteList2 = () => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const [isMount] = useMount();

  const [is_update_complete, setIsUpdateComplete] = useState(false);

  const total_favorite_count =
    (CommonStore?.tmp?.my_favorite_pacakge_set.length || 0) +
    (CommonStore?.tmp?.my_favorite_course_set.length || 0) +
    (CommonStore?.tmp?.my_favorite_kit_set.length || 0);

  const requestUpdateFavorite = async () => {
    let { course_favorite_set, package_favorite_set, kit_favorite_set } =
      await AccountModel.myFavoriteList();

    if (isMount()) {
      dispatch(set_tmp('my_favorite_course_set', course_favorite_set));
      dispatch(set_tmp('my_favorite_pacakge_set', package_favorite_set));
      dispatch(set_tmp('my_favorite_kit_set', kit_favorite_set));

      setIsUpdateComplete(true);
    }
  };

  useEffect(() => {
    requestUpdateFavorite();
  }, []);

  return (
    <>
      {total_favorite_count === 0 ? (
        <EmptyList category={MYPAGE_FAVORITE} />
      ) : is_update_complete ? (
        <div className='my-category-wrap favorite'>
          {CommonStore?.tmp?.my_favorite_pacakge_set.length > 0 && (
            <div>
              <h2>패키지</h2>
              <div className='my-favorite-course'>
                {CommonStore?.tmp?.my_favorite_pacakge_set.map((_package, index) => (
                  <FavoriteCard
                    data={{
                      id: _package.id,
                      title: _package.short_title,
                      description: _package.title,
                      discount_rate: Math.floor(
                        ((_package.original_price - _package.price) / _package.original_price) *
                          100,
                      ),
                      price: _package.price,
                      original_price: _package.original_price,
                      favorite_count: _package.favorite_count,
                      main_thumbnail_image_url: _package.main_thumbnail_image_url,
                      period_time_in_day: _package.rental_time,
                    }}
                    category='package'
                    key={index}
                  />
                ))}
              </div>
            </div>
          )}

          {CommonStore?.tmp?.my_favorite_course_set.length > 0 && (
            <div>
              <h2>클래스</h2>
              <div className='my-favorite-course'>
                {CommonStore?.tmp?.my_favorite_course_set.map((course, index) => (
                  <FavoriteCard
                    data={{
                      id: course.id,
                      title: course.short_title,
                      description: course.title,
                      discount_rate: Math.floor(
                        ((course.original_price - course.price) / course.original_price) * 100,
                      ),
                      price: course.price,
                      original_price: course.original_price,
                      favorite_count: course.favorite_count,
                      main_thumbnail_image_url: course.main_thumbnail_image_url,
                      period_time_in_day: course.rental_time_in_day,
                    }}
                    category='course'
                    key={index}
                  />
                ))}
              </div>
            </div>
          )}

          {CommonStore?.tmp?.my_favorite_kit_set.length > 0 && (
            <div>
              <h2>키트</h2>
              <div className='my-favorite-course'>
                {CommonStore?.tmp?.my_favorite_kit_set.map((kit, index) => (
                  <FavoriteCard
                    data={{
                      id: kit.id,
                      title: kit.title,
                      description: kit.description,
                      discount_rate: 0,
                      price: kit.our_sale_price,
                      original_price: kit.our_sale_price,
                      favorite_count: kit.favorite_count,
                      circle_image_url:
                        kit.circle_image_url ||
                        `${process.env.REACT_APP_IMG_URL}/static/v2/jpg/common/circle_kit.jpg`,
                      period_time_in_day: 0,
                    }}
                    category='kit'
                    key={index}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default MyFavoriteList2;
