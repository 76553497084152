import { useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { get_past_day, init_comma, replace_cloudfront_url } from '../../../common';
import 'animate.css';

const TimeCourseCard = (props) => {
  const { discount_info, is_expire } = props;
  const propsCourse = props.course;
  // 1. 오픈예정 -> course_sale_status_id == 1
  // 2. best -> badge_type_name == 'best'
  // 3. new -> write_time < 30일
  const getBadge = (propsCourse) => {
    if (propsCourse.course_sale_status_id === 1) {
      // 오픈예정
      return (
        <div className='badge-wrap-2'>
          <p>오픈예정</p>
        </div>
      );
    } else if (propsCourse.badge_type_name === 'BEST') {
      // best
      return (
        <div className='badge-wrap-2'>
          <p>Best</p>
        </div>
      );
    } else if (get_past_day(propsCourse.write_time) < 30) {
      // new
      return (
        <div className='badge-wrap-2'>
          <p>New</p>
        </div>
      );
    }
  };

  const PriceDescription = useCallback(() => {
    let view = null;
    if (propsCourse.rental_time_in_day >= 30) {
      view = (
        <p>
          {discount_info.override_price
            ? `(수강기간 기준 월 ${init_comma(
                Math.floor(discount_info.override_price / (propsCourse.rental_time_in_day / 30)),
              )}원)`
            : `(수강기간 기준 월 ${init_comma(
                propsCourse.min_price - discount_info.discount_price < 0
                  ? 0
                  : Math.floor(
                      (propsCourse.min_price - discount_info.discount_price) /
                        (propsCourse.rental_time_in_day / 30),
                    ),
              )}원)`}
        </p>
      );
    }
    return view;
  }, [discount_info, propsCourse]);

  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountRatio, setDiscountRatio] = useState(0);

  useEffect(() => {
    let discountPrice = 0;

    if (discount_info.override_price) {
      discountPrice = discount_info.override_price;
      setDiscountPrice(discount_info.override_price);
    } else {
      if (propsCourse.min_price - discount_info.discount_price >= 0) {
        discountPrice = propsCourse.min_price - discount_info.discount_price;
        setDiscountPrice(propsCourse.min_price - discount_info.discount_price);
      }
    }

    setDiscountRatio(
      parseInt(((propsCourse.original_price - discountPrice) * 100) / propsCourse.original_price),
    );
  }, []);

  return (
    <div
      className='list-item gtm-data gtm-click relative'
      data-ai-pid={`c${propsCourse.id.toString().padStart(8, '0')}`}
      data-ai-pnm={propsCourse.title.replace(/\r\n/g, '')}
      data-ai-ppc={propsCourse.min_price}
      data-ai-ppb={propsCourse.brand_name}
      data-ai-pca={propsCourse.category_name}
    >
      {is_expire ? (
        <div>
          <div className='thumbnail-wrap'>
            {getBadge(propsCourse)}
            <div className='thumbnail-box'>
              <div
                className='course-image'
                style={{
                  background: `url("${replace_cloudfront_url(
                    propsCourse.main_thumbnail_image_url,
                  )}") no-repeat center center/cover`,
                }}
              />
              <div className='disable-layer'>
                <p className='info-text'>타임특가 종료</p>
              </div>
            </div>
          </div>
          <div className='opacity-layer'>
            <div className='course-desc-wrap'>
              <h4>{propsCourse.title}</h4>
              <p>{propsCourse.category_name}</p>
            </div>
            <div className='origin-price-wrap'>
              <h6>
                정가 <span>{`${init_comma(propsCourse.original_price)}원`}</span>
              </h6>
            </div>
            <div className='time-event-price-wrap'>
              <div className='left'>
                <h5 className='discount-ratio'>{discountRatio}%</h5>
              </div>
              <div className='right'>
                <h5>{init_comma(discountPrice)}원</h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Link to={`/course/${propsCourse.id}?event_code=${props.event_code}`} target='_blank'>
          <div className='thumbnail-wrap'>
            {getBadge(propsCourse)}
            <div className='thumbnail-box'>
              <div
                className='course-image'
                style={{
                  background: `url("${replace_cloudfront_url(
                    propsCourse.main_thumbnail_image_url,
                  )}") no-repeat center center/cover`,
                }}
              />
            </div>
          </div>
          <div className='course-desc-wrap'>
            <h4>{propsCourse.title}</h4>
            <p>{propsCourse.category_name}</p>
          </div>
          <div className='origin-price-wrap'>
            <h6>
              정가 <span>{`${init_comma(propsCourse.original_price)}원`}</span>
            </h6>
          </div>
          <div className='time-event-price-wrap'>
            <div className='left'>
              <h5 className='discount-ratio'>{discountRatio}%</h5>
            </div>
            <div className='right'>
              <h5>{init_comma(discountPrice)}원</h5>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default withRouter(TimeCourseCard);
