import React, { useEffect, useState } from 'react';
import { dialog_type_custom, useDialogContext } from '../../_common/dialog/DialogContextProvider';
import AccountModel from '../../../model/AccountModel';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TrackShippingModal from '../../_common/dialog/TrackShippingModal';
import EmptyList from './EmptyList';
import { useMount } from '../../../util/BearuHooks';
import { MYPAGE_SHIPPING } from '../../_collections/mypage';

const MyShippingList2 = () => {
  //Modal
  const { showDialog } = useDialogContext();

  const [kit_set, setKitSet] = useState(null);
  const [isMount] = useMount();

  useEffect(async () => {
    let { kit_set } = await AccountModel.myShippingList();
    if (isMount) {
      setKitSet(kit_set);
    }
  }, []);

  return (
    <>
      {kit_set === null ? null : (
        <>
          {kit_set.length === 0 ? (
            <EmptyList category={MYPAGE_SHIPPING} />
          ) : (
            <div className='my-category-wrap shipping'>
              {kit_set.map((kit, index) => (
                <div className='my-shipping-card' key={index}>
                  <Link className='kit-info-row' to={`/kit/${kit.kit_info.id}`}>
                    <img
                      className='kit-img'
                      src={
                        kit.kit_info.circle_image_url
                          ? kit.kit_info.circle_image_url
                          : `${process.env.REACT_APP_IMG_URL}/static/v2/jpg/common/circle_kit.jpg`
                      }
                      alt='키트 이미지'
                    />
                    <div>
                      <h3>{`${kit.kit_info.title} ${
                        kit.ordered_count - kit.refunded_count > 1
                          ? `${kit.ordered_count - kit.refunded_count}개`
                          : ''
                      }`}</h3>
                    </div>
                  </Link>
                  <div className='shipping-info'>
                    <div className='shipping-process-bar'>
                      <div className='active'>
                        <p>배송준비</p>
                      </div>
                      <hr className={kit.ship_info.shipping_status > 1 ? 'active' : ''} />
                      <div className={kit.ship_info.shipping_status > 1 ? 'active' : ''}>
                        <p>배송출고</p>
                      </div>
                      <hr className={kit.ship_info.shipping_status > 2 ? 'active' : ''} />
                      <div className={kit.ship_info.shipping_status > 2 ? 'active' : ''}>
                        <p>배송완료</p>
                      </div>
                    </div>
                  </div>
                  <div className='bottom-row'>
                    <p>{`${moment(kit.purchased_time).format('YYYY.MM.DD')} 구매`}</p>
                    <button
                      onClick={() => {
                        showDialog({
                          type: dialog_type_custom,
                          component: TrackShippingModal,
                          component_props: {
                            kit: kit,
                          },
                        });
                      }}
                    >
                      배송조회
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MyShippingList2;
