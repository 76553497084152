import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { init_comma, replace_cloudfront_url } from '../../../common';

const TimePackageCard = (props) => {
  const { discount_info, is_expire } = props;
  const propsProduct = props.product;
  const getBadge = () => {
    return (
      <div className='badge-wrap-2'>
        <p>패키지</p>
      </div>
    );
  };

  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountRatio, setDiscountRatio] = useState(0);

  useEffect(() => {
    let discountPrice = 0;

    if (discount_info.override_price) {
      discountPrice = discount_info.override_price;
      setDiscountPrice(discount_info.override_price);
    } else {
      if (propsProduct.min_price - discount_info.discount_price >= 0) {
        discountPrice = propsProduct.min_price - discount_info.discount_price;
        setDiscountPrice(propsProduct.min_price - discount_info.discount_price);
      }
    }

    setDiscountRatio(
      parseInt(((propsProduct.original_price - discountPrice) * 100) / propsProduct.original_price),
    );
  }, []);

  return (
    <div
      className={`list-item gtm-data gtm-click`}
      data-ai-pid={`p${propsProduct.id.toString().padStart(8, '0')}`}
      data-ai-pnm={propsProduct.title.replace(/\r\n/g, ' ')}
      data-ai-ppc={propsProduct.price}
      data-ai-ppb=''
      data-ai-pca=''
    >
      {is_expire ? (
        <div>
          <div className='thumbnail-wrap'>
            {getBadge()}
            <div className='thumbnail-box'>
              <div
                className='course-image'
                style={{
                  background: `url("${replace_cloudfront_url(
                    propsProduct.main_thumbnail_image_url,
                  )}") no-repeat center center/cover`,
                }}
              />
              <div className='disable-layer'>
                <p className='info-text'>타임특가 종료</p>
              </div>
            </div>
          </div>
          <div className='opacity-layer'>
            <div className='course-desc-wrap'>
              <div className='thumbnail-box'>
                <h4>{propsProduct.title}</h4>
                <p>{propsProduct.category_name}</p>
              </div>
            </div>
            <div className='origin-price-wrap'>
              <h6>
                정가 <span>{`${init_comma(propsProduct.original_price)}원`}</span>
              </h6>
            </div>
            <div className='time-event-price-wrap'>
              <div className='left'>
                <h5 className='discount-ratio'>{discountRatio}%</h5>
              </div>
              <div className='right'>
                <h5>{init_comma(discountPrice)}원</h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Link to={`/package/${propsProduct.id}?event_code=${props.event_code}`} target='_blank'>
          <div className='thumbnail-wrap'>
            {getBadge()}
            <div className='thumbnail-box'>
              <div
                className='course-image'
                style={{
                  background: `url("${replace_cloudfront_url(
                    propsProduct.main_thumbnail_image_url,
                  )}") no-repeat center center/cover`,
                }}
              />
            </div>
          </div>
          <div className='course-desc-wrap'>
            <div className='thumbnail-box'>
              <h4>{propsProduct.title}</h4>
              <p>{propsProduct.category_name}</p>
            </div>
          </div>
          <div className='origin-price-wrap'>
            <h6>
              정가 <span>{`${init_comma(propsProduct.original_price)}원`}</span>
            </h6>
          </div>
          <div className='time-event-price-wrap'>
            <div className='left'>
              <h5 className='discount-ratio'>{discountRatio}%</h5>
            </div>
            <div className='right'>
              <h5>{init_comma(discountPrice)}원</h5>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default withRouter(TimePackageCard);
