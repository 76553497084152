import React from 'react';
import { withRouter } from 'react-router-dom';

const BgdXNotActive = () => {
  return (
    <article className='not-active-page'>
      <div className='content-top-img'>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgdX_prev_main_img.png`}
          alt='목표달성 환급 챌린지 러닝크루 매일의 작은 기록으로 우리는 큰 결과를 만들어 냅니다.'
        />
      </div>

      <div className='content-area flex-all-center'>
        <div className='desc-area'>
          <div>
            <p style={{ fontSize: '1.875rem', fontWeight: '600' }}>
              목표달성과 환급까지 두마리 토끼를 한번에!
            </p>

            <div className='gray-card desc-text'>
              <div>✅</div>
              <div>
                <p className='text-gray-03'>
                  러닝크루(Learning Crew)는 매일 최소 1강 이상의 꾸준한 학습을 기록하고, ‘끝까지’
                  목표를 향해 달려가는 사람들의 커뮤니티입니다. 빡공단X와 함께 진정한 성장을
                  경험하고, 나아가 목표 달성에 대한 보상으로 환급까지!
                  <br />
                  <br />
                  <span
                    style={{
                      fontSize: '1.25rem',
                      fontWeight: '600',
                      marginTop: '10px',
                    }}
                  >
                    당신의 성장에 한계는 없습니다. 지금 시작해보세요!
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className='info-item'>
            <h3>러닝크루 환급챌린지 안내</h3>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgdX_img_01.png`}
              alt='10 Min 30 Days'
            />
            <p className='mt-8'>
              습관을 만드는데 최소 21일이 필요합니다. 단기간의 성장보다 꾸준히 노력하는 과정을 통해
              몸이 기억하고 뇌에 각인되는 학습과 응용의 결과를 만들게 됩니다. 꾸준한 성장을 통해
              거대한 결과를 만드는 것이 러닝크루의 핵심입니다. 매일 10분의 성장을 위한 시간과,
              매일의 학습 기록을 통해 진정한 성장을 만들어보세요. 혼자서는 어렵기만한 목표 달성이
              매일의 미션과 함께라면 🔺17배 높은 목표 달성을 이룰 수 있게 됩니다. 성장을 미루지
              마세요. 지금 시작해보세요.
            </p>
            <ul className='mt-4'>
              <li>
                <h4>무엇이 제공되나요?</h4>
                <ul className='deco-disc'>
                  <li>참가자 전원에게 해당 코스에 맞는 온라인클래스를 제공합니다.</li>
                  <li>학습의 기록을 남길 수 있는 전용 커뮤니티도 함께 제공됩니다.</li>
                </ul>
                <h4 className='mt-6'>어떤 활동을 하나요?</h4>
                <ul className='deco-disc'>
                  <li>원하는 코스를 선택 후 매일 최소 10분 이상의 학습(수강)을 진행합니다.</li>
                  <li>커뮤니티에서 학습의 기록을 매일매일 작성합니다.</li>
                  <li>함께하는 사람들의 성장을 응원합니다.</li>
                  <li>커리어 프로필을 만들어 나만의 포트폴리오를 만듭니다.</li>
                  <li>최종 성장에 대한 회고를 작성합니다.</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className='info-item'>
            <h3>챌린지 신청 방법</h3>
            <div className='gray-card desc-text'>
              <div>✅</div>
              <ul>
                <li>
                  <strong>챌린지 참가비용 : 139,000원 (1기수 기준, 15기 가격)</strong>
                </li>
                <li className='text-deco-disc'>
                  미션 완주시 전액 장학금(현금)으로{' '}
                  <span className='underline text-red-300'>100% 환급이 진행됩니다.</span>
                </li>
                <li className='text-deco-disc'>
                  수강 기간은 총 3개월, 활동 이후에도 이용이 가능합니다.
                </li>
              </ul>
            </div>
            <ul>
              <li>1. 아래의 챌린지 참가 신청서를 작성해주세요.</li>
              <li>2. 작성 후 선발 결과일에 대상자가 발표됩니다.</li>
              <li>3. 수강등록기간 내에 수강등록(결제)을 진행해주세요.</li>
              <li>
                4. 수강등록이 확인되면 &lt;학습매니저&rt;가 활동에 대한 안내를 개별 제공합니다.
              </li>
            </ul>
          </div>

          <div className='info-item'>
            <h3>어떤 코스가 있나요?</h3>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/assets/images/bgd/bgdX_img_02.png`}
              alt='10 Min 30 Days'
            />
          </div>

          <div className='info-item'>
            <h3>환급챌린지를 해야하는 이유</h3>
            <div className='gray-card desc-text'>
              <div>🎁</div>
              <div>
                <p className='text-yellow-04 m-0'>
                  ‘특별해서’가 아닌 <span className='underline'>‘꾸준했기’</span> 때문에 가능한
                  결과입니다. 당신도 할 수 있어요!
                </p>
              </div>
            </div>
            <b className='text-deco-l-line'>
              100% 목표 달성을 위한 확실한 성장, 후기가 증명합니다.
            </b>
            <ul className='review-list'>
              <li className='item'>
                <div>
                  <h4 className='title'>학원보다 훨씬 가치있었던 학습 챌린지!</h4>
                  <p>
                    포토샵 클래스 덕분에 디자인 기술이 많이 향상되었습니다. 매일 강의를 듣고
                    학습일지를 작성하면서 실력을 쌓을 수 있었고, 끝까지 완강하여 환급도 받았습니다.
                    이 과정은 신입 디자이너로서 제 역량을 한층 끌어올려 주는 계기가 되었습니다.
                    <p>목표: 포토샵 기술 향상을 통해 더 나은 디자인 작업 수행</p>
                  </p>
                </div>
              </li>
              <li className='item'>
                <div>
                  <h4 className='title'>ChatGPT 클래스는 마케팅 업무에 큰 도움이 되었어요.</h4>
                  <p>
                    매일 한 강씩 수강하고 학습일지를 작성하는 과정이 체계적인 학습을 가능하게
                    해줬습니다. 끝까지 완강하고 환급도 받아서 매우 만족스럽습니다. 이제 ChatGPT를
                    활용한 마케팅 전략을 더 효과적으로 세울 수 있을 것 같아요.
                  </p>
                  <p className='text-deco-disc'>
                    목표: ChatGPT 활용 능력을 통해 마케팅 업무 효율성 극대화
                  </p>
                </div>
              </li>
              <li className='item'>
                <div>
                  <h4 className='title'>회사에서 필요한 능력을 하루 10분으로 키울 수 있었어요!</h4>
                  <p>
                    엑셀클래스를 통해 업무에 직접적으로 활용할 수 있는 유용한 팁을 많이 배웠습니다.
                    매일 한 강씩 수강하면서 일지를 작성하는 과정이 처음에는 부담스러웠지만, 점점
                    익숙해졌고 끝까지 완강할 수 있었어요. 환급까지 받아서 더 만족스럽습니다.
                  </p>
                  <p className='text-deco-disc'>
                    목표: 엑셀 능력 향상으로 업무 효율성 증대 및 승진 준비
                  </p>
                </div>
              </li>
              <li className='item'>
                <div>
                  <h4 className='title'>아무도 알려주지 않던 것을 이제는 완벽하게!</h4>
                  <p>
                    재테크 클래스에서 배운 지식 덕분에 재정 관리를 훨씬 더 체계적으로 할 수 있게
                    되었어요. 매일 강의를 듣고 학습일지를 작성하는 것이 습관이 되어 완강하는 데 큰
                    도움이 되었습니다. 환급도 받아서 정말 기쁩니다. 경제적 안정을 향한 첫 걸음을 잘
                    뗀 것 같아요.
                  </p>
                  <p className='text-deco-disc'>
                    목표: 재테크 지식을 통해 경제적 안정 확보 및 자산 증대
                  </p>
                </div>
              </li>
              <li className='item'>
                <div>
                  <h4 className='title'>혼자서 많은 것을 이룰 수 있게 해주는 유익한 챌린지!</h4>
                  <p>
                    영상편집 클래스는 정말 재미있었고, 유익했습니다. 매일 강의를 듣고 일지를
                    작성하면서 점점 실력이 향상되는 것을 느낄 수 있었어요. 끝까지 완강하고 환급도
                    받아서 경제적 부담도 덜었습니다. 이제 영상편집을 취미로 즐기면서, 이력을 더욱
                    강화할 수 있게 되었어요.
                  </p>
                  <p className='text-deco-disc'>
                    목표: 영상편집 기술 습득으로 취미 및 취업 준비에 활용
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
};

export default withRouter(BgdXNotActive);
