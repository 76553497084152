import React, { useMemo } from 'react';
import '../../../assets/scss/component/_ebook_card.scss';
import { init_comma } from '../../../common';
import useEbookViewer from '../../../hook/useEbookViewer';

function EbookCard({
  ebookCode,
  thumbnailUrl,
  title,
  author,
  category,
  description,
  latestViewedPage,
  price,
  originPrice,
  hasBookmarkButton,
  isBookmarked,
  isCompleted,
  isMine,
  onBookmarkClick,
}) {
  const { openEbookViewer } = useEbookViewer();

  const handleReadClick = (e) => {
    e.preventDefault();
    openEbookViewer(ebookCode);
  };

  return (
    <div className='ebook-card'>
      <div className='ebook-card-inner'>
        <div className='ebook-thumbnail'>
          <img src={thumbnailUrl} alt='ebook 썸네일' />
        </div>
        <div className='ebook-info-area'>
          <div className='ebook-info-wrap'>
            <div className='ebook-card-title'>{title}</div>
            <div className='ebook-info'>
              <span>{author}</span> &middot; <span>{category}</span>
            </div>
            <div className='ebook-description'>{description}</div>
          </div>

          {price && (
            <div className='price-wrap'>
              {originPrice && originPrice !== price && (
                <div className='discount-wrap'>
                  <span className='discount-rate'>
                    {Math.round((1 - price / originPrice) * 100)}% 할인
                  </span>
                  <span className='origin-price'>{init_comma(originPrice)}원</span>
                </div>
              )}
              <div className='price'>{init_comma(price)}원</div>
            </div>
          )}

          {isMine && latestViewedPage > 0 && !isCompleted && (
            <button className='read-button' onClick={handleReadClick}>
              바로 읽기 p.{latestViewedPage}
            </button>
          )}
          {isMine && (latestViewedPage === 0 || isCompleted) && (
            <button className='read-start-button' onClick={handleReadClick}>
              {isCompleted ? '완독' : '지금 읽기'}
            </button>
          )}
        </div>
      </div>

      {hasBookmarkButton && (
        <button
          className='bookmark-button'
          type='button'
          onClick={(e) => {
            e.preventDefault();
            onBookmarkClick();
          }}
        >
          <div className={`bookmark-icon ${isBookmarked ? 'active' : ''}`} />
        </button>
      )}
    </div>
  );
}

export default EbookCard;
