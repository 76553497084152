import axios from 'axios';

class EbookModel {
  async ebookList({ category_id, order_option, page, search_text }) {
    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/ebook`, {
      params: { category_id, order_option, page, search_text },
    });
    return { books: data?.ebook_set || [] };
  }

  async ebookDetail({ ebook_id }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/ebook/${ebook_id}`);
    return { code, data };
  }

  async ebookRealtimeReviewList({ size }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/ebook/realtime-review`, {
      params: { size },
    });
    return { code, reviews: data };
  }

  async ebookReviewList({ ebook_id, last_visible_data, page, parent_id, size }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/ebook/review`, {
      params: {
        ebook_id,
        last_visible_data,
        parent_id,
        size,
        page,
      },
    });
    return { code, data };
  }

  async ebookReviewWrite({ ebook_id, content, rating, parent_id }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/ebook/review`, {
      ebook_id,
      content,
      rating,
      parent_id,
    });
    return { code, data };
  }

  async ebookReviewEdit({ review_id, content, rating }) {
    const {
      data: { code, data },
    } = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/ebook/review/${review_id}`, {
      content,
      rating,
    });
    return { code, data };
  }

  async ebookReviewDelete({ review_id }) {
    const {
      data: { code, data },
    } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/ebook/review/${review_id}`);
    return { code, data };
  }

  async ebookFavorite({ ebook_id }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/ebook/${ebook_id}/favorite`);
    return { code, data };
  }

  async ebookViewerUrl({ ebookCode }) {
    const {
      data: { data },
    } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/ebook/user/pdf-viewer?ebook_code=${ebookCode}`,
    );
    return data;
  }
}

const instance = new EbookModel();
export default instance;
