import {
  SET_ACCOUNT_EBOOK_FAVORITE_COUNT,
  SET_ACCOUNT_FAVORITE_COUNT,
  STORED_ACCOUNT_INFO,
} from '../action_type';

export const stored_account_info = (data) => ({
  type: STORED_ACCOUNT_INFO,
  data,
});

export const set_account_favorite_count = (count) => ({
  type: SET_ACCOUNT_FAVORITE_COUNT,
  count,
});

export const set_account_ebook_favorite_count = (count) => ({
  type: SET_ACCOUNT_EBOOK_FAVORITE_COUNT,
  count,
});
