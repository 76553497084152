export const stringify = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');

export const parse = (_str) => {
  const str = _str[0] === '?' ? _str.slice(1) : _str; // remove leading "?"
  const obj = {};
  const pairs = str.split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return obj;
};
