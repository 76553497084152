import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className='loading-spinner-wrap'>
      <div className='anim-wrap'>
        <div className='loading-icon' />
      </div>
    </div>
  );
};

export default LoadingSpinner;
