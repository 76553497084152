import React, { useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import PropTypes from 'prop-types';
import { useDialogContext } from './DialogContextProvider';

const propTypes = {
  is_detail: PropTypes.bool,
};

const AddressChangeModal = (props) => {
  //Modal
  const { alert } = useDialogContext();
  const dialog_props = props.dialog_props || {};
  const dialog_utils = props.dialog_utils || {};

  //Address State
  const [step, setStep] = useState(0);
  const [road_address, setRoadAddress] = useState(null);
  const [address_detail, setAddressDetail] = useState(null);
  const [address_code, setAddressCode] = useState(null);

  const onComplete = async (address) => {
    if (step === 1) {
      if (address_detail === null || address_detail === '') {
        await alert('상세주소를 입력해주세요.');
        return;
      }
      props.onComplete({
        roadAddress: road_address,
        zonecode: address_code,
        address_detail: address_detail,
      });
      dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
    } else {
      if (props?.is_detail) {
        setRoadAddress(address.roadAddress);
        setAddressCode(address.zonecode);
        setStep(1);
      } else {
        props.onComplete(address);
        dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
      }
    }
  };

  const onCloseModalBtn = () => {
    dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
  };

  return (
    <div className='td-modal address-change-modal'>
      <div className='td-dimmed' onClick={onCloseModalBtn} />
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>주소찾기</h3>
          <div
            className='modal-close-btn'
            style={{
              background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`,
            }}
            onClick={onCloseModalBtn}
          />
        </div>
        {step === 0 && (
          <div className='modal-section'>
            <DaumPostcode height={471} onComplete={onComplete} submitMode={false} />
            {/* <DaumPostcode2
                    style={{ height: 471, }}
                    daum={{
                        onComplete,
                        submitMode: false
                    }}/> */}
          </div>
        )}
        {step === 1 && (
          <div className='modal-section' style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '18px', margin: '14px 0 7px' }}>
              ({address_code}) {road_address}
            </p>
            <input
              style={{ fontSize: '20px', textAlign: 'center', margin: '7px 0 14px', width: '100%' }}
              type='text'
              placeholder='상세주소를 입력해주세요.'
              onChange={(e) => {
                setAddressDetail(e.target.value);
              }}
            />
            <button className='td-btn' onClick={onComplete}>
              완료
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

AddressChangeModal.propTypes = propTypes;

export default AddressChangeModal;
