import axios from 'axios';

class CourseModel {
  async courseList({
    page,
    size,
    category_id = null,
    search_text = null,
    is_mine = 0,
    order_option = null,
    seed = 12345,
  }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/course`, {
      params: { page, size, category_id, search_text, is_mine, order_option, seed },
    });
    let course_set = code === 200 ? data.course_set : [];
    return { code, course_set };
  }

  async courseDetail({ course_id, only_type, event_code = null }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/course/${course_id}`, {
      params: { only_type, event_code },
    });
    let event = code === 200 ? data.event : null;
    let course = code === 200 ? data.course : null;
    return { code, course, event };
  }

  async subjectDetail({ subject_id, is_v2_player }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/course/subject/${subject_id}`, {
      params: { is_v2_player },
    });
    let subject = code === 200 ? data.subject : null;
    return { code, subject };
  }

  async subjectViewCheck({ subject_id }) {
    const {
      data: { code },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/course/subject/${subject_id}`);
    return { code };
  }

  async subjectViewTimeCheck({ subject_id, second }) {
    const {
      data: { code },
    } = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/course/subject/${subject_id}`, {
      second,
    });
    return { code };
  }

  async courseFavorite({ course_id }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/course/${course_id}/favorite`);
    let { is_favorite, favorite_count, account_class_favorite_count } = code === 200 ? data : {};
    return { code, is_favorite, favorite_count, account_class_favorite_count };
  }

  async promotionList({ promotion_type_id, with_product_set }) {
    const {
      data: {
        code,
        data: { course_set, product_set },
      },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/course/promotion`, {
      params: { promotion_type_id, with_product_set },
    });
    return { code, course_set, product_set };
  }

  async courseReviewList({ course_id, page, page_size }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/course/review`, {
      params: { course_id },
    });
    const { total_count, avg_rating, is_write } = code === 200 ? data : {};

    const getReviewSet = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/course/review-set`,
      {
        params: { course_id, page, page_size },
      },
    );
    const getReviewSetResult = getReviewSet.data;
    const getReviewSetCode = getReviewSetResult.code;
    const getReviewSetData = getReviewSetResult.data;
    const { review_set } = getReviewSetCode === 200 ? getReviewSetData : {};

    return { code, total_count, avg_rating, review_set, is_write };
  }

  async courseReviewReplyList({ course_id, page, page_size, parent_id }) {
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/course/review-set`, {
      params: { course_id, page, page_size, parent_id },
    });

    const { review_set } = code === 200 ? data : {};
    return { code, review_set };
  }

  async courseReviewWrite({ course_id, content, rating, parent_id }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/course/review`, {
      course_id,
      content,
      rating,
      parent_id,
    });
    return { code, data };
  }

  async courseReviewModify({ review_id, content, rating }) {
    const {
      data: { code, data },
    } = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/course/review`, {
      review_id,
      content,
      rating,
    });
    return { code, data };
  }

  async courseReviewDelete({ review_id }) {
    const {
      data: { code, data },
    } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/course/review`, {
      data: { review_id },
    });
    return { code, data };
  }

  async courseQNAList({ course_id, size, parent_id, last_visible_id }) {
    //last_visible_id를 이용해 새로운 목록을 불러오는 방식으로 바뀌면서, page는 모두 null을 전달
    const {
      data: { code, data },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/course/qna`, {
      params: { course_id, size, parent_id, last_visible_id },
    });
    let qna_set = code === 200 ? data.qna_set : [];
    return { code, qna_set };
  }

  async courseQNAWrite({ course_id, content, parent_id, question_video_second, subject_id }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/course/qna`, {
      course_id,
      content,
      parent_id,
      question_video_second,
      subject_id,
    });
    let { qna } = code === 200 ? data : {};
    return { code, qna };
  }

  async courseQNAModify({ qna_id, content }) {
    const {
      data: { code, data },
    } = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/course/qna`, { qna_id, content });
    let { qna } = code === 200 ? data : {};
    return { code, qna };
  }

  async courseQNADelete({ qna_id }) {
    const {
      data: { code, data },
    } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/course/qna`, {
      data: { qna_id },
    });
    return { code, data };
  }
}

export default new CourseModel();
