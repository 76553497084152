import React, { useState } from 'react';
import TemplateInput from '../_template/TemplateInput';
import PropTypes from 'prop-types';
import { add_dash_to_birth_date } from '../../../common';
import InputLabel from './InputLabel';

const propTypes = {
  default_value: PropTypes.string, // 회원 수정 시 받아올 default value
  require: PropTypes.bool, // require 표시 여부
  input_list_ref: PropTypes.array, // focus event ref
  on_data_changed: PropTypes.func, // text, validated
};

const BirthInput = (props) => {
  const [birth_input, setBirthInput] = useState(props?.default_value ? props.default_value : '');
  const [birth_error_message, setBirthErrorMessage] = useState('');
  const [is_birth_error, setIsBirthError] = useState(false);
  const require = props?.require ?? true;

  // 유효성 검사
  const checkBirthValidate = (text) => {
    const regex_phone = /^[0-9\b -]{0,13}$/;
    return regex_phone.test(text);
  };

  return (
    <div className='birth-input-wrap'>
      <InputLabel label={'생년월일'} require={require} />
      <TemplateInput
        is_error={is_birth_error}
        error_message={birth_error_message}
        on_validate={(text) => {
          if (text === '') {
            setIsBirthError(true);
            setBirthErrorMessage('필수항목입니다');
          } else if (text.length !== 10) {
            setIsBirthError(true);
            setBirthErrorMessage('입력값을 확인해주세요');
          } else {
            setIsBirthError(false);
            setBirthErrorMessage('');
          }
        }}
        input_props={{
          value: birth_input,
          placeholder: 'YYYY-MM-DD',
          maxLength: 10,
          ref: props?.input_list_ref,
          onFocus: () => {
            setIsBirthError(false);
            setBirthErrorMessage('');
          },
          onBlur: (text) => {
            let validated = checkBirthValidate(text) && text.length === 10;
            props.on_data_changed && props.on_data_changed(text, validated);
          },
          onChange: (text) => {
            let validated = checkBirthValidate(text) && text.length === 10;
            if (checkBirthValidate(text)) {
              setBirthInput(add_dash_to_birth_date(text).replace(/\s/gi, ''));
            }
            props.on_data_changed && props.on_data_changed(text, validated);
          },
        }}
      />
    </div>
  );
};

BirthInput.prototype = propTypes;

export default BirthInput;
