import { useCallback, useEffect, useRef, useState } from 'react';
import { linear } from './animation_utils';

// [옵저버 훅]
// const ref = useObserver(옵션, 들어올때 실행함수,나갈때 실행함수, 변경뎁스)
export const useObserver = (options, in_func, out_func, deps = []) => {
  const ref = useRef(null);
  console.log('ref', ref);
  console.log('in_func', in_func);
  console.log('out_func', out_func);
  console.log('deps', deps);
  const checkObserve = useCallback(([entry]) => {
    if (entry.isIntersecting) {
      if (in_func) in_func();
    } else {
      if (out_func) out_func();
    }
  }, deps);

  useEffect(() => {
    const observer = new IntersectionObserver(checkObserve, options);
    console.log('observer', observer);
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, deps);
  return ref;
};

// [인터벌 훅]
// 실행함수, 딜레이
export const useInterval = (func, delay, direct_fire = false) => {
  useEffect(() => {
    if (delay) {
      direct_fire && func && func();
      let id = setInterval(func, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

// [타임아웃 훅]
// 실행함수, 딜레이
export const useTimeout = (func, delay) => {
  useEffect(() => {
    if (delay) {
      let id = setTimeout(func, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};

// [지정된 요소의 외부 클릭 감지 훅]
export const useClickOutside = (ref, func, ignore_ref_list = []) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event) => {
      if (startedInside || !startedWhenMounted) return;
      for (let i = 0; i < ignore_ref_list.length; i++) {
        if (ignore_ref_list[i].current?.contains(event.target)) {
          return;
        }
      }

      func(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    if (ref.current?.children.length) {
      document.addEventListener('mousedown', validateEventStart);
      document.addEventListener('touchstart', validateEventStart);
      document.addEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('mousedown', validateEventStart);
      document.removeEventListener('touchstart', validateEventStart);
      document.removeEventListener('click', listener);
    };
  }, [ref, func, ignore_ref_list]);
};

// 변경감지 훅
export const useMutaionObserver = (ref, func, deps = []) => {
  useEffect(() => {
    const obs = new MutationObserver(func);
    if (ref.current) {
      obs.observe(ref.current, {
        subtree: true,
        attributes: true,
        childList: true,
        characterData: true,
      });
    }
    return () => {
      if (obs) {
        obs.disconnect();
      }
    };
  }, deps);
};

/**
 * History.pushState
 * https://developer.mozilla.org/ko/docs/Web/API/History/pushState
 * state 통한 뒤로가기 감지
 * @TODO hpark state 추가된 상태에서 새로고침시에 대한 대응코드 작성 가능성
 * @TODO hpark 현 dialog 기준으로 작성되다보니 본 구현부등이 어렵게 작성되있는 부분 있음, 향후 dialog lifecycle 에 따라 재반영 필요
 * @param on_pop_state(event, prev_path, current_path)
 * @param deps
 * @returns { push: ({stateObj, title, url}) => void, replace: ({stateObj, title, url}) => void, back: ()=>{}, }
 */
export const useBackStateHandler = (on_pop_state, deps = []) => {
  const url_list = useRef([]);
  const origin_path = useRef('/');

  const onPopState = (event) => {
    let last_url = pop();
    on_pop_state && on_pop_state(event, last_url, getPathname(), url_list.current.length);
  };

  const pop = () => {
    return url_list.current.pop();
  };

  const getPathname = () => {
    return window.location.href.substring(window.location.origin.length + 1);
  };

  useEffect(() => {
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, deps);

  return {
    push: ({ state_obj, title, url, push_state = true }) => {
      url_list.current.push(url);
      if (push_state) {
        window.history.pushState(state_obj, title, url);
      }
    },
    replace: ({ state_obj, title, url }) => {
      url_list.current = [url];
      window.history.replaceState(state_obj, title, url);
    },
    back: () => {
      window.history.back();
    },
    getOriginPath: () => {
      return origin_path.current;
    },
    getPathname,
  };
};

/**
 * 뒤로가기 방패
 * @param on_before_unload(event)
 * @param deps
 * @returns { block: (is_accept=false) => void, }
 */
export const useBeforeUnload = (on_before_unload, deps = []) => {
  const block = useRef(false);

  const onBeforeUnload = (event) => {
    on_before_unload && on_before_unload(event);

    if (block.current) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.addEventListener('beforeunload', onBeforeUnload);
    };
  }, deps);

  return {
    block: (is_block = true) => {
      block.current = is_block;
    },
  };
};

/**
 * 스크립트 동적 로드
 * @param src: string
 * @param on_script_load: () => void
 * @param on_script_error: () => void
 * @returns { getStatus: () => "ready|load|error", }
 */
export const useScript = (src, on_script_load, on_script_error) => {
  const STATUS_READY = 'ready';
  const STATUS_LOAD = 'load';
  const STATUS_ERROR = 'error';

  const status = useRef(STATUS_READY);
  const [is_load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(false);
    status.current = STATUS_READY;

    let script = window.document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = window.document.createElement('script');
      script.src = src;
      script.async = true;

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      window.document.body.appendChild(script);
    }

    return () => {
      status.current = STATUS_READY;
      if (script) {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
        script.remove();
      }
    };
  }, [src]);

  const onScriptLoad = () => {
    setLoad(true);
    status.current = STATUS_LOAD;
    on_script_load && on_script_load();
  };

  const onScriptError = () => {
    setLoad(true);
    status.current = STATUS_ERROR;
    on_script_error && on_script_error();
  };

  return [
    is_load,
    {
      getStatus: () => {
        return status.current;
      },
    },
  ];
};

/**
 * @author 혁미
 * @param on_resize function
 * @param deps
 */
export const useResize = (on_resize, deps = []) => {
  const onResize = () => {
    on_resize && on_resize();
  };

  useEffect(() => {
    on_resize && on_resize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, deps);
};

export const useMount = (deps = []) => {
  const is_mount = useRef(false);

  const isMount = () => {
    return is_mount.current;
  };

  useEffect(() => {
    is_mount.current = true;
    return () => {
      is_mount.current = false;
    };
  }, deps);

  return [isMount];
};

// 애니메이션 타이밍 함수
export const useTimingFunction = (duration, fps, timing_func = linear) => {
  const worker = useRef(null);
  const start = ({ on_timing, data }) => {
    stop();

    let interval = 1000 / fps;
    let start_time = getCurrentMillisecond();
    worker.current = setInterval(() => {
      let elapsed_time = getCurrentMillisecond() - start_time;
      let t = elapsed_time / duration;
      if (t > 1) {
        t = 1;
      }
      on_timing && on_timing(timing_func(t), data);

      if (t >= 1) {
        stop();
      }
    }, interval);
  };

  const stop = () => {
    clearInterval(worker.current);
    worker.current = null;
  };

  const getCurrentMillisecond = () => {
    return new Date().getTime();
  };

  return [start, stop];
};

export const getSeed = () => {
  const moment = require('moment');
  const currentTimeMs = moment().valueOf();

  const seedDate = currentTimeMs;
  const random = Math.floor((seedDate % 100000) * Math.random());
  return String(random).padStart(5, '0');
};
