import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';
import { useDispatch, useSelector } from 'react-redux';

import '../../assets/scss/page/oppy.scss';
import { set_header_type } from '../../redux/common/action';

const HardStudy2 = () => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const is_top_banner_visible = CommonStore.tmp?.is_top_banner_visible ?? false;

  useEffect(() => {
    let origin_class_name = window.document.body.className;
    window.document.body.className = 'overflow';
    dispatch(
      set_header_type(0, 1, { no_footer: true, no_top_btn: true, no_channel_talk_button: true }),
    );

    ReactPixel.trackCustom('빡공단 페이지 조회');

    return () => {
      window.document.body.className = origin_class_name;
    };
  }, []);

  return (
    <div className={'oopy-wrap' + (is_top_banner_visible ? '' : ' hide')}>
      <Helmet>
        <title>빡공단 : 자기계발 챌린지</title>
      </Helmet>
      <div className='bgd-container withOopy'>
        <div className='top-nav'>
          <a href='/bgd' className={`top-item`}>
            하루 10분 빡공단 챌린지
          </a>
          <div className={`top-item active`}>러닝크루 환급챌린지</div>
        </div>
      </div>

      <iframe title={'hardstudy oppy'} src='https://hardstudy.oopy.io/bgdxreward' />
    </div>
  );
};

export default HardStudy2;
