/* Common */
export const STORED_STATIC_CODE = 'STORED_STATIC_CODE';
export const RESET_STATIC_CODE = 'RESET_STATIC_CODE';
export const SET_HEADER_TYPE = 'SET_HEADER_TYPE';
export const SET_FOOTER_TYPE = 'SET_FOOTER_TYPE';
export const SET_TMP = 'SET_TMP';
export const SET_NOTI = 'SET_NOTI';
export const SET_VERSION = 'SET_VERSION';
export const STORED_SERVICE = 'STORED_SERVICE';
export const RESET_TMP = 'RESET_TMP';

/* Account */
export const STORED_ACCOUNT_INFO = 'STORED_ACCOUNT_INFO';
export const SET_ACCOUNT_FAVORITE_COUNT = 'SET_ACCOUNT_FAVORITE_COUNT';
export const SET_ACCOUNT_EBOOK_FAVORITE_COUNT = 'SET_ACCOUNT_EBOOK_FAVORITE_COUNT';
