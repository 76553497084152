import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../../assets/scss/page/drop_out.scss';
import AccountModel from '../../model/AccountModel';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch } from 'react-redux';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';

const DropOut = (props) => {
  const dispatch = useDispatch();
  const [sign, setSign] = useState({
    value: '',
    err_msg: '',
  });
  const { confirm, alert } = useDialogContext();

  useEffect(() => {
    dispatch(
      set_header_type(0, 2, {
        title: '계정 탈퇴',
        no_footer: true,
        no_top_btn: true,
      }),
    );
    dispatch(set_footer_type(0));
    props.ready();
  }, []);

  const doDropOut = async () => {
    if (await confirm('정말로 탈퇴 하시겠습니까?')) {
      AccountModel.dropOut().then(async ({ code }) => {
        if (code === 200) {
          await alert('그동한 이용해주셔서 감사합니다.');
          AccountModel.signOut().then(({ code }) => {
            let course_card_set = document.querySelectorAll(`.list-item`);
            course_card_set.forEach((data) => {
              let img = data.querySelector('.thumbnail-wrap img');
              img.src = `${process.env.REACT_APP_IMG_URL}/static/svg/common/favorites-default-2.svg`;
            });
            window.location.replace('/');
          });
        }
      });
    } else {
      setSign({ ...sign, value: '', err_msg: '' });
    }
  };

  const onInputChange = (e) => {
    setSign({ ...sign, value: e.target.value, err_msg: '' });
  };

  const onInputValidation = () => {
    if (sign.value === '탈퇴') {
      doDropOut();
    } else {
      setSign({ ...sign, value: '', err_msg: "'탈퇴' 를 정확히 입력해주세요." });
    }
  };

  return (
    <div className='user-container login drop-out-container'>
      <Helmet>
        <title>계정 탈퇴</title>
      </Helmet>
      <div className='td-wrapper'>
        <div className='content'>
          <h2 className='common-center-title type1'>계정 탈퇴</h2>
          <p>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-information-outline.svg`}
              alt='가입하신 이메일 주소를 정확히 입력 해주세요.'
            />
            계정을 탈퇴하시는 경우,
            <br />
            해당 계정으로는 재가입이 불가능 합니다.
          </p>
          <div className='text-input'>
            <label>확인 서명</label>
            <input
              type='text'
              value={sign.value}
              placeholder="'탈퇴' 를 정확히 입력해주세요."
              onChange={(e) => {
                onInputChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onInputValidation(e);
              }}
            />
            <span>{sign.err_msg}</span>
          </div>
          <div className='td-btn drop-out-btn' onClick={onInputValidation}>
            탈퇴하기
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropOut;
