import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../../assets/scss/page/bgd.scss';
import RecruitFormModel from '../../model/RecruitFormModel';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import BgdNotActive from './component/BgdNotActive';
import BgdXNotActive from './component/BgdXNotActive';
import { get_kor_date_with_week, init_comma, is_today_active_range } from '../../common';
import { Helmet } from 'react-helmet';

const Bgd = (props) => {
  const { confirm } = useDialogContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serviceType = searchParams.get('type');
  const AccountStore = useSelector((state) => state.AccountStore);
  const [activeContent, setActiveContent] = useState(
    serviceType === 'bgdX' ? 'challenge2' : 'challenge1',
  );

  const currentDate = new Date();
  const compareDate = new Date('2024-06-30T23:59:59');
  const isNotActiveBgdXForm = currentDate < compareDate;

  useEffect(() => {
    if (isNotActiveBgdXForm && (serviceType === 'bgdX' || activeContent === 'challenge2')) {
      props.history.push('/hardstudy');
    }
  }, [isNotActiveBgdXForm, activeContent]);

  const [recruitFormData, setRecruitFormData] = useState({
    activeContent: serviceType === 'bgdX' ? 'challenge2' : 'challenge1',
    bgd: {
      formData: {},
      eventCode: '',
      isActiveRecruit: false,
      prices: 0,
    },
    bgdX: {
      formData: {},
      eventCode: '',
      isActiveRecruit: false,
      prices: 0,
    },
  });

  const fetchRecruitData = async () => {
    const [bgdData, bgdXData] = await Promise.all([
      RecruitFormModel.getCurrentRecruitingBgd(),
      RecruitFormModel.getCurrentRecruitingBgdX(),
    ]);

    setRecruitFormData((prevState) => ({
      bgd: {
        ...prevState.bgd,
        formData: bgdData || {},
        eventCode: bgdData.event_code || '',
        isActiveRecruit: bgdData
          ? is_today_active_range(bgdData?.apply_start_time, bgdData?.apply_end_time)
          : false,
      },
      bgdX: {
        ...prevState.bgdX,
        formData: {},
        eventCode: '',
        isActiveRecruit: bgdXData
          ? is_today_active_range(bgdXData?.apply_start_time, bgdXData?.apply_end_time)
          : false,
      },
    }));
  };

  useEffect(() => {
    fetchRecruitData();
  }, []);

  useEffect(() => {}, [recruitFormData]);

  useEffect(() => {
    const fetchPrices = async () => {
      const res = await RecruitFormModel.getEventInfo({ eventCode: recruitFormData.bgd.eventCode });
      if (recruitFormData.bgd.eventCode) {
        setRecruitFormData((prevState) => ({
          ...prevState,
          bgd: {
            ...prevState.bgd,
            prices: res?.data?.data?.event?.discount_info?.override_price || 0,
          },
        }));
      } else if (recruitFormData.bgdX.eventCode) {
        const res = await RecruitFormModel.getEventInfo({
          eventCode: recruitFormData.bgdX.eventCode,
        });
        setRecruitFormData((prevState) => ({
          ...prevState,
          bgdX: {
            ...prevState.bgdX,
            prices: res?.data?.data?.event?.discount_info?.override_price || 0,
          },
        }));
      }
    };
    fetchPrices();
  }, [recruitFormData.bgd.eventCode, recruitFormData.bgdX.eventCode]);

  const statusCheck = async (goToUrl) => {
    if (!AccountStore?.id) {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}`,
          },
        });
      }
    } else if (!AccountStore?.info?.email || !AccountStore?.info?.phone) {
      if (
        await confirm(
          '빡공단에 참여하기 위해서는\n 이메일과 전화번호가 필수 입니다.\n 프로필을 이어서 작성 하시겠습니까?',
        )
      ) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}`,
          },
        });
      }
    } else {
      props.history.push(goToUrl);
    }
  };

  const dateConverter = (date) => {
    return get_kor_date_with_week(date, 'M월 D일');
  };

  const changeTab = (tabName) => {
    props.history.push('/bgd');
    setActiveContent(tabName);
  };

  return (
    <div className='bgd-container'>
      <Helmet>
        <title>빡공단 모집</title>
      </Helmet>
      <div className='top-nav'>
        <p
          className={`top-item ${activeContent === 'challenge1' ? 'active' : ''}`}
          onClick={() => changeTab('challenge1')}
        >
          하루 10분 빡공단 챌린지
        </p>
        <p
          className={`top-item ${activeContent === 'challenge2' ? 'active' : ''}`}
          onClick={() => changeTab('challenge2')}
        >
          러닝크루 환급챌린지
        </p>
      </div>

      <div className='content-container'>
        {activeContent === 'challenge1' &&
          (recruitFormData.bgd.isActiveRecruit ? (
            <>
              <div className='content-top-img'>
                <img
                  src={recruitFormData.bgd.formData.thumbnail_img}
                  alt='자기계발 챌린지 빡공단 하루 10분, 30일 동안 습관과 스펙을 만드세요.'
                />
              </div>

              <div className='content-area'>
                <div className='desc-area'>
                  <div>
                    <div className='gray-card desc-text'>
                      <div>💡</div>
                      <div>
                        <p className='text-gray-03'>
                          {' '}
                          자기계발이 막막하다면 빡공단으로 시작하세요!
                        </p>
                        <button
                          type='button'
                          className='apply-btn'
                          onClick={() => {
                            statusCheck('/bgd/recruit?type=bgd');
                          }}
                        >
                          빡공단 신청하기
                        </button>
                      </div>
                    </div>

                    <table className='desc-info gray-line-card'>
                      <tbody>
                        <tr>
                          <td rowSpan={99}>
                            <p>🗓️</p>
                          </td>
                          <td>
                            <p>신청 기간</p>
                            <span>
                              {`${dateConverter(recruitFormData.bgd.formData.apply_start_time)}
											 ~ ${dateConverter(recruitFormData.bgd.formData.apply_end_time)}`}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>선발 결과 발표</p>
                            <span>
                              {dateConverter(recruitFormData.bgd.formData.result_notice_time)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>참가 비용</p>
                            <span>
                              <span className='text-deco-line-through'>{init_comma(159000)}원</span>{' '}
                              -&gt; {init_comma(recruitFormData?.bgd?.prices)}원
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>클래스 신청</p>
                            <span>
                              {`${dateConverter(recruitFormData.bgd.formData.course_register_start_time)}
											 ~ ${dateConverter(recruitFormData.bgd.formData.course_register_end_time)}`}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>활동 기간</p>
                            <span>
                              {`${dateConverter(recruitFormData.bgd.formData.apply_start_time)}
											 ~ ${dateConverter(recruitFormData.bgd.formData.apply_end_time)}`}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    className='desc-html'
                    dangerouslySetInnerHTML={{ __html: recruitFormData.bgd.formData.content_html }}
                  />

                  {recruitFormData.bgd.formData.event_code && (
                    <div>
                      <Link to={`/bgd/class-list/${recruitFormData.bgd.formData.event_code}`}>
                        신청 가능 강의 리스트 보러가기
                      </Link>
                    </div>
                  )}
                </div>

                <div className='btn-area'>
                  <button
                    className='apply-btn'
                    onClick={() => {
                      statusCheck('/bgd/recruit?type=bgd');
                    }}
                  >
                    빡공단 신청하기
                  </button>
                </div>
              </div>
            </>
          ) : (
            <BgdNotActive />
          ))}

        {activeContent === 'challenge2' &&
          (recruitFormData.bgdX.isActiveRecruit ? (
            <>
              <div className='content-top-img'>
                <img
                  src={recruitFormData.bgdX.formData.thumbnail_img}
                  alt='목표달성 환급 챌린지 러닝크루 매일의 작은 기록으로 우리는 큰 결과를 만들어 냅니다.'
                />
              </div>

              <div className='content-area'>
                <div className='desc-area'>
                  <div>
                    <p style={{ fontSize: '1.875rem', fontWeight: '600' }}>
                      목표달성과 환급까지 두마리 토끼를 한번에!
                    </p>

                    <div className='gray-card desc-text'>
                      <div>✅</div>
                      <div>
                        <p className='text-gray-03'>
                          러닝크루(Learning Crew)는 매일 최소 1강 이상의 꾸준한 학습을 기록하고,
                          ‘끝까지’ 목표를 향해 달려가는 사람들의 커뮤니티입니다. 빡공단X와 함께
                          진정한 성장을 경험하고, 나아가 목표 달성에 대한 보상으로 환급까지!
                        </p>
                        <p style={{ fontSize: '1.25rem', fontWeight: '600', marginTop: '10px' }}>
                          당신의 성장에 한계는 없습니다. 지금 시작해보세요!
                        </p>

                        <button
                          type='button'
                          className='apply-btn'
                          onClick={() => {
                            statusCheck('/bgd/recruit?type=bgdX');
                          }}
                        >
                          👉️ 러닝크루 환급챌린지 신청하기
                        </button>
                      </div>
                    </div>

                    <table className='desc-info gray-line-card'>
                      <tbody>
                        <tr>
                          <td rowSpan={99}>
                            <p>🗓️</p>
                          </td>
                          <td>
                            <p>러닝크루 환급챌린지 신청기간</p>
                            <span>
                              {`${dateConverter(recruitFormData.bgdX.formData.apply_start_time)}
												 ~ ${dateConverter(recruitFormData.bgdX.formData.apply_end_time)}`}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>선발결과 발표</p>
                            <span>
                              {dateConverter(recruitFormData.bgdX.formData.result_notice_time)}
                            </span>
                          </td>
                        </tr>
                        <tr className='d-flex w-full'>
                          <td>
                            <p>참가 비용</p>
                            <span>
                              <span className='text-deco-line-through'>{init_comma(159000)}원</span>{' '}
                              -&gt; {init_comma(recruitFormData?.bgdX?.prices)}원
                            </span>
                            <strong className='purchase-merit'>
                              <i>→ 미션완주시 100% 전액 환급</i>
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>클래스 신청</p>
                            <span>
                              {`${dateConverter(recruitFormData.bgdX.formData.course_register_start_time)}
												 ~ ${dateConverter(recruitFormData.bgdX.formData.course_register_end_time)}`}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>활동기간</p>
                            <span>
                              {`${dateConverter(recruitFormData.bgdX.formData.apply_start_time)} 시작`}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='desc-html'
                    dangerouslySetInnerHTML={{ __html: recruitFormData.bgdX.formData.content_html }}
                  />
                  {recruitFormData.bgdX.formData.event_code && (
                    <div>
                      <Link to={`/bgd/class-list/${recruitFormData.bgdX.formData.event_code}`}>
                        신청 가능 강의 리스트 보러가기
                      </Link>
                    </div>
                  )}
                </div>

                <div className='btn-area'>
                  <button
                    className='apply-btn'
                    onClick={() => {
                      statusCheck('/bgd/recruit?type=bgdX');
                    }}
                  >
                    빡공단X 신청하기
                  </button>
                </div>
              </div>
            </>
          ) : (
            <BgdXNotActive />
          ))}
      </div>
    </div>
  );
};

export default Bgd;
