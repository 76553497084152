import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import '../../assets/scss/page/sign_up.scss';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import EmailInput from '../_common/Input/EmailInput';
import PasswordInput from '../_common/Input/PasswordInput';
import NameInput from '../_common/Input/NameInput';
import PhoneInput from '../_common/Input/PhoneInput';
import BirthInput from '../_common/Input/BirthInput';
import GenderButton from '../_common/Input/GenderButton';
import JobSelectBox from '../_common/Input/JobSelectBox';
import AccountModel from '../../model/AccountModel';
import { stored_account_info } from '../../redux/account/action';
import AgreeCheckList from '../_common/component/AgreeCheckList';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import { useMount } from '../../util/BearuHooks';

const SignUp = (props) => {
  const dispatch = useDispatch();
  const { alert } = useDialogContext();
  const [isMount] = useMount();

  const [validate, setValidate] = useState(false);
  const [re_password_error, setRePasswordError] = useState(false);
  const [error, setError] = useState({
    gender: true,
    job_class_id: true,
    agreement_terms: true,
  });

  useEffect(() => {
    props.ready();
    dispatch(
      set_header_type(0, 2, {
        title: '회원가입',
        no_footer: true,
        no_top_btn: true,
      }),
    );
    dispatch(set_footer_type(0));
  }, []);

  // input 입력값
  const input_values = useRef({
    email: '',
    password: '',
    re_password: '',
    name: '',
    phone: '',
    birth: '',
    gender: '',
    job_class_id: '',
  });

  // input ref
  const input_list_ref = useRef({
    email: null,
    password: null,
    re_password: null,
    name: null,
    phone: null,
    birth: null,
  });

  // validate check
  const is_values_check = useRef({
    email: false,
    password: false,
    re_password: false,
    name: false,
    phone: false,
    birth: false,
    gender: false,
    job_class_id: false,
    agreement_terms: false,
  });

  const checkRePasswordValidate = () => {
    const current_password = input_values.current.password;
    const current_re_password = input_values.current.re_password;

    if (current_password.length === 0 || current_re_password.length === 0) {
      setRePasswordError(false);
    } else {
      if (current_password !== current_re_password) {
        setRePasswordError(true);
      } else {
        setRePasswordError(false);
      }
    }
  };

  const checkValidate = () => {
    return Object.values(is_values_check.current).every((value) => value);
  };

  const onSignUp = async () => {
    if (!checkValidate()) {
      await alert('입력항목을 다시 확인해주세요!');
      Object.values(input_list_ref.current).forEach((el) => {
        el.focus({ preventScroll: true });
        el.blur();
      });
      setError({
        ...error,
        gender: is_values_check.current.gender,
        job_class_id: is_values_check.current.job_class_id,
        agreement_terms: is_values_check.current.agreement_terms,
      });
      return;
    }

    let { code, account } = await AccountModel.signUp({
      email: input_values.current.email,
      password: input_values.current.password,
      name: input_values.current.name,
      phone: input_values.current.phone,
      birth: input_values.current.birth,
      gender: input_values.current.gender,
      job_class_id: input_values.current.job_class_id,
    });

    if (isMount()) {
      if (code === 200) {
        dispatch(stored_account_info(account));
        props.history.push('/user/signup/complete');
      } else if (code === 4501) {
        await alert('이미 가입되어 있는 이메일입니다.');
      } else {
        await alert('오류가 발생하였습니다.');
      }
    }
  };

  return (
    <div className='td-container-v2'>
      <Helmet>
        <title>회원가입</title>
      </Helmet>

      <div className='td-wrapper-v2'>
        <div className='sign-up-wrap template-input-wrap'>
          <h2 className='common-center-title type2'>회원가입</h2>

          <form>
            {/* 이메일 계정 */}
            <EmailInput
              input_list_ref={(el) => (input_list_ref.current.email = el)}
              auto_focus='autoFocus'
              on_data_changed={(text, validated) => {
                if (validated) {
                  input_values.current.email = text;
                  is_values_check.current.email = true;
                } else {
                  input_values.current.email = '';
                  is_values_check.current.email = false;
                }
                setValidate(checkValidate());
              }}
            />

            {/* 비밀번호 */}
            <PasswordInput
              input_list_ref={(el) => (input_list_ref.current.password = el)}
              enable_show_password={true}
              auto_complete='new-password'
              on_data_changed={(password, validated) => {
                if (validated) {
                  input_values.current.password = password;
                  is_values_check.current.password = true;
                } else {
                  input_values.current.password = '';
                  is_values_check.current.password = false;
                }
                checkRePasswordValidate();
                setValidate(checkValidate());
              }}
              on_validate={(validated) => {
                if (re_password_error) {
                  input_list_ref.current.re_password.focus();
                  input_list_ref.current.re_password.blur();
                }
                is_values_check.current.password = !!validated;
                setValidate(checkValidate());
              }}
            />

            {/* 비밀번호 확인 */}
            <PasswordInput
              label={'비밀번호 확인'}
              enable_show_password={true}
              input_list_ref={(el) => (input_list_ref.current.re_password = el)}
              force_error={re_password_error}
              input_props={{ placeholder: '비밀번호를 다시 입력해주세요' }}
              error_text={'비밀번호와 동일하지 않습니다'}
              on_data_changed={(re_password, validated) => {
                if (validated) {
                  input_values.current.re_password = re_password;
                  is_values_check.current.re_password = true;
                } else {
                  input_values.current.re_password = '';
                  is_values_check.current.re_password = false;
                }
                checkRePasswordValidate();
                setValidate(checkValidate());
              }}
              on_validate={(validated) => {
                is_values_check.current.re_password = !!validated;
                setValidate(checkValidate());
              }}
            />

            {/* 이름 */}
            <NameInput
              input_list_ref={(el) => (input_list_ref.current.name = el)}
              on_data_changed={(text, validated) => {
                if (validated) {
                  input_values.current.name = text;
                  is_values_check.current.name = true;
                } else {
                  input_values.current.name = '';
                  is_values_check.current.name = false;
                }
                setValidate(checkValidate());
              }}
            />

            {/* 휴대전화 */}
            <PhoneInput
              input_list_ref={(el) => (input_list_ref.current.phone = el)}
              on_data_changed={(text, validated) => {
                if (validated) {
                  input_values.current.phone = text;
                  is_values_check.current.phone = true;
                } else {
                  input_values.current.phone = '';
                  is_values_check.current.phone = false;
                }
                setValidate(checkValidate());
              }}
            />

            {/* 생년월일 */}
            <BirthInput
              input_list_ref={(el) => (input_list_ref.current.birth = el)}
              on_data_changed={(text, validated) => {
                if (validated) {
                  input_values.current.birth = text;
                  is_values_check.current.birth = true;
                } else {
                  input_values.current.birth = '';
                  is_values_check.current.birth = false;
                }
                setValidate(checkValidate());
              }}
            />

            {/* 성별 */}
            <GenderButton
              force_error={error.gender}
              on_data_changed={(text, validate) => {
                if (validate) {
                  input_values.current.gender = text;
                  is_values_check.current.gender = true;
                } else {
                  input_values.current.gender = '';
                  is_values_check.current.gender = false;
                }
                setValidate(checkValidate());
              }}
            />

            {/* 직종 */}
            <JobSelectBox
              force_error={error.job_class_id}
              on_data_changed={(text) => {
                if (text === '') {
                  input_values.current.job_class_id = '';
                  is_values_check.current.job_class_id = false;
                } else {
                  input_values.current.job_class_id = text;
                  is_values_check.current.job_class_id = true;
                }
                setValidate(checkValidate());
              }}
            />
          </form>

          {/* 이용약관 및 개인정보처리방침 동의 */}
          <AgreeCheckList
            force_error={error.agreement_terms}
            on_data_changed={(is_agree_check) => {
              is_values_check.current.agreement_terms = is_agree_check;
              setValidate(checkValidate());
            }}
          />

          <div className='login-btn'>
            <button
              type='button'
              onClick={onSignUp}
              tabIndex={0}
              style={validate ? { backgroundColor: '#4745FF' } : { backgroundColor: '#d8d8d8' }}
            >
              가입하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
