import Main from '../views/main/Main';
import Permission from '../util/Permission';
import SignIn from '../views/account/SignIn';
import SignUp from '../views/account/SignUp';
import Notice from '../views/service/Notice';
import NoticeDetail from '../views/service/NoticeDetail';
import Event from '../views/service/Event';
import Faq from '../views/service/Faq';
import Privacy from '../views/service/Privacy';
import Terms from '../views/service/Terms';
import Master from '../views/service/Master';
import Search from '../views/search/Search';
import Overview from '../views/course/Overview';
import CourseDetail from '../views/course/CourseDetail';
import InfoViewer from '../views/service/InfoViewer';
import BearuLab from '../views/service/BearuLab';
import Purchase from '../views/purchase/Purchase';
import PurchaseResult from '../views/purchase/PurchaseResult';
import ProductDetail from '../views/product/ProductDetail';
import KitDetail from '../views/kit/KitDetail';
import Viewer from '../views/course/Viewer';
import AccountInfoEdit from '../views/account/AccountInfoEdit';
import PasswordChange from '../views/account/PasswordChange';
import EmailIdFind from '../views/account/EmailIdFind';
import PasswordFind from '../views/account/PasswordFind';
import DropOut from '../views/account/DropOut';
import Curation from '../views/_common/Curation';
import Survey from '../views/service/Survey';
import TimeEvent from '../views/event/TimeEvent';
import GivingCoupon from '../views/event/GivingCoupon';
import SignUpComplete from '../views/account/SignUpComplete';
import ForceSignIn from '../views/account/ForceSignIn';
/*import SuperZ2 from "../views/superz/SuperZ2";*/
import MoreInfo from '../views/service/MoreInfo';
import MyPage2 from '../views/account/MyPage2';
import HardStudyX from '../views/hardstudy/HardStudyX';
import Creditbank from '../views/creditbank/Creditbank';
import DataDeleteTerms from '../views/service/DataDeleteTerms';
import TimeEventForHardStudy from '../views/event/TimeEventForHardStudy';
import PlatformPolicy from '../views/service/PlatformPolicy';
import TimeEventForTm from '../views/event/TimeEventForTm';
import Ebook from '../views/ebook/Ebook';
import EbookDetail from '../views/ebook/EbookDetail';
import Bgd from '../views/bgd/Bgd';
import BgdForm from '../views/bgd/BgdForm';
import BgdClassList from '../views/bgd/BgdClassList';
import HardStudy2 from '../views/hardstudy/HardStudy2';

export const routes = [
  { path: '/', Component: Permission(Main, 'all') },
  { path: '/info', Component: Permission(Main, 'all') }, // info url 에서도 표시하기로함

  { path: '/search', Component: Permission(Search, 'all') },

  { path: '/user/signin', Component: Permission(SignIn, 'logout') },
  { path: '/user/signin/:social', Component: Permission(SignIn, 'logout') },
  { path: '/user/signup', Component: Permission(SignUp, 'logout') },
  { path: '/user/signup/complete', Component: Permission(SignUpComplete, 'login') },
  { path: '/user/mypage', Component: Permission(MyPage2, 'login') },
  { path: '/user/mypage/:category', Component: Permission(MyPage2, 'login') },
  { path: '/user/info', Component: Permission(AccountInfoEdit, 'login') },
  { path: '/user/password/change', Component: Permission(PasswordChange, 'login') },
  { path: '/user/email/find', Component: Permission(EmailIdFind, 'all') },
  { path: '/user/password/find', Component: Permission(PasswordFind, 'all') },
  { path: '/user/dropout', Component: Permission(DropOut, 'login') },
  { path: '/user/force-signin', Component: Permission(ForceSignIn, 'all') },

  { path: '/service/notice', Component: Permission(Notice, 'all') },
  { path: '/service/notice/:notice_id', Component: Permission(NoticeDetail, 'all') },
  { path: '/service/event', Component: Permission(Event, 'all') },
  { path: '/service/faq', Component: Permission(Faq, 'all') },
  { path: '/service/privacy', Component: Permission(Privacy, 'all') },
  { path: ['/service/terms', '/service/terms/:term_id'], Component: Permission(Terms, 'all') },
  { path: '/service/platform-policy', Component: Permission(PlatformPolicy, 'all') },
  { path: '/service/data_deletion_terms', Component: Permission(DataDeleteTerms, 'all') },
  { path: '/service/master', Component: Permission(Master, 'all') },
  { path: '/service/info/viewer', Component: Permission(InfoViewer, 'all') },
  { path: '/service/info/lab', Component: Permission(BearuLab, 'all') },
  { path: '/service/more_info', Component: Permission(MoreInfo, 'all') },
  { path: '/service/survey/:survey_id', Component: Permission(Survey, 'all') },
  {
    path: '/service/survey/hardstudy/:survey_id',
    Component: Permission(Survey, 'all', { event_type: 'complete_enroll_hardstudy' }),
  },

  { path: '/course/:id', Component: Permission(CourseDetail, 'all') },
  { path: '/package/:id', Component: Permission(ProductDetail, 'all') },

  { path: '/ebook', Component: Permission(Ebook, 'all') },
  { path: '/ebook/:id', Component: Permission(EbookDetail, 'all') },

  { path: '/kit/:id', Component: Permission(KitDetail, 'all') },

  { path: '/curation/:id', Component: Permission(Curation, 'all') },

  { path: '/event/time/:id', Component: Permission(TimeEvent, 'all') },
  { path: '/event/hardstudy_time/:id', Component: Permission(TimeEventForHardStudy, 'all') },
  { path: '/event/tm_time/:id', Component: Permission(TimeEventForTm, 'all') },
  { path: '/event/coupon/giving', Component: Permission(GivingCoupon, 'all') },

  { path: '/overview/:id', Component: Permission(Overview, 'all') },

  { path: '/course/:course_id/viewer', Component: Permission(Viewer, 'login') },

  { path: '/purchase', Component: Permission(Purchase, 'login') },
  { path: '/purchase/result', Component: Permission(PurchaseResult, 'login') },

  /*{path: "/payback", Component: Permission(SuperZ2, "all")},*/

  { path: '/hardstudyX', Component: Permission(HardStudyX, 'all') },

  { path: '/creditbank', Component: Permission(Creditbank, 'all') },

  { path: '/hardstudy', Component: Permission(HardStudy2, 'all') },
  { path: '/bgd', Component: Permission(Bgd, 'all') },
  { path: '/bgd/recruit', Component: Permission(BgdForm, 'login') },
  { path: '/bgd/class-list/:eventCode', Component: Permission(BgdClassList, 'all') },
];
