import React, { useEffect, useRef, useState } from 'react';
import CourseModel from '../../../model/CourseModel';
import '../../../assets/scss/component/_qna_container.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingSpinner from '../component/LoadingSpinner';
import { useMediaQuery } from 'react-responsive';
import { ableSelectAll, ProfileImage, resetSizeTextarea } from './QnaContainer';
import Review from '../component/Review';
import { moveRefToTop } from '../../course/CourseDetail';
import { useDialogContext } from '../dialog/DialogContextProvider';
import EbookModel from '../../../model/EbookModel';

const propTypes = {
  type: PropTypes.oneOf(['ebook', 'course']),
  ebook_id: PropTypes.string,
  course_id: PropTypes.string,
  subject_index: PropTypes.string,
  subject_id: PropTypes.string,
  page_size: PropTypes.number,
  default_textarea_height: PropTypes.number,
  is_ebook_author: PropTypes.bool,
  onLoadReview: PropTypes.func,
};

const ReviewContainer = (props) => {
  const [review_list, setReviewList] = useState(null);
  const [visible_more_button, setVisibleMoreButton] = useState(true);
  const [is_request_loading, setIsRequestLoading] = useState(false);
  const [is_write, setIsWrite] = useState(false);
  const [hover_rating, setHoverRating] = useState(5);
  const [avg_rating, setAvgRating] = useState(0);
  const [page, setPage] = useState(1);

  //Dom ref
  const new_review_textarea_ref = useRef(null);
  const review_wrap_ref = useRef(null);

  //ref
  const comment = useRef('');
  const total_count_ref = useRef(null);
  const my_star_rating = useRef(5);
  const is_posting_review = useRef(false);

  const AccountStore = useSelector((state) => state.AccountStore);
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { alert } = useDialogContext();

  const disabledWrite =
    AccountStore === null ||
    (!props.is_mine &&
      !props.is_ebook_author &&
      !(props.type === 'ebook' && AccountStore?.account_type_id === 5));

  let input_placeholder = props.input_placeholder || '내용을 입력하세요.';
  let review_size = props.review_size || 10;
  let default_textarea_height = props.default_textarea_height || 0;

  useEffect(async () => {
    await requestNextPageReviewList(true);
  }, [props.course_id, props.ebook_id]);

  useEffect(async () => {
    resetSizeTextarea(new_review_textarea_ref, default_textarea_height);
  }, [is_tablet_or_mobile]);

  const requestNextPageReviewList = async (is_reset = false) => {
    setPage(page + 1);

    let code;
    let total_count;
    let avg_rating;
    let review_set;
    let is_write;

    if (props.type === 'course') {
      const result = await CourseModel.courseReviewList({
        course_id: props.course_id,
        page: page,
        page_size: review_size,
      });
      code = result.code;
      total_count = result.total_count;
      avg_rating = result.avg_rating;
      review_set = result.review_set;
      is_write = result.is_write;
    }

    if (props.type === 'ebook') {
      const lastReview = review_list?.[review_list?.length - 1];
      const result = await EbookModel.ebookReviewList({
        ebook_id: props.ebook_id,
        last_visible_data: lastReview?.create_time,
        size: review_size,
      });
      code = result.code;
      total_count = result.data.total_count;
      avg_rating = result.data.avg_rating || 0;
      review_set = result.data.review_set;
      is_write = result.data.is_write;
      props.onLoadReview(result.data);
    }

    if (code === 200) {
      const new_review_list = is_reset ? [] : review_list;
      const merged_review_list = [...new_review_list, ...review_set];
      total_count_ref.current = total_count;

      setAvgRating(avg_rating);
      setIsWrite(is_write);
      setVisibleMoreButton(total_count > merged_review_list.length);
      setReviewList(merged_review_list);
      setIsRequestLoading(false);
    } else {
      alert('댓글을 불러오지 못했습니다.ㅠ');
    }
  };

  const writeReview = async () => {
    let code;
    let data;

    if (props.type === 'course') {
      const result = await CourseModel.courseReviewWrite({
        course_id: props.course_id,
        content: comment.current.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, '\n'),
        rating: my_star_rating.current || 5,
      });
      code = result.code;
      data = result.data;
    }
    if (props.type === 'ebook') {
      const result = await EbookModel.ebookReviewWrite({
        ebook_id: props.ebook_id,
        content: comment.current.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, '\n'),
        rating: my_star_rating.current || 5,
      });
      code = result.code;
      data = result.data;
    }

    if (code === 200) {
      new_review_textarea_ref.current.value = '';
      my_star_rating.current = 5;
      total_count_ref.current = data.total_count;
      is_posting_review.current = false;

      setAvgRating(data.avg_rating);
      setReviewList([{ ...data.review }, ...review_list]);
      setIsWrite(true);
      props.onLoadReview((prev) => ({
        ...prev,
        total_count: data.total_count,
        avg_rating: data.avg_rating,
      }));
    } else {
      alert('댓글 등록에 실패했습니다 ㅠㅠ');
    }
  };

  const deleteReview = async (id) => {
    let code;
    let data;

    if (props.type === 'course') {
      const result = await CourseModel.courseReviewDelete({ review_id: id });
      code = result.code;
      data = result.data;
    }
    if (props.type === 'ebook') {
      const result = await EbookModel.ebookReviewDelete({ review_id: id });
      code = result.code;
      data = result.data;
    }

    if (code === 200) {
      let tmp = [...review_list];

      if (data.avg_rating !== null) {
        setAvgRating(data.avg_rating);
      }

      my_star_rating.current = 5;
      total_count_ref.current = data.total_count;

      setHoverRating(5);
      setIsWrite(false);
      setReviewList(tmp.filter((a) => (a.id || a.review_id) !== id));

      setTimeout(() => {
        resetSizeTextarea(new_review_textarea_ref, default_textarea_height);
      }, 50);

      props.onLoadReview((prev) => ({
        ...prev,
        total_count: data.total_count,
        avg_rating: data.avg_rating,
      }));
    } else {
      alert('댓글 삭제 실패(네트워크 오류)');
    }
  };

  const createReviewList = (review) => {
    let user_nickname = review.account_nickname;

    if (review.account_type_id === 5) {
      user_nickname = '관리자';
    } else if (!review.account_nickname) {
      user_nickname = '익명의 수강생';
    }

    return (
      <Review
        type={props.type}
        review={review}
        key={review.review_id}
        user_nickname={user_nickname}
        deleteReview={deleteReview}
        setAvgRating={setAvgRating}
        is_ebook_author={props.is_ebook_author}
        onLoadReview={props.onLoadReview}
        visible_edit_buttons={
          AccountStore !== null &&
          review.reply_status_id < 3 &&
          (AccountStore?.id === review.account_id || AccountStore?.account_type_id === 5)
        }
      />
    );
  };

  const createSubmitButton = () => {
    return (
      <button
        className='submit-save-btn'
        disabled={disabledWrite}
        onClick={async () => {
          if (!comment.current) {
            alert('후기를 작성해주세요');
          } else if (!is_posting_review.current) {
            is_posting_review.current = true;
            await writeReview();
          }
        }}
      >
        등록
      </button>
    );
  };

  return (
    <div className='reply-wrap' ref={review_wrap_ref}>
      {props.visible_top_row ? (
        <div className='top-row'>
          <p>수강후기</p>
          {total_count_ref.current !== null && total_count_ref.current > 0 ? (
            <div className='rating-info-wrap'>
              <img
                alt='별점 아이콘'
                src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/product-star-active.svg`}
              />
              <p>{avg_rating.toFixed(1)}점</p>
              <h5>({total_count_ref.current})</h5>
            </div>
          ) : null}
        </div>
      ) : null}

      {/*댓글 입력 영역 start*/}
      {!is_write ? (
        // 이미 수강후기 작성했을 시, textarea 영역 보이지 않음
        <div className='content textarea-wrap'>
          <div className='writing-area'>
            {!is_tablet_or_mobile && AccountStore !== null ? (
              <div className='pc-profile'>
                <ProfileImage
                  img_src={AccountStore?.info?.picture_thumbnail_image_url}
                  auth_check={AccountStore}
                />
              </div>
            ) : null}

            <div className='main-column-wrap'>
              {AccountStore !== null ? (
                <div className='profile-info-row write-reply'>
                  <ProfileImage
                    img_src={AccountStore?.info?.picture_thumbnail_image_url}
                    auth_check={AccountStore}
                  />
                  <h4>
                    {AccountStore?.account_type_id === 5
                      ? '관리자'
                      : AccountStore?.info?.nickname || '익명의 수강생'}
                  </h4>
                  {createStarRating(
                    my_star_rating,
                    props.is_mine ? hover_rating : 0,
                    setHoverRating,
                  )}
                </div>
              ) : null}

              <span>
                <textarea
                  placeholder={
                    AccountStore === null ? '로그인 후 작성 가능합니다.' : input_placeholder
                  }
                  maxLength={300}
                  disabled={disabledWrite}
                  onKeyDown={(e) => ableSelectAll(e)}
                  ref={!is_write ? new_review_textarea_ref : null}
                  onFocus={() => {
                    if (is_tablet_or_mobile) {
                      moveRefToTop(new_review_textarea_ref);
                    }
                  }}
                  onChange={(e) => {
                    comment.current = e.target.value;
                    resetSizeTextarea(new_review_textarea_ref, default_textarea_height);
                    props.resetSwiperHeight && props.resetSwiperHeight();
                  }}
                />

                {is_tablet_or_mobile ? <>{createSubmitButton()}</> : null}
              </span>
            </div>
          </div>

          {is_tablet_or_mobile ? null : <>{createSubmitButton()}</>}
        </div>
      ) : null}
      {/*댓글 입력 영역 end*/}

      {/*댓글 표시 영역 start*/}
      {review_list === null ? (
        <LoadingSpinner />
      ) : (
        <>
          {review_list.length === 0 ? (
            <div className='empty-list'>
              <p>
                {props.type === 'ebook'
                  ? '아직 등록된 전자책 후기가 없어요'
                  : '아직 등록된 수강후기가 없어요'}
              </p>
            </div>
          ) : (
            <>
              {review_list.map((review) => {
                return createReviewList(review);
              })}
              {visible_more_button ? (
                <div className='more-button-wrap'>
                  {is_request_loading ? (
                    <LoadingSpinner />
                  ) : (
                    <button
                      onClick={async () => {
                        if (!is_request_loading) {
                          setIsRequestLoading(true);
                          await requestNextPageReviewList();
                        }
                      }}
                    >
                      댓글 더보기
                    </button>
                  )}
                </div>
              ) : null}
            </>
          )}
        </>
      )}
      {/*댓글 표시 영역 end*/}
    </div>
  );
};
ReviewContainer.propTypes = propTypes;

export default ReviewContainer;

export const createStarRating = (
  my_star_rating,
  hover_rating,
  setHoverRating,
  is_editable = true,
) => {
  return (
    <ul className='star-rating-wrap'>
      {Array.from({ length: 5 }).map((el, index) => {
        return (
          <img
            key={index}
            className={is_editable ? 'editable' : ''}
            alt='별점 아이콘'
            onMouseEnter={() => {
              setHoverRating(index + 1);
            }}
            onMouseOut={() => {
              setHoverRating(my_star_rating.current);
            }}
            onClick={() => {
              if (is_editable) {
                my_star_rating.current = index + 1;
              }
            }}
            src={`${process.env.REACT_APP_IMG_URL}/static/svg/detail/product-star-${
              index + 1 <= hover_rating ? 'active' : 'default'
            }.svg`}
          />
        );
      })}
    </ul>
  );
};
