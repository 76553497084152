import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactPixel from 'react-facebook-pixel';

import '../../assets/scss/page/bgd_form.scss';
import { set_header_type } from '../../redux/common/action';
import RecruitFormModel from '../../model/RecruitFormModel';
import InputLabel from '../_common/Input/InputLabel';
import TemplateInput from '../_common/_template/TemplateInput';
import { alert_and_redirct, is_today_active_range } from '../../common';
import { formValidationSchema, defaultValues } from '../../validations/formValidationSchema';

import LoadingSpinner from '../_common/component/LoadingSpinner';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';

const BgdForm = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serviceType = searchParams.get('type');
  const AccountStore = useSelector((state) => state.AccountStore);
  const CommonStore = useSelector((state) => state.CommonStore);
  const is_top_banner_visible = CommonStore.tmp?.is_top_banner_visible ?? false;
  const [recruitFormData, setRecruitFormData] = useState({});
  const [recruitAbleClasses, setRecruitAbleClasses] = useState([]);
  const [eventCode, setEventCode] = useState('');
  const [answers, setAnswers] = useState([]);
  const [isActiveRecruit, setIsActiveRecruit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { alert } = useDialogContext();

  const currentDate = new Date();
  const compareDate = new Date('2024-06-30T23:59:59');
  const isNotActiveBgdXForm = currentDate < compareDate;

  useEffect(() => {
    if (isNotActiveBgdXForm && serviceType === 'bgdX') {
      props.history.push('/hardstudy');
      window.open('https://tally.so/r/w2kXx9', '_blank', 'noopener, noreferrer');
    }
  }, [isNotActiveBgdXForm, serviceType]);

  useEffect(() => {
    if (!isNotActiveBgdXForm && !isActiveRecruit) {
      alert_and_redirct(
        alert,
        props.history,
        '모집이 준비중입니다.\n다음 기수를 준비하고 있으니 기대해주세요!',
        serviceType === 'bgd' ? '/bgd' : '/bgd?type=bgdX',
      );
    }
  }, [isActiveRecruit]);

  useEffect(async () => {
    dispatch(
      set_header_type(0, 1, { no_footer: true, no_top_btn: true, no_channel_talk_button: true }),
    );
    ReactPixel.trackCustom('빡공단 모집 폼 등록 페이지 조회');
  }, []);

  useEffect(() => {
    const fetchRecruitData = async () => {
      setIsLoading(true);
      try {
        const getRecruitData =
          serviceType === 'bgd'
            ? await RecruitFormModel.getCurrentRecruitingBgd()
            : await RecruitFormModel.getCurrentRecruitingBgdX();
        setRecruitFormData(getRecruitData);
        setEventCode(getRecruitData?.event_code);
        setIsActiveRecruit(
          is_today_active_range(getRecruitData?.apply_start_time, getRecruitData?.apply_end_time),
        );
      } catch (error) {
        console.error('Error fetching recruit data:', error);
      }
      setIsLoading(false);
    };
    fetchRecruitData();
  }, [dispatch, serviceType]);

  useEffect(() => {
    if (serviceType === 'bgdX' && eventCode) {
      const fetchRecruitClassData = async () => {
        try {
          const getRecruitAbleClasses = await RecruitFormModel.getClasses({ eventCode });
          setRecruitAbleClasses(getRecruitAbleClasses);
        } catch (error) {
          console.error('Error fetching recruit able classes:', error);
        }
      };
      fetchRecruitClassData();
    }
  }, [eventCode, serviceType]);

  const getDefaultValues = recruitFormData.questions
    ? defaultValues(recruitFormData.questions, AccountStore, '')
    : {};
  const validationSchema = recruitFormData.questions
    ? formValidationSchema(recruitFormData.questions)
    : Yup.object().shape({});

  const {
    trigger,
    watch,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getDefaultValues,
  });

  useEffect(() => {
    if (recruitFormData.questions) {
      reset(
        defaultValues(
          recruitFormData.questions,
          AccountStore,
          recruitFormData?.bgd_recruit_form_id,
        ),
      );
    }
  }, [recruitFormData, reset, AccountStore]);

  const updateAnswersFromWatch = async (answeredQuestionId, answeredQuestionValue) => {
    const copyWatch = watch();
    const answersArray = copyWatch.answers;

    answersArray?.forEach((key, idx) => {
      const questionId = key.question_id;
      if (questionId === answeredQuestionId.toString()) {
        answersArray[idx].value = answeredQuestionValue.toString();
      }
    });

    const selectedData = {
      bgd_recruit_form_id: copyWatch.bgd_recruit_form_id,
      account_id: copyWatch.account_id,
      phone: copyWatch.phone,
      email: copyWatch.email,
      answers: copyWatch.answers,
    };

    validation(selectedData);
  };

  useEffect(() => {
    if (Object.entries(errors).length > 0) {
      trigger();
    }
  }, [answers]);

  const handleOnChange = async (id, type, value) => {
    setAnswers((prevAnswers) => {
      const filteredAnswers = prevAnswers.filter((answer) => answer.question_id !== id);
      const newAnswer = {
        question_id: id,
        type: type,
        value: value.toString(),
      };

      updateAnswersFromWatch(id, value);

      return [...filteredAnswers, newAnswer];
    });
  };

  const SelectQuestion = ({ question, name }) => {
    const options =
      question.title === '참여코스선택' && recruitAbleClasses
        ? recruitAbleClasses.map((option) => ({
            value: option.id,
            label: `[${option.short_title}] ${option.title}`,
          }))
        : question.options.map((option) => ({
            value: option.bgd_option_id,
            label: option.text,
          }));

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=''
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            className={'w-full'}
            onChange={(selectedOption) => {
              field.onChange(selectedOption?.value || '');
              handleOnChange(
                question.question_id,
                question.question_type,
                selectedOption?.value || '',
              );
            }}
            value={options.find((option) => option.value === field.value)}
          />
        )}
      />
    );
  };

  const validation = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return true;
    } catch (validationErrors) {
      console.error(validationErrors.errors);
      return false;
    }
  };

  const onSubmit = async () => {
    const selectedData = {
      account_id: watch().account_id,
      phone: watch().phone,
      email: watch().email,
      answers: watch().answers,
    };

    if (await validation(selectedData)) {
      try {
        const data = await RecruitFormModel.postRecruitingBgd(
          watch().bgd_recruit_form_id,
          watch().account_id,
          watch().phone,
          watch().email,
          watch().answers,
        );

        if (data.billing_status === 'ready') {
          await alert('정상적으로 등록되었습니다.');
          ReactPixel.trackCustom('빡공단 모집 폼 등록 완료', { accountId: data.account_id });
          props.history.push('/bgd');
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Validation failed');
    }
  };

  return (
    <div className='bgd-container'>
      <div className='content-container'>
        <div className={`recruit-page-wrap ${is_top_banner_visible ? '' : ' hide'}`}>
          <Helmet>
            <title>빡공단 지원하기 : 자기계발 챌린지</title>
          </Helmet>
          {isActiveRecruit && isLoading ? (
            <LoadingSpinner />
          ) : (
            isActiveRecruit && (
              <>
                <div className='content-top-img'>
                  <img
                    src={recruitFormData.thumbnail_img}
                    alt='자기계발 챌린지 빡공단 하루 10분, 30일 동안 습관과 스펙을 만드세요.'
                  />
                </div>
                <section className='recruit-content-wrap'>
                  <div className='recruit-info-text'>
                    <h4>
                      {serviceType === 'bgd' ? '빡공단 신청하기' : '[러닝크루] 환급챌린지 신청'}
                    </h4>
                    {serviceType === 'bgd' && (
                      <p className='recruit-description'>
                        빡공단은 참여하는 모든 분들이 서로 동기부여를 나누고 학습 커뮤니티의 올바른
                        방향을 위하여 <b className='underline'>진짜 성장을 희망하는 분</b>들만
                        선발하여 모시고 있습니다. 아래 신청서를 통해 신청하실 수 있어요!
                        <br />
                        <br />
                        결과는 아래 일정에 맞게 안내드릴 예정이며, 선발된 인원들은 추후 개발
                        안내되는 링크를 통해서 결제(참가비 59,000원)와 함께 수강신청을 해주시면
                        됩니다. 이름 및 연락처 등 오타가 없도록 꼼꼼히 작성하고
                        <span className='text-purple'> [신청하기]</span> 를 눌러주세요. 선발 결과는
                        카카오 알림톡으로 안내됩니다.
                      </p>
                    )}
                  </div>

                  <article className='recruit-content'>
                    {isActiveRecruit && Object.keys(recruitFormData).length !== 0 && (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <ul className='recruit-question-list'>
                          <li className='hidden'>
                            <InputLabel label='account_id' />
                            <Controller
                              name='account_id'
                              control={control}
                              defaultValue={AccountStore?.id ?? ''}
                              render={({ field }) => (
                                <input type='hidden' id='account_id' {...field} />
                              )}
                            />
                          </li>
                          <li className='hidden'>
                            <InputLabel label='phone' />
                            <Controller
                              name='phone'
                              control={control}
                              defaultValue={AccountStore?.info?.phone ?? ''}
                              render={({ field }) => <input type='hidden' id='phone' {...field} />}
                            />
                          </li>
                          <li className='hidden'>
                            <InputLabel label='email' />
                            <Controller
                              name='email'
                              control={control}
                              defaultValue={AccountStore?.info?.email ?? ''}
                              render={({ field }) => <input type='hidden' id='email' {...field} />}
                            />
                          </li>
                          <li className='hidden'>
                            <InputLabel label='bgd_recruit_form_id' />
                            <Controller
                              name='bgd_recruit_form_id'
                              control={control}
                              defaultValue={recruitFormData?.bgd_recruit_form_id ?? ''}
                              render={({ field }) => (
                                <input type='hidden' id='bgd_recruit_form_id' {...field} />
                              )}
                            />
                          </li>
                          {recruitFormData?.questions?.map((question, idx) => {
                            const questionName = `${question.question_type}${question.question_id}`;
                            return (
                              <li
                                key={`${question.question_type}${question.question_id}${idx}`}
                                className='item'
                              >
                                {question.question_type === 'text' && (
                                  <>
                                    <InputLabel label={question.title} htmlFor={questionName} />
                                    <Controller
                                      name={questionName}
                                      control={control}
                                      defaultValue={question.value ?? ''}
                                      render={({ field }) => (
                                        <TemplateInput
                                          input_props={{
                                            type: question.question_type,
                                            id: questionName,
                                            ...field,
                                            onChange: (e) => {
                                              handleOnChange(
                                                question.question_id,
                                                question.question_type,
                                                e,
                                              );
                                              field.onChange(e);
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </>
                                )}
                                {['radio', 'checkbox'].includes(question.question_type) && (
                                  <>
                                    <InputLabel label={question.title} />
                                    {question.options.map((option, idx) => (
                                      <div
                                        className='row-reverse'
                                        key={`${question.question_type}${question.question_id}${idx}`}
                                      >
                                        <InputLabel
                                          label={option.text}
                                          htmlFor={`${question.question_type}${question.question_id}${idx}`}
                                        />
                                        <Controller
                                          name={questionName}
                                          control={control}
                                          defaultValue=''
                                          render={({ field }) => (
                                            <TemplateInput
                                              input_props={{
                                                type: question.question_type,
                                                id: `${question.question_type}${question.question_id}${idx}`,
                                                ...field,
                                                value: option.bgd_option_id,
                                                question_id: question.question_id,
                                                onChange: (e) => {
                                                  if (question.question_type === 'checkbox') {
                                                    const inputId = `${question.question_type}${question.question_id}${idx}`;
                                                    const inputElement =
                                                      document.getElementById(inputId);
                                                    if (inputElement) {
                                                      const isChecked = inputElement.checked;
                                                      const value = option.bgd_option_id;
                                                      const currentValue = Array.isArray(
                                                        field.value,
                                                      )
                                                        ? field.value
                                                        : [];
                                                      const newValue = isChecked
                                                        ? [...currentValue, value]
                                                        : currentValue.filter((v) => v !== value);
                                                      handleOnChange(
                                                        question.question_id,
                                                        question.question_type,
                                                        newValue.join('||'),
                                                      );
                                                      field.onChange(newValue);
                                                    }
                                                  } else {
                                                    handleOnChange(
                                                      question.question_id,
                                                      question.question_type,
                                                      e,
                                                    );
                                                    field.onChange(e);
                                                  }
                                                },
                                              }}
                                            />
                                          )}
                                        />
                                      </div>
                                    ))}
                                  </>
                                )}
                                {question.question_type === 'select' && (
                                  <>
                                    <InputLabel label={question.title} htmlFor={questionName} />
                                    <SelectQuestion question={question} name={questionName} />
                                  </>
                                )}
                                {question.question_type === 'textarea' && (
                                  <>
                                    <InputLabel label={question.title} htmlFor={questionName} />
                                    <Controller
                                      name={questionName}
                                      control={control}
                                      defaultValue=''
                                      render={({ field }) => (
                                        <textarea
                                          id={questionName}
                                          rows={13}
                                          onChange={(e) => {
                                            handleOnChange(
                                              question.question_id,
                                              question.question_type,
                                              e.target.value,
                                            );
                                            field.onChange(e.target.value);
                                          }}
                                        />
                                      )}
                                    />
                                  </>
                                )}
                                {Object.entries(errors).length > 0 && errors?.answers[idx] && (
                                  <p className='error-massage'>
                                    {errors?.answers[idx].value.message}
                                  </p>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                        <div className='btn-area'>
                          <button className='apply-btn' type='submit'>
                            빡공단 신청하기
                          </button>
                        </div>
                      </form>
                    )}
                  </article>
                </section>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BgdForm;
