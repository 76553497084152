import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  subject_index: PropTypes.string,
  getPlayedTime: PropTypes.func,
  getMaxDuration: PropTypes.func,
  next_focus_ref: PropTypes.object,
  question_time: PropTypes.number,
  setQuestionTime: PropTypes.func,
};

const QnaTimeInputs = (props) => {
  const [question_time, setQuestionTime] = useState(props.question_time);

  const question_minute_ref = useRef(null);
  const question_second_ref = useRef(null);

  useEffect(() => {
    setQuestionMinute(props.question_time);
  }, [props.question_time]);

  useEffect(() => {
    saveQuestionTime(0);
  }, [props.subject_index]);

  const setQuestionMinute = (QuestionSecond) => {
    question_minute_ref.current.value = `0${Math.floor(QuestionSecond / 60)}`.slice(-2);
    question_second_ref.current.value = `0${QuestionSecond % 60}`.slice(-2);

    saveQuestionTime(QuestionSecond);
  };

  const convertQuestionMinuteToSecond = () => {
    let tmp_question_time =
      Number(question_minute_ref.current?.value) * 60 + Number(question_second_ref.current?.value);

    let tmp_duration = props.getMaxDuration();

    if (tmp_duration !== null && tmp_question_time > tmp_duration) {
      tmp_question_time = tmp_duration;
    }

    saveQuestionTime(Math.ceil(tmp_question_time));
  };

  const saveQuestionTime = (time) => {
    if (props.setQuestionTime) {
      props.setQuestionTime(time);
    }
    setQuestionTime(time);
  };

  const timeInput = (ref) => {
    let next_focus_ref = null;

    if (ref === question_minute_ref) {
      next_focus_ref = question_second_ref;
    } else {
      next_focus_ref = props.next_focus_ref;
    }

    return (
      <input
        className={question_time > 0 ? '' : 'no-input'}
        type='number'
        ref={ref}
        autoComplete='off'
        defaultValue='00'
        disabled={question_time > 0}
        onFocus={(e) => {
          e.target.value = '';
        }}
        onInput={(e) => {
          //붙여넣기 등 방지
          if (e.target.value.length >= 2) {
            e.target.value = e.target.value.slice(0, 2);
            next_focus_ref.current.focus();
          }
        }}
        onBlur={(e) => {
          if (e.target.value === '') {
            //아무 것도 입력 안 했을 시 00으로 세팅
            e.target.value = '00';
          } else if (e.target.value.length === 1) {
            // 한 자리 숫자만 입력하고 블러하면 다음 ref로 강제 이동
            // (question_minute_ref에 숫자 하나만 입력하고 댓글 입력하려고 하면 에러 발생 때문)
            e.target.value = `0${e.target.value}`;
            next_focus_ref.current.focus();
          } else if (Number(e.target.value) > 59) {
            // 59이상 입력 시 59로 고정
            // (추후 영상 길이 넘는 시간 입력했을 시 영상 최대 시간으로 바뀌게 할 예정)
            e.target.value = '59';
          }

          ref.current.value = e.target.value;

          if (ref === question_second_ref) {
            convertQuestionMinuteToSecond();
          }
        }}
      />
    );
  };

  return (
    <span className='question-time-wrap'>
      <img
        onClick={() => {
          setQuestionMinute(Math.floor(props.getPlayedTime()));
          props.next_focus_ref?.current.focus();
        }}
        src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_timequestion.png`}
        alt='질문시간 아이콘'
      />

      <div className={`question-time-info ${question_time > 0 ? 'completed' : ''}`}>
        <h5>{props.subject_index}</h5>
        {timeInput(question_minute_ref)}:{timeInput(question_second_ref)}
        {question_time > 0 ? (
          <button
            onClick={() => {
              setQuestionMinute(0);
            }}
          >
            <img
              className='close-btn-img'
              src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/common/ic_x_lg_18px.png`}
              alt='닫기 버튼'
            />
          </button>
        ) : null}
      </div>
    </span>
  );
};
QnaTimeInputs.propTypes = propTypes;

export default QnaTimeInputs;
