import axios from 'axios';

class PurchaseModel {
  async purchase({
    purchase_type,
    paymethod_id,
    currency_code,
    course_sale_info_id,
    ebook_sale_info_id,
    coupon_ids,
    course_id,
    package_id,
    ebook_id,
    extend_month,
    kit_set,
    event_code,
    ticket_code = null,
    shipping_msg,
  }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/purchase`, {
      purchase_type,
      paymethod_id,
      currency_code,
      course_sale_info_id,
      ebook_sale_info_id,
      coupon_ids,
      course_id,
      package_id,
      ebook_id,
      extend_month,
      kit_set,
      event_code,
      ticket_code,
      shipping_msg,
    });
    let product_name = code === 200 ? data.product_name : null;
    let amount = code === 200 ? data.amount : null;
    let merchant_uid = code === 200 ? data.merchant_uid : null;
    let purchase_status = code === 200 ? data.purchase_status : 0;
    let id = code === 200 ? data.id : null;

    return {
      code,
      product_name,
      amount,
      merchant_uid,
      purchase_status,
      id,
    };
  }

  async purchaseCancel({ merchant_uid, imp_uid }) {
    const {
      data: { code },
    } = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/purchase`, {
      merchant_uid,
      imp_uid,
    });
    return {
      code,
    };
  }

  async purchaseValidate({ merchant_uid, imp_uid = null }) {
    const {
      data: { code, data },
    } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/purchase/validate`, {
      merchant_uid,
      imp_uid,
    });
    let purchase_info = code === 200 ? data.purchase_info : null;
    let msg = code === 200 ? data.msg : null;
    let vbank_info = code === 200 ? data.vbank_info : null;
    let is_user_confirm = code === 200 ? data.is_user_confirm : null;
    let coupon_code = code === 200 ? data.coupon_code : null;
    let event_title = code === 200 ? data.event_title : null;
    let import_status = code === 200 ? data.import_status : null;
    return {
      code,
      purchase_info,
      vbank_info,
      msg,
      is_user_confirm,
      coupon_code,
      event_title,
      import_status,
    };
  }

  async priceValidate({
    currency_code = 'KRW',
    course_sale_info_id = null,
    ebook_sale_info_id = null,
    coupon_ids = [],
    extend_month = null,
    package_id = null,
    kit_set = [],
    event_code = null,
  }) {
    try {
      const {
        data: { code, data },
      } = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/purchase/price/validate`, {
        currency_code,
        course_sale_info_id,
        ebook_sale_info_id,
        coupon_ids,
        package_id,
        extend_month,
        kit_set,
        event_code,
      });

      let discount_set = code === 200 ? data.discount_set : null;
      let total_price = code === 200 ? data.total_price : null;
      let purchase_price = code === 200 ? data.purchase_price : null;
      return {
        code,
        total_price,
        purchase_price,
        discount_set,
      };
    } catch (error) {
      console.log('priceValidate error::', error);
    }
  }

  /* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */
  /*async getProductSeatCount({type, type_id}) {
        const {code, data} = await axios.get(process.env.REACT_APP_API_URL + `/api/v1/course/counter/${type}/${type_id}`);
        return data;
    }
    async postProductSeatCount({type, type_id}) {
        const {code, data} = await axios.post(process.env.REACT_APP_API_URL + `/api/v1/course/counter/${type}/${type_id}/count`, {
            type: type,
            type_id: type_id,
        });
        return data;
    }*/
}

export default new PurchaseModel();
