import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const propTypes = {
  coupon: PropTypes.object,
};

const CouponInfoModal = (props) => {
  //Dialog Context
  const dialog_utils = props.dialog_utils || {};
  const dialog_props = props.dialog_props || {};

  const onCloseModalBtn = () => {
    dialog_utils.hideDialog && dialog_utils.hideDialog(dialog_props.id);
  };

  return (
    <div className='td-modal'>
      <div className='td-dimmed' onClick={onCloseModalBtn} />
      <div className='modal-content'>
        <div className='modal-section simple-section'>
          <h3>쿠폰 안내</h3>
          <div
            className='modal-close-btn'
            style={{
              background: `url(${process.env.REACT_APP_IMG_URL}/static/png/common/icon-close-gray.png) no-repeat center center/cover`,
            }}
            onClick={onCloseModalBtn}
          />
          <div className='modal-section-wrap'>
            <h4>쿠폰 적용 대상 상품</h4>
            <div className='modal-section-list'>
              {props.coupon?.allow_set.length ? (
                <div className='coupon-class-type course-type'>
                  {props.coupon?.allow_set
                    .filter((allow) => allow.product_type_id <= 4)
                    .map((allow, index) => {
                      return (
                        <p key={index} className='coupon-apply-target-title'>
                          - 전체 {allow.product_type_name}
                        </p>
                      );
                    })}
                </div>
              ) : null}
              {props.coupon?.coupon_course_set.length > 0 ? (
                <div className='coupon-class-type course-type'>
                  <h5>단과 강의</h5>
                  {props.coupon?.coupon_course_set.map((course) => {
                    return (
                      <p className='coupon-apply-target-title' key={course.course_id}>
                        - {course.course_short_title}
                      </p>
                    );
                  })}
                </div>
              ) : null}
              {props.coupon?.coupon_ebook_set.length > 0 ? (
                <div className='coupon-class-type course-type'>
                  <h5>전자책</h5>
                  {props.coupon?.coupon_ebook_set.map((ebook) => {
                    return (
                      <p className='coupon-apply-target-title' key={ebook.ebook_id}>
                        - {ebook.ebook_title}
                      </p>
                    );
                  })}
                </div>
              ) : null}
              {props.coupon?.coupon_product_set.length > 0 ? (
                <div className='coupon-class-type product-type'>
                  <h5>패키지 강의</h5>
                  {props.coupon?.coupon_product_set.map((product) => {
                    return (
                      <p className='coupon-apply-target-title' key={product.product_id}>
                        - {product.product_short_title}
                      </p>
                    );
                  })}
                </div>
              ) : null}
            </div>
            {props.coupon?.can_use_at_extend_course === 0 ? (
              <div className='coupon-class-type product-type no-extension'>
                <h5>*해당 쿠폰은 기간 연장에 사용 불가</h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

CouponInfoModal.propTypes = propTypes;

export default withRouter(CouponInfoModal);
