import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { isIOS } from 'react-device-detect';
import moment from 'moment';

import { set_footer_type, set_header_type, set_tmp } from '../../redux/common/action';
import { dialog_type_custom, useDialogContext } from '../_common/dialog/DialogContextProvider';
import { stored_account_info } from '../../redux/account/action';
import '../../assets/scss/page/purchase.scss';
import { alert_and_redirct, init_comma } from '../../common';
import AccountModel from '../../model/AccountModel';
import PurchaseModel from '../../model/PurchaseModel';
import CouponChoiceModal from '../_common/dialog/CouponChoiceModal';
import AddressChangeModal from '../_common/dialog/AddressChangeModal';
import PixelModel from '../../model/PixelModel';

const Purchase = (props) => {
  //Modal
  const { showDialog, alert, confirm } = useDialogContext();

  //Redux
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const AccountStore = useSelector((state) => state.AccountStore);

  //State
  const [total_price, setTotalPrice] = useState(null);
  const [purchase_price, setPurchasePrice] = useState(0);
  const [discount_set, setDiscountSet] = useState([]);
  const [coupon_ids, setCouponIds] = useState([]);
  const [shipping_msg, setShippingMsg] = useState('');
  const [check_index, setCheckIndex] = useState(0);
  const [is_purchase, setIsPurchase] = useState(false);
  const [payment_agree_set, setPaymentAgreeSet] = useState([
    { name: '이용약관/환불규정', url: '/service/terms', is_check: false },
    { name: '개인정보처리방침', url: '/service/privacy', is_check: false },
  ]);
  const [is_init, setIsInit] = useState(false);
  const [orderd_kit_set, setOrderedKitSet] = useState([]);
  const [is_purchase_pending, setIsPurchasePending] = useState(false);
  const _is_mount = useRef(false);
  const [rentalTimeInDay, setRentalTimeInDay] = useState(0);

  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });

  const payment_set = [
    {
      id: 1,
      name: '카드',
      pay_method: 'card',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/purchase/icon-credit-card-settings-outline.png`,
    },
    {
      id: 3,
      name: '계좌이체',
      pay_method: 'trans',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/purchase/icon-transfer.png`,
    },
    {
      id: 4,
      name: '가상계좌',
      pay_method: 'vbank',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/purchase/icon-card-account-details-outline.png`,
    },
    {
      id: 5,
      name: '케이페이',
      pay_method: 'kpay',
      img_url: `${process.env.REACT_APP_IMG_URL}/static/png/purchase/icon-kpay.png`,
    },
  ];

  if (isIOS) {
    payment_set.pop();
  }

  useEffect(() => {
    _is_mount.current = true;
    dispatch(set_footer_type(0));
    dispatch(
      set_header_type(0, 2, {
        title: CommonStore.tmp.purchase?.title,
        no_search_btn: true,
        no_footer: true,
        no_top_btn: true,
        no_channel_talk_button: true,
      }),
    );
    return () => {
      _is_mount.current = false;
    };
  }, []);

  useEffect(() => {
    if (CommonStore.tmp?.purchase) {
      if (
        !coupon_ids.length &&
        moment().diff(moment(CommonStore.tmp.purchase.expire_date), 'seconds') > 120
      ) {
        dispatch(set_tmp('purchase', null));
        alert_and_redirct(alert, props.history, '잘못된 접근입니다.');
      } else {
        let validate_kit_set = [];
        let kit_cant_discount = false;
        for (let i = 0; i < CommonStore.tmp.purchase.kit_set.length; i++) {
          if (coupon_ids.length && !CommonStore.tmp.purchase.kit_set[i].can_discount) {
            kit_cant_discount = true;
          }
          let check = false;
          for (let j = 0; j < validate_kit_set.length; j++) {
            if (validate_kit_set[j].id === CommonStore.tmp.purchase.kit_set[i].id) {
              validate_kit_set[j].order_count++;
              check = true;
              break;
            }
          }
          if (!check) {
            validate_kit_set.push({
              id: CommonStore.tmp.purchase.kit_set[i].id,
              order_count: 1,
              price: CommonStore.tmp.purchase.kit_set[i].price_info[0].our_sale_price,
              title: CommonStore.tmp.purchase.kit_set[i].title,
            });
          }
        }
        setOrderedKitSet(validate_kit_set);
        const priceValidateData = {
          currency_code: 'KRW',
          course_sale_info_id: CommonStore.tmp.purchase.purchase_item.sale_id,
          coupon_ids: coupon_ids,
          package_id: CommonStore.tmp.purchase.purchase_item.product_id,
          extend_month: CommonStore.tmp.purchase.purchase_item.extend_month,
          kit_set: validate_kit_set,
          event_code: CommonStore.tmp.purchase.event_code,
        };
        if (CommonStore.tmp.purchase.purchase_type === 'ebook') {
          delete priceValidateData.course_sale_info_id;
          delete priceValidateData.package_id;
          priceValidateData.ebook_sale_info_id = CommonStore.tmp.purchase.purchase_item.sale_id;
        }
        PurchaseModel.priceValidate(priceValidateData).then(
          async ({ code, discount_set, total_price, purchase_price }) => {
            if (_is_mount.current) {
              if (code === 200) {
                setDiscountSet(discount_set);
                setTotalPrice(total_price);
                setPurchasePrice(purchase_price);
                if (!is_init) {
                  props.ready();
                  setIsInit(true);
                }
                window.scrollTo(0, 0);
                if (kit_cant_discount) {
                  await alert('일부 키트가격은 할인되지 않습니다.');
                }
                PixelModel.postConversionAPI({
                  eventName: 'CustomizeProduct',
                  AccountStore: AccountStore,
                  content_name: {
                    value: discount_set,
                    content_name: `${code}쿠폰사용`,
                  },
                });
              } else if (code === 4800) {
                await alert('해당 상품에 사용할 수 없는 쿠폰입니다.');
                setCouponIds([]);
              } else if (code === 4812) {
                alert_and_redirct(
                  alert,
                  props.history,
                  '최초 강의 보유자에게만 제공되는 이벤트입니다.',
                );
              } else if (code === 4813) {
                alert_and_redirct(alert, props.history, '이미 참여하신 이벤트입니다.');
              } else if (code === 4811) {
                await alert('해당 상품은 쿠폰을 함께 사용할 수 없습니다.');
                setCouponIds([]);
              } else {
                alert_and_redirct(alert, props.history, '잘못된 요청입니다.');
              }
            }
          },
        );
      }
    } else {
      alert_and_redirct(alert, props.history, '잘못된 접근입니다.');
    }
  }, [coupon_ids]);

  const onAgreeClick = (target_index) => {
    let _is_chk = true;

    payment_agree_set[target_index].is_check = !payment_agree_set[target_index].is_check;
    setPaymentAgreeSet([...payment_agree_set]);

    payment_agree_set.forEach((agree) => {
      _is_chk = agree['is_check'] && _is_chk;
    });
    setIsPurchase(_is_chk);
  };

  const onPurchase = async () => {
    if (is_purchase && !is_purchase_pending) {
      setIsPurchasePending(true);
      let IMP = window.IMP;
      IMP.init('imp62499270');
      let purchase_kit_set = [];
      for (let i = 0; i < CommonStore.tmp.purchase.kit_set.length; i++) {
        let check = false;
        for (let j = 0; j < purchase_kit_set.length; j++) {
          if (purchase_kit_set[j].id === CommonStore.tmp.purchase.kit_set[i].id) {
            purchase_kit_set[j].order_count++;
            check = true;
            break;
          }
        }
        if (!check) {
          purchase_kit_set.push({ id: CommonStore.tmp.purchase.kit_set[i].id, order_count: 1 });
        }
      }
      let pay_data = {
        purchase_type: CommonStore.tmp.purchase.purchase_type,
        paymethod_id: payment_set[check_index].id,
        currency_code: 'KRW',
        course_sale_info_id: CommonStore.tmp.purchase.purchase_item?.sale_id || null,
        coupon_ids: coupon_ids,
        course_id: CommonStore.tmp.purchase.purchase_item?.course_id || null,
        package_id: CommonStore.tmp.purchase.purchase_item?.product_id || null,
        extend_month: CommonStore.tmp.purchase.purchase_item?.extend_month || null,
        kit_set: purchase_kit_set,
        event_code: CommonStore.tmp.purchase.event_code,
        shipping_msg: shipping_msg,
      };
      if (CommonStore.tmp.purchase.purchase_type === 'ebook') {
        delete pay_data.course_id;
        delete pay_data.course_sale_info_id;
        pay_data.ebook_id = CommonStore.tmp.purchase.purchase_item.ebook_id;
        pay_data.ebook_sale_info_id = CommonStore.tmp.purchase.purchase_item.sale_id;
      }

      PurchaseModel.purchase(pay_data).then(
        async ({ code, product_name, amount, tax_free, merchant_uid, purchase_status }) => {
          const pg_id =
            CommonStore.tmp.purchase.purchase_type === 'ebook'
              ? 'html5_inicis.MOIbeaucom'
              : 'html5_inicis.MOIbearu01';
          PixelModel.postConversionAPI({
            eventName: 'InitiateCheckout',
            AccountStore: AccountStore,
            customDataOptions: {
              value: amount,
              content_name: product_name,
            },
          });
          if (code === 200) {
            if (purchase_status === 1) {
              IMP.request_pay(
                {
                  pg: pg_id,
                  pay_method: payment_set[check_index].pay_method,
                  merchant_uid: merchant_uid,
                  name: product_name,
                  amount: amount,
                  tax_free: tax_free,
                  vbank_due: moment().add(24, 'hours').format('YYYYMMDDHHmm'),
                  buyer_email: AccountStore?.info?.email || '',
                  buyer_name: AccountStore?.info?.name || '',
                  buyer_tel: AccountStore?.info?.phone || '',
                  buyer_addr: AccountStore?.info?.address_code
                    ? AccountStore?.info?.address + ' ' + AccountStore?.info?.address_detail
                    : '',
                  buyer_postcode: AccountStore?.info?.address_code || '',
                  notice_url: process.env.REACT_APP_IAMPORT_WEBHOOK_END_POINT,
                  m_redirect_url: `${window.location.origin}/purchase/result`,
                  display: {
                    card_quota: [2, 3, 4, 5, 6],
                  },
                },
                (rsp) => {
                  if (rsp.success) {
                    props.history.push({
                      pathname: '/purchase/result',
                      state: {
                        merchant_uid,
                        imp_uid: rsp.imp_uid,
                        gtm_data: CommonStore.tmp.purchase.gtm_data,
                        orderd_kit_set: orderd_kit_set,
                      },
                    });
                  } else {
                    // PurchaseModel.purchaseCancel({merchant_uid, imp_uid: rsp.imp_uid}).then(({code}) => {
                    props.history.push({
                      pathname: '/purchase/result',
                      state: {
                        merchant_uid,
                        imp_uid: rsp.imp_uid,
                        err_msg: rsp.error_msg,
                      },
                    });
                    // });
                  }
                },
              );
            } else if (purchase_status === 2) {
              props.history.push({
                pathname: '/purchase/result',
                state: {
                  merchant_uid,
                  is_complete: true,
                  gtm_data: CommonStore.tmp.purchase.gtm_data,
                  orderd_kit_set: orderd_kit_set,
                },
              });
            }
          } else if (code === 4701) {
            props.history.push({
              pathname: '/purchase/result',
              state: {
                merchant_uid,
                is_cancel: true,
                cancel_msg: '키트상품이 품절 되었습니다.',
              },
            });
          } else if (code === 4601) {
            await alert('강의가 판매 가능한 상태가 아닙니다.');
          } else {
            await alert('결제 시도중 에러가 발생했습니다. 관리자에게 문의해주세요.');
          }

          setIsPurchasePending(false);
        },
      );
    }

    if (is_purchase_pending) {
      await alert('결제 처리 중 입니다.');
    }
  };

  const onAddressChange = (address) => {
    AccountModel.profileEdit({
      address: address.roadAddress,
      address_code: address.zonecode,
      address_detail: address.address_detail,
    }).then(({ account }) => {
      dispatch(stored_account_info(account));
    });
  };

  const [coupon_set, setCouponSet] = useState([]);
  useEffect(() => {
    _is_mount.current = true;
    AccountModel.myCouponList().then(({ coupon_set }) => {
      if (_is_mount.current) {
        setCouponSet(coupon_set);
      }
    });
  }, []);

  useEffect(() => {
    if (CommonStore.tmp.purchase?.purchase_item?.rental_time_in_day) {
      setRentalTimeInDay(CommonStore.tmp.purchase?.purchase_item?.rental_time_in_day);
    }
  }, [CommonStore]);

  return (
    <>
      <Helmet>
        <title>{CommonStore.tmp.purchase?.title}</title>
      </Helmet>

      {CommonStore.tmp.purchase?.gtm_data ? (
        <>
          <input
            type='hidden'
            name='ai_pnm[]'
            value={CommonStore.tmp.purchase.gtm_data.pnm || ''}
          />
          <input
            type='hidden'
            name='ai_pid[]'
            value={CommonStore.tmp.purchase.gtm_data.pid || ''}
          />
          <input
            type='hidden'
            name='ai_ppc[]'
            value={CommonStore.tmp.purchase.gtm_data.ppc || ''}
          />
          <input
            type='hidden'
            name='ai_ppb[]'
            value={CommonStore.tmp.purchase.gtm_data.ppb || ''}
          />
          <input
            type='hidden'
            name='ai_pca[]'
            value={CommonStore.tmp.purchase.gtm_data.pca || ''}
          />
          <input type='hidden' name='ai_pva[]' value='' />
          <input type='hidden' name='ai_pqt[]' value={1} />
        </>
      ) : null}

      {orderd_kit_set.map((kit) => {
        return (
          <div key={kit.id}>
            <input type='hidden' name='ai_pnm[]' value={kit.title.replace(/\r\n/g, ' ')} />
            <input type='hidden' name='ai_pid[]' value={`k${kit.id.toString().padStart(8, '0')}`} />
            <input type='hidden' name='ai_ppc[]' value={kit.price} />
            <input type='hidden' name='ai_ppb[]' value='' />
            <input type='hidden' name='ai_pca[]' value='옵션상품' />
            <input type='hidden' name='ai_pva[]' value='' />
            <input type='hidden' name='ai_pqt[]' value={kit.order_count} />
          </div>
        );
      })}

      {CommonStore.tmp.purchase ? (
        <div className='user-container purchase-container'>
          <div className='td-wrapper'>
            <div className='purchase-content'>
              <div className='purchase-header'>
                <h3>{!is_tablet_or_mobile ? CommonStore.tmp.purchase?.title : '구매하기'}</h3>
              </div>
              <div
                className={`purchase-section ${CommonStore.tmp.purchase.purchase_type === 'kit' ? 'kit' : ''}`}
              >
                <div className='select-wrap'>
                  <h4>구매 목록</h4>
                  {CommonStore.tmp.purchase.purchase_type !== 'kit' &&
                    CommonStore.tmp.purchase.purchase_item && (
                      <ul className='sale-select-list'>
                        <li>
                          <h5>· {CommonStore.tmp.purchase.purchase_item.short_title}</h5>
                          <h6>
                            {init_comma(CommonStore.tmp.purchase.purchase_item.original_price)}원
                          </h6>
                          {CommonStore.tmp.purchase.purchase_item?.description && (
                            <p>{CommonStore.tmp.purchase.purchase_item?.description}</p>
                          )}
                        </li>
                      </ul>
                    )}

                  {CommonStore.tmp.purchase.kit_set.length ? (
                    <ul className='kit-select-list'>
                      {CommonStore.tmp.purchase.kit_set.map((kit, index) => {
                        return (
                          <li key={index}>
                            <h5>· {kit.title}</h5>
                            <h6>{init_comma(kit.price_info[0].our_sale_price)}원</h6>
                            {kit.description && <p>{kit.description}</p>}
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>

                {CommonStore.tmp.purchase.kit_set.length ? (
                  <div className='kit-delivery-wrap'>
                    {CommonStore.tmp.purchase.kit_set.findIndex(
                      (kit) => kit.shipping_method_id === 1,
                    ) !== -1 && (
                      <>
                        <h4>입력하신 아래 주소로 배송이 진행됩니다.</h4>
                        <div className='delivery-info'>
                          <p>
                            ({AccountStore?.info?.address_code}) {AccountStore?.info?.address}{' '}
                            {AccountStore?.info?.address_detail}
                          </p>
                          <button
                            onClick={() => {
                              showDialog({
                                type: dialog_type_custom,
                                component: AddressChangeModal,
                                component_props: {
                                  is_detail: true,
                                  onComplete: onAddressChange,
                                },
                              });
                            }}
                          >
                            수정
                          </button>
                        </div>
                        <div className='address-edit'>
                          <label>요청사항</label>
                          <input
                            type='text'
                            placeholder='배송시 요청사항을 입력해주세요.'
                            maxLength={64}
                            onChange={(e) => setShippingMsg(e.target.value)}
                          />
                        </div>
                      </>
                    )}

                    {CommonStore.tmp.purchase.kit_set.findIndex(
                      (kit) => kit.shipping_method_id === 2,
                    ) !== -1 && (
                      <h4 className='digital-content-text'>
                        ※ 택배 배송이 아닌 키트는 별도로
                        <br />
                        안내드린 방식으로 배송이 진행될 예정입니다.
                      </h4>
                    )}
                  </div>
                ) : null}

                <ul className='purchase-info'>
                  <li>
                    <h5>실 구매가</h5>
                    <h6>{init_comma(total_price || 0)}원</h6>
                  </li>

                  {discount_set.map((discount, index) => {
                    return (
                      <React.Fragment key={index}>
                        {discount.price !== 0 && (
                          <li>
                            <h5>{discount.title}</h5>
                            <h6 className='sale-price'>
                              {isNaN(parseInt(init_comma(discount.price || 0)))
                                ? discount.price
                                : '-' + init_comma(discount.price || 0) + '원'}

                              {discount.type === 'coupon' && (
                                <img
                                  className='del-button'
                                  src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-delete.png`}
                                  alt='삭제'
                                  onClick={async () => {
                                    if (await confirm('쿠폰 사용을 취소할까요?')) {
                                      let new_coupon_ids = coupon_ids.filter((_elem, _index) => {
                                        return discount.id !== _elem;
                                      });
                                      setCouponIds(new_coupon_ids);
                                    }
                                  }}
                                />
                              )}
                            </h6>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>

                <div>
                  <div
                    className={`total-price-wrap flex ${
                      CommonStore.tmp.purchase.purchase_type !== 'kit' &&
                      CommonStore.tmp.purchase?.can_coupon
                        ? 'exist_coupon'
                        : ''
                    }`}
                  >
                    <h4>총 결제 금액</h4>
                    <h6 className='total-price'>{init_comma(purchase_price || 0)}원</h6>
                  </div>
                  {rentalTimeInDay / 30 > 1 ? (
                    <p className='description'>
                      ✅ <span className='info_text'>할부 결제 시</span> 총결제 금액인{' '}
                      {init_comma(purchase_price || 0)}원이 할부개월 만큼 분할되어 결제 됩니다.
                      <br />
                      예시) {init_comma(purchase_price || 0)}원{' '}
                      <span className='info_text'>{Math.round(rentalTimeInDay / 30)}개월</span> 결제
                      시{' '}
                      <span className='info_text'>
                        {init_comma(
                          rentalTimeInDay >= 30
                            ? Math.floor(purchase_price / (rentalTimeInDay / 30))
                            : purchase_price,
                        )}
                        원
                      </span>{' '}
                      씩 <span className='info_text'>매달 결제</span>됨
                    </p>
                  ) : (
                    ''
                  )}
                </div>

                {CommonStore.tmp.purchase?.can_coupon ? (
                  <>
                    <div
                      className='coupon-select-btn'
                      onClick={() => {
                        showDialog({
                          type: dialog_type_custom,
                          component: CouponChoiceModal,
                          component_props: {
                            purchase_type: CommonStore.tmp.purchase.purchase_type,
                            is_extend_course: CommonStore.tmp.purchase?.is_extend_course || false,
                            course_id: CommonStore.tmp.purchase?.purchase_item?.course_id || null,
                            ebook_id: CommonStore.tmp.purchase?.purchase_item?.ebook_id || null,
                            package_id: CommonStore.tmp.purchase?.purchase_item?.product_id || null,
                            selectedCouponIds: coupon_ids || [],
                            setCouponIds: setCouponIds,
                          },
                        });
                      }}
                    >
                      쿠폰 선택하기
                      {coupon_set?.length > 0 && (
                        <span className='info_text pl-2'>{coupon_set?.length}개</span>
                      )}
                    </div>
                    <p className='info_text pt-3' style={{ lineHeight: '1.5' }}>
                      간편결제(카카오페이, 네이버페이 등)는 카드결제수단을 체크 후 진행해 주세요
                    </p>
                  </>
                ) : null}
              </div>

              <div className='purchase-section' style={{ borderTop: '2px solid #f6f6f6' }}>
                <h4>결제 수단</h4>
                <ul className='payment-way-wrap'>
                  {payment_set.map((payment, index) => {
                    let is_check_index = index === check_index;
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setCheckIndex(index);
                        }}
                        className={is_check_index ? 'checked' : ''}
                      >
                        <img src={payment.img_url} alt={payment.name} />
                        <span>{payment.name}</span>
                      </li>
                    );
                  })}
                </ul>
                <p className='info_text pt-3' style={{ lineHeight: '1.5' }}>
                  BC카드는 3개월 이내 할부만 가능합니다
                </p>
              </div>

              <div className='purchase-section' style={{ borderTop: '2px solid #f6f6f6' }}>
                <ul className='payment-agree-wrap'>
                  {payment_agree_set.map((agree, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          onAgreeClick(index);
                        }}
                        className={agree.is_check ? 'checked' : ''}
                      >
                        {agree.is_check ? (
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/png/purchase/icon-check-able.png`}
                            alt={agree.name + '에 동의 체크'}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/png/purchase/icon-check-disable.png`}
                            alt={agree.name + '에 동의 체크전'}
                          />
                        )}
                        <p className='check-content'>
                          <span>[필수]</span>
                          <span onClick={() => window.open(agree.url, '_blank')}>{agree.name}</span>
                          <span>에 동의합니다.</span>
                        </p>
                      </li>
                    );
                  })}
                </ul>

                {!is_tablet_or_mobile && (
                  <div
                    className={
                      'td-btn payment-btn' +
                      (is_purchase && !is_purchase_pending ? ' active' : ' inactive')
                    }
                    onClick={onPurchase}
                  >
                    {is_purchase_pending ? '처리중' : '결제하기'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {is_tablet_or_mobile && (
        <div className='mo-payment-btn-wrap'>
          <div
            className={
              'td-btn payment-btn' + (is_purchase && !is_purchase_pending ? ' active' : ' inactive')
            }
            onClick={onPurchase}
          >
            {is_purchase_pending ? '처리중' : '결제하기'}
          </div>
        </div>
      )}
    </>
  );
};
export default withRouter(Purchase);
