import React, { useEffect, useRef, useState } from 'react';
import TemplateInput from '../_template/TemplateInput';
import PropTypes from 'prop-types';
import AccountModel from '../../../model/AccountModel';
import InputLabel from './InputLabel';
import { useBroadcastContext } from '../broadcast/BroadcastContextProvider';
import { BROADCAST_NICKNAME_VALIDATION_CHECK } from '../../../constant/broadcast';

const propTypes = {
  default_value: PropTypes.string, // 회원 수정 시 받아올 default value
  require: PropTypes.bool, // require 표시 여부
  on_data_changed: PropTypes.func, // text, validated
};

const NicknameInput = (props) => {
  const [nick_error_message, setNickErrorMessage] = useState('');
  const [is_nick_error, setIsNickError] = useState(false);
  const require = props?.require ?? true;
  const nickname_ref = useRef(null);
  const { broadcast } = useBroadcastContext();
  const { is_values_check } = props;

  useEffect(() => {
    let unlisten = broadcast.listen([BROADCAST_NICKNAME_VALIDATION_CHECK], () => {
      if (!is_values_check.current.nickname) {
        setIsNickError(true);
        setNickErrorMessage('닉네임을 확인해주세요');
      } else {
        setNickErrorMessage('');
      }
    });

    return () => {
      broadcast.unlisten(unlisten);
    };
  }, [broadcast]);

  // 닉네임 유효성 검사
  const checkNicknameValidate = (text) => {
    const req_type = 'nickname';
    const req_value = text;
    const reg_nickname = /^[가-힣a-zA-Z0-9]+$/;

    if (reg_nickname.test(req_value)) {
      if (['베어유', '관리자'].includes(req_value)) {
        setIsNickError(true);
        setNickErrorMessage('사용할 수 없는 닉네임입니다');
      } else {
        AccountModel.duplicateCheck({ req_type, req_value }).then(({ code }) => {
          if (code === 200) {
            setIsNickError(false);
            setNickErrorMessage('사용가능한 닉네임입니다');
            is_values_check.current.nickname = true;
          } else if (props.default_value === req_value) {
            setIsNickError(false);
            setNickErrorMessage('');
          } else {
            setIsNickError(true);
            setNickErrorMessage('이미 존재하는 닉네임입니다');
          }
        });
      }
    } else if (req_value === '') {
      setIsNickError(false);
      setNickErrorMessage('');
    } else {
      setIsNickError(true);
      setNickErrorMessage('한글/영문/숫자만 입력 가능합니다');
    }
  };

  const onInputChange = () => {
    let text = nickname_ref.current.value;
    checkNicknameValidate(text);
  };

  return (
    <div className='nickname-input-wrap'>
      <InputLabel label={'닉네임'} require={require} />

      <div className='nickname-wrap'>
        <TemplateInput
          is_error={is_nick_error}
          error_message={nick_error_message}
          input_props={{
            placeholder: '닉네임을 입력해주세요(8자 이내)',
            maxLength: 8,
            defaultValue: props?.default_value,
            ref: nickname_ref,
            onFocus: () => {
              setIsNickError(false);
              setNickErrorMessage('');
            },
            onChange: (text) => {
              props.on_data_changed && props.on_data_changed(text);
            },
          }}
        />

        <button className='confirm-btn' tabIndex={-1} onClick={onInputChange}>
          중복확인
        </button>
      </div>
    </div>
  );
};

NicknameInput.prototype = propTypes;

export default NicknameInput;
