import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from '../../../util/BearuHooks';
import { date_diff } from '../../../common';
import moment from 'moment';

const propTypes = {
  is_start: PropTypes.bool, // 시작 X -> false
  is_expire: PropTypes.bool, // 만료 X -> false
  start_time: PropTypes.string, // 시작 날짜 & 시간
  expire_time: PropTypes.string, // 종료 날짜 & 시간
  start_data_set: PropTypes.object, // 시작 전 content
  ongoing_data_set: PropTypes.object, // 진행 시 content
  end_data_set: PropTypes.object, // 완료 시 content
};

const CountBanner = (props) => {
  const [is_loaded, setLoaded] = useState(false);
  const [count_time, setCountTime] = useState(null);
  const { is_start, is_expire, start_time, end_time } = props;

  const start_data_set = props.start_data_set ?? init_start_data_set;
  const ongoing_data_set = props.ongoing_data_set ?? init_ongoing_data_set;
  const end_data_set = props.end_data_set ?? init_end_data_set;

  useInterval(
    () => {
      let start_diff = date_diff(start_time, moment());
      let end_diff = date_diff(end_time, moment());

      let is_not_started =
        start_diff.day >= 0 &&
        start_diff.hour >= 0 &&
        start_diff.minute >= 0 &&
        start_diff.second >= 0;

      let _is_expire =
        end_diff.day <= 0 && end_diff.hour <= 0 && end_diff.minute <= 0 && end_diff.second <= 0;

      const makeCountDate = (_date_diff) => {
        return {
          day: _date_diff.day,
          hour: _date_diff.hour.toString().padStart(2, '0'),
          minute: _date_diff.minute.toString().padStart(2, '0'),
          second: _date_diff.second.toString().padStart(2, '0'),
        };
      };

      // 시작 전 or 진행중
      if (start_time === null) {
        setCountTime(makeCountDate(end_diff));
      } else if (is_not_started) {
        setCountTime(makeCountDate(start_diff));
      } else {
        setCountTime(makeCountDate(end_diff));
      }

      // 만료 시
      if (_is_expire) {
        setCountTime({ day: '0', hour: '00', minute: '00', second: '00' });
      }
      setLoaded(true);
    },
    end_time ? 1000 : null,
  );

  return (
    is_loaded && (
      <div className={'count-banner-wrap' + (is_expire ? ' expire' : '')}>
        <div className={'count-time-wrap'}>
          <span className='day-number'>{count_time?.day}</span>
          <span className='day-text'>일</span>
          <div className='time-wrap'>
            <span>{count_time?.hour}</span>
            <span className='colon'>:</span>
            <span>{count_time?.minute}</span>
            <span className='colon'>:</span>
            <span>{count_time?.second}</span>
            <span>남음</span>
          </div>
        </div>

        <div className={'count-content-wrap'}>
          {is_start ? (
            <>
              {is_expire ? (
                <>
                  <p className='title'>{end_data_set.title}</p>
                  <p className='sub-title'>{end_data_set.sub_title}</p>
                </>
              ) : (
                <>
                  <p className='title'>{ongoing_data_set.title}</p>
                  <p className='sub-title'>{ongoing_data_set.sub_title}</p>
                </>
              )}
            </>
          ) : (
            <>
              <p className='title'>{start_data_set.title}</p>
              <p className='sub-title'>{start_data_set.sub_title}</p>
            </>
          )}
        </div>
      </div>
    )
  );
};

// content init_data_set
const init_start_data_set = {
  title: '🛎 이벤트 시작까지 🛎',
  sub_title: '*타임특가가 곧 오픈합니다!! 많은 관심부탁드립니다🎉',
};

const init_ongoing_data_set = {
  title: '⏰ 특가 종료까지 ⏰',
  sub_title: '*다시는 보기 힘들 가격! 지금 바로 누리세요💸',
};

const init_end_data_set = {
  title: '❌ 타임특가가 종료되었습니다 ❌',
  sub_title: '*다음에도 많은 관심부탁드립니다😆',
};

CountBanner.propsTypes = propTypes;

export default CountBanner;
