import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputLabel from './InputLabel';

const propTypes = {
  default_value: PropTypes.string, // 회원 수정 시 받아올 default value
  require: PropTypes.bool, // require 표시 여부
  force_error: PropTypes.bool, // error 상황 처리
  on_data_changed: PropTypes.func, // text, validated
};

const GenderButton = (props) => {
  const [is_checked, setIsChecked] = useState(props?.default_value ? props.default_value : '');
  const [is_error, setIsError] = useState(false);
  const gender_ref = useRef('');
  const require = props?.require ?? true;

  const gender_set = [
    { type: 'male', text: '남자' },
    { type: 'female', text: '여자' },
  ];

  useEffect(() => {
    if (props.force_error) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [props.force_error]);

  return (
    <div className='gender-button-wrap'>
      <InputLabel label={'성별'} require={require} />

      <div className='input-wrap'>
        <div className='gender-btn-wrap'>
          {gender_set.map((gender, index) => {
            return (
              <button
                tabIndex={0}
                key={index}
                type='button'
                value={gender_ref.current}
                className={'gender-btn' + (is_checked === gender.type ? ' active' : '')}
                onClick={() => {
                  const text = (gender_ref.current = gender.type);
                  let validated = text.length > 0;

                  if (text === gender.type) {
                    setIsChecked(text);
                    setIsError(false);
                  }
                  props.on_data_changed && props.on_data_changed(text, validated);
                }}
              >
                {gender.text}
              </button>
            );
          })}
        </div>
        {is_error && <p className='default-msg'>필수항목입니다</p>}
      </div>
    </div>
  );
};

GenderButton.propTypes = propTypes;

export default GenderButton;
