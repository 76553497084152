import { createStore, applyMiddleware, compose } from 'redux';
// middlewares
import thunkMiddleware from 'redux-thunk';
import reducers from '../redux/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const persistConfig = {
  key: 'bearu',
  transforms: [
    encryptTransform({
      secretKey: '5Kj<ye|RNE2]K`(Z(g1u@HS)R^Dd$tjnl1FlBZRx5G,@F8`2ZG#HT4EH]pf(Ht',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
  storage,
};
const enhancedReducer = persistReducer(persistConfig, reducers);

export default function configureStore() {
  const store = createStore(
    enhancedReducer,
    compose(
      applyMiddleware(thunkMiddleware),
      //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
      window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : function (f) {
            return f;
          },
    ),
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
