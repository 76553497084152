import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label_name: PropTypes.string,
};

const InputLabel = (props) => {
  return (
    <div className='label-wrap'>
      <label className='label' htmlFor={props?.htmlFor}>
        {props.label || '입력항목'}
        {props.require && <span>*</span>}
      </label>
    </div>
  );
};

InputLabel.prototype = propTypes;

export default InputLabel;
