import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stored_account_info } from '../redux/account/action';
import AccountModel from '../model/AccountModel';
import TagManager from 'react-gtm-module';
import { useMutaionObserver } from './BearuHooks';
import { reset_static_code, reset_tmp, set_noti, set_version } from '../redux/common/action';
import CommonModel from '../model/CommonModel';

// permission: all (전부다)
// permission: login (로그인 유저만)
// permission: logout (로그아웃 유저만)
function Permission(Component, permission = 'all', additional_props) {
  function Auth(props) {
    const dispatch = useDispatch();
    const [check, setCheck] = useState(false);
    const AccountStore = useSelector((state) => state.AccountStore);
    const CommonStore = useSelector((state) => state.CommonStore);

    const [pagecount, setPageCount] = useState(0);
    const trigger = useRef(null);

    const ready = () => {
      setPageCount(pagecount + 1);
    };

    useMutaionObserver(trigger, () => {
      setTimeout(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PageView',
            pageTitle: document.title,
          },
        });
      }, 500);
    }, [trigger]);

    useEffect(() => {
      if (permission === 'login' && !AccountStore) {
        AccountModel.validate({ access_token: null })
          .then(({ code, account }) => {
            if (code === 200) {
              dispatch(stored_account_info(account));
              setCheck(true);
            } else {
              props.history.push('/user/signin');
            }
          })
          .catch((e) => {
            dispatch(reset_tmp());
            dispatch(stored_account_info(null));
            props.history.push('/');
          });
      } else if (permission === 'logout' && AccountStore) {
        props.history.push('/');
      } else {
        setCheck(true);
      }
    }, []);

    useEffect(() => {
      AccountModel.notificationNewCheck().then(({ is_new_noti, total_count }) => {
        dispatch(set_noti(total_count, is_new_noti));
      });
    }, [props.match.path]);

    useEffect(() => {
      CommonModel.version('W').then(({ version }) => {
        // version: {min_num_ver, cur_num_ver, ...}
        let need_reload = false;

        if (version?.min_num_ver > CommonStore.version?.cur_num_ver) {
          // @TODO redux 특정 값 초기화 해줄 가능성 있음
          need_reload = true;
        }
        dispatch(set_version(version));

        if (need_reload) {
          dispatch(reset_static_code());
          window.location.reload();
        }
      });
    }, []);

    return (
      <>
        {check ? <Component {...props} {...additional_props} ready={ready} /> : null}
        <div style={{ display: 'none' }} ref={trigger}>
          {pagecount}
        </div>
      </>
    );
  }

  return Auth;
}

export default Permission;
