import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { replace_cloudfront_url } from '../../../common';

const propTypes = {
  course: PropTypes.object,
};

const SmallCourseCard = (props) => {
  return (
    <div
      className={'mobile-course-card' + (props.course.is_expired ? ' expired' : '')}
      style={props.disabled ? { opacity: 0.3 } : {}}
    >
      <Link to={`/course/${props.course.id}`}>
        <div className='course-img-wrap'>
          <div
            style={{
              background: `url("${replace_cloudfront_url(props.course.circle_image_url)}") no-repeat center center/cover`,
            }}
          />
        </div>
        <div className='course-segment'>
          <p>
            {props.course.short_title ? '[' + props.course.short_title + ']' : ''}{' '}
            {props.course.title}
          </p>
        </div>
      </Link>
    </div>
  );
};

SmallCourseCard.propTypes = propTypes;

export default withRouter(SmallCourseCard);
