import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import '../../assets/scss/page/sign_in.scss';
import AccountModel from '../../model/AccountModel';
import { stored_account_info } from '../../redux/account/action';
import { set_footer_type, set_header_type, set_tmp } from '../../redux/common/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import { useMount } from '../../util/BearuHooks';
import { encryptData } from '../../util/crypto-utils';
import EmailInput from '../_common/Input/EmailInput';
import TemplateInput from '../_common/_template/TemplateInput';
import InputLabel from '../_common/Input/InputLabel';

const SignIn = (props) => {
  const dispatch = useDispatch();
  const CommonStore = useSelector((state) => state.CommonStore);
  const { alert } = useDialogContext();
  const [isMount] = useMount();

  const [step, setStep] = useState(1);
  const [password_error_message, setPasswordErrorMessage] = useState('');
  const [password_error, setPasswordError] = useState(false);

  const input_values = useRef({
    email: '',
    password: '',
  });

  const input_list_ref = useRef({
    email: null,
    password: null,
  });

  const is_values_check = useRef({
    email: false,
    password: false,
  });

  useEffect(() => {
    if (props.match.params?.social) {
      const provider_type = 4;
      const social_token = new URLSearchParams(props.location.search).get('code');
      const redirect_url = process.env.REACT_APP_KAKAO_REDIRECT_URL;

      if (provider_type) {
        AccountModel.socialSign({
          provider_type,
          social_token,
          redirect_url,
        }).then(async ({ code, account, is_sign_up }) => {
          if (code === 200) {
            dispatch(stored_account_info(account));
            Cookies.set('ebookAuth', `${account.id}`, {
              domain: '.bear-u.com',
              path: '/',
              expires: 3,
            });

            if (is_sign_up) {
              props.history.push('/user/signup/complete');
            } else {
              ReactPixel.trackCustom('카카오 로그인');
              dispatch(set_tmp('redirect_url', null));
              props.history.push(CommonStore.tmp.redirect_url || '/');
            }

            AccountModel.bgdCheck().then(({ data }) => {
              if (data?.bgd?.length > 0) {
                const isBgdSessionId = Cookies.get('bgdSessionId');
                if (isBgdSessionId) {
                  Cookies.remove('bgdSessionId', { domain: '.bear-u.com', path: '/' });
                }
                const sessionData = createSessionData(account);
                Cookies.set('bgdSessionId', `${sessionData}`, {
                  domain: '.bear-u.com',
                  path: '/',
                  expires: 14,
                });
              }
            });
          } else if (code === 4505) {
            await alert('탈퇴한 계정입니다');
          } else if (code === 4506) {
            await alert('이용정지 계정입니다');
          } else {
            await console.error('code', code);
            await alert('오류가 발생하였습니다');
          }
        });
      }
    } else {
      setStep(props.location?.state?.step || 1);
    }

    dispatch(
      set_header_type(0, 2, {
        title: '로그인',
        no_footer: true,
        no_top_btn: true,
      }),
    );
    dispatch(set_footer_type(0));
    dispatch(set_tmp('redirect_url', props.location.state?.redirect_url));

    props.ready();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const socialLogin = (url) => {
    window.location.href = url;
  };

  const onOtherLogin = () => {
    setStep(2);
  };

  const checkValidate = () => {
    return Object.values(is_values_check.current).every((value) => value);
  };

  const createSessionData = (account) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const jsonData = JSON.stringify({
      sessionId: uuidv4().replaceAll('-', ''),
      account: account,
    });
    return encryptData(jsonData, secretKey);
  };

  const onSignIn = async () => {
    Object.values(input_list_ref.current).forEach((el) => {
      el.focus();
      el.blur();
    });

    if (!checkValidate()) {
      return;
    }

    const { code, account } = await AccountModel.signIn({
      email: input_values.current.email,
      password: input_values.current.password,
    });

    if (isMount()) {
      if (code === 200) {
        ReactPixel.trackCustom('이메일 로그인');
        dispatch(stored_account_info(account));
        Cookies.set('ebookAuth', `${account.id}`, { domain: '.bear-u.com', path: '/', expires: 3 });

        AccountModel.bgdCheck().then(({ data }) => {
          if (data?.bgd?.length > 0) {
            const isBgdSessionId = Cookies.get('bgdSessionId');
            if (isBgdSessionId) {
              Cookies.remove('bgdSessionId', { domain: '.bear-u.com', path: '/' });
            }
            const sessionData = createSessionData(account);
            Cookies.set('bgdSessionId', `${sessionData}`, {
              domain: '.bear-u.com',
              path: '/',
              expires: 14,
            });
          }
        });

        const give_coupon_token = JSON.parse(localStorage.getItem('Channel_G_C'));
        if (give_coupon_token) {
          localStorage.removeItem('Channel_G_C');
        }
        if (!account.last_password_change_time) {
          await alert('비밀번호를 변경해주세요.');
          props.history.push({
            pathname: '/user/password/change',
            state: { email: input_values.current.email },
          });
        } else if (moment().diff(moment(account.last_password_change_time), 'days') > 180) {
          await alert('비밀번호를 변경해주세요.');
          props.history.push({
            pathname: '/user/password/change',
            state: { email: input_values.current.password },
          });
        } else {
          if (props.location?.state?.redirect_url) {
            window.location.replace(props.location.state.redirect_url);
          } else {
            props.history.push('/');
          }
        }
      } else if (code === 4505) {
        await alert('탈퇴한 계정입니다.');
      } else if (code === 4506) {
        await alert('이용정지 계정입니다.');
      } else if (code === 4500) {
        await alert('이메일 혹은 비밀번호를 확인해주세요.');
      } else {
        await alert('오류가 발생하였습니다.');
      }
    }
  };

  return (
    <div className={'user-container' + (step === 1 ? ' init-login' : '')}>
      <Helmet>
        <title>로그인</title>
      </Helmet>

      {/* 로그인 화면 */}
      <div className='td-wrapper'>
        {step === 1 && (
          <div className='content'>
            <h3>
              시작이 막막할 때, 베어유에서 배워요!
              <br />
              <span>
                베어유를 보다 쉽고,
                <br />
                간편하게 이용하세요 😄
              </span>
            </h3>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/static/png/common/bearu-anim-img.png`}
              alt=''
            />
            <div className='login-btn step-1'>
              <a
                className='td-btn kko-login-btn'
                onClick={() =>
                  socialLogin(
                    `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`,
                  )
                }
              >
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/common/kakao.png`}
                  alt='카카오 로그인'
                />
                카카오로 3초 만에 시작하기
              </a>
              <button className='td-btn other-login-way-btn' onClick={onOtherLogin}>
                다른 방법으로 시작하기
              </button>
            </div>
          </div>
        )}

        {/* 다른 방법으로 시작하기 */}
        {step === 2 && (
          <div className='content'>
            <h2 className='common-center-title type3'>로그인</h2>
            <div className='template-input-wrap'>
              <form>
                {/* 이메일 */}
                <EmailInput
                  input_list_ref={(el) => (input_list_ref.current.email = el)}
                  require={false}
                  auto_focus='autoFocus'
                  on_data_changed={(text, validated) => {
                    if (validated) {
                      input_values.current.email = text;
                      is_values_check.current.email = true;
                    } else {
                      input_values.current.email = '';
                      is_values_check.current.email = false;
                    }
                  }}
                />

                {/* 비밀번호 */}
                <InputLabel label={'비밀번호'} />
                <TemplateInput
                  is_error={password_error}
                  error_message={password_error_message}
                  input_props={{
                    placeholder: '영문/숫자/특수문자 포함 8자 이상',
                    type: 'password',
                    ref: (el) => (input_list_ref.current.password = el),
                    onChange: (password) => {
                      input_values.current.password = password;
                    },
                    onFocus: () => {
                      setPasswordError(false);
                      setPasswordErrorMessage('');
                    },
                    onBlur: (password) => {
                      if (password === '') {
                        setPasswordError(true);
                        setPasswordErrorMessage('비밀번호를 입력해주세요');
                      } else {
                        is_values_check.current.password = true;
                      }
                    },
                    onKeyUp: (event) => {
                      if (event.key === 'Enter' || event.code === 'NumpadEnter') {
                        return onSignIn();
                      }
                    },
                  }}
                />
              </form>
            </div>

            <div className='info-find-wrap'>
              <Link to='/user/email/find'>아이디 찾기</Link>
              <Link to='/user/password/find'>비밀번호 찾기</Link>
            </div>

            <div className='login-btn'>
              <input type='submit' value='로그인' className='td-btn' onClick={onSignIn} />
            </div>

            <p>SNS 계정으로 간편하게 로그인하세요</p>

            <div className='login-btn sns'>
              <a
                className='td-btn kko-login-btn'
                onClick={() =>
                  socialLogin(
                    `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`,
                  )
                }
              >
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/png/common/kakao.png`}
                  alt='카카오 로그인'
                />
                카카오로 3초 만에 시작하기
              </a>
            </div>

            <div className='sign-up-induce-wrap'>
              <p>아직 베어유 계정이 없나요?</p>
              <Link to='/user/signup'>회원가입</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(SignIn);
