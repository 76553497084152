import React, { useState } from 'react';
import TemplateInput from '../_template/TemplateInput';
import PropTypes from 'prop-types';
import InputLabel from './InputLabel';
import { withRouter } from 'react-router-dom';

const propTypes = {
  require: PropTypes.bool, // require 표시 여부
  label: PropTypes.string, // label name 변경 시
  default_value: PropTypes.string, // 회원 수정 시 받아올 default value
  auto_focus: PropTypes.string, // input autoFocus
  input_list_ref: PropTypes.func, // input ref
  on_data_changed: PropTypes.func, // text, validated
};

const EmailInput = (props) => {
  const [email_error_message, setEmailErrorMessage] = useState('');
  const [is_email_error, setIsEmailError] = useState(false);
  const require = props?.require ?? true;

  // 유효성 검사
  const checkEmailValidate = (text) => {
    const regex_email = /^[_.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,6}$/i;
    return regex_email.test(text);
  };

  return (
    <div className='email-input-wrap'>
      <InputLabel label={props?.label ?? '이메일 계정'} require={require} />
      <TemplateInput
        is_error={is_email_error}
        error_message={email_error_message}
        on_validate={(text) => {
          let validated = checkEmailValidate(text);
          if (text === '') {
            setIsEmailError(true);
            setEmailErrorMessage('이메일을 입력해주세요');
          } else if (validated) {
            setIsEmailError(false);
            setEmailErrorMessage('');
          } else {
            setIsEmailError(true);
            setEmailErrorMessage('정확한 이메일 주소를 입력해주세요');
          }
        }}
        input_props={{
          placeholder: '이메일 주소를 입력해주세요',
          defaultValue: props?.default_value,
          autoFocus: props?.auto_focus,
          ref: props?.input_list_ref,
          onFocus: () => {
            setIsEmailError(false);
            setEmailErrorMessage('');
          },
          onChange: (text) => {
            props.on_data_changed && props.on_data_changed(text, checkEmailValidate(text));
          },
        }}
      />
    </div>
  );
};

EmailInput.propTypes = propTypes;

export default withRouter(EmailInput);
