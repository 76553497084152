import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { reset_tmp, set_header_type, set_tmp } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux';
import { stored_account_info } from '../../redux/account/action';
import '../../assets/scss/page/my_page_2.scss';
import LazyLoad from 'react-lazyload';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import PopularList2 from '../_common/section/PopularList2';
import MyPageMain from './section/MyPageMain';
import { useMount } from '../../util/BearuHooks';
import { HEADER_TYPE_MOBILE_FIXED, HEADER_TYPE_PC_BASE } from '../_common/section/Header';
import {
  createCategoryInfo,
  MYPAGE_COUPON,
  MYPAGE_COUPON_KO,
  MYPAGE_COURSE,
  MYPAGE_COURSE_KO,
  MYPAGE_EBOOK,
  MYPAGE_FAVORITE,
  MYPAGE_FAVORITE_EBOOK,
  MYPAGE_FAVORITE_KO,
  MYPAGE_SHIPPING,
  MYPAGE_SHIPPING_KO,
} from '../_collections/mypage';
import AccountModel from '../../model/AccountModel';
import Cookies from 'js-cookie';

export const MY_COURSE_FILTER_ALL = 'all';
export const MY_COURSE_FILTER_ON_GOING = 'on-going';
export const MY_COURSE_FILTER_EXPIRED = 'expired';

const MyPage = (props) => {
  const [title, setTitle] = useState('마이페이지');
  const [isMount] = useMount();

  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 767px)' });
  const dispatch = useDispatch();

  const selected_category = props.match.params.category;
  const selected_category_info = createCategoryInfo();

  const checkValidation = async () => {
    try {
      let { code, account } = await AccountModel.validate({ access_token: null });
      if (isMount()) {
        dispatch(set_tmp('account_validate_count'));
        if (code === 200) {
          dispatch(stored_account_info(account));
        } else {
          doRequireLogin();
        }
      }
    } catch (e) {
      doRequireLogin();
    }
  };

  const doRequireLogin = () => {
    dispatch(reset_tmp());
    dispatch(stored_account_info(null));
    props.history.push({
      pathname: '/user/signin',
      state: {
        step: 1,
        redirect_url: `${props.location.pathname}${props.location.search}`,
      },
    });
  };

  useEffect(() => {
    checkValidation();
  }, []);

  useEffect(() => {
    if (props.match.params.category === MYPAGE_COURSE) {
      setTitle(MYPAGE_COURSE_KO);
    } else if (props.match.params.category === MYPAGE_FAVORITE) {
      setTitle(MYPAGE_FAVORITE_KO);
    } else if (props.match.params.category === MYPAGE_EBOOK) {
      setTitle('내 전자책');
    } else if (props.match.params.category === MYPAGE_FAVORITE_EBOOK) {
      setTitle('저장한 전자책');
    } else if (props.match.params.category === MYPAGE_COUPON) {
      setTitle(MYPAGE_COUPON_KO);
    } else if (props.match.params.category === MYPAGE_SHIPPING) {
      setTitle(MYPAGE_SHIPPING_KO);
    }
    props.ready();
  }, [props.match.params.category]);

  useEffect(() => {
    dispatch(
      set_header_type(
        HEADER_TYPE_PC_BASE,
        HEADER_TYPE_MOBILE_FIXED,
        is_tablet_or_mobile
          ? {
              no_footer: true,
              no_top_btn: true,
              no_channel_talk_button: true,
              title: title,
            }
          : null,
      ),
    );

    // PC일 때 '/user/mypage/' 접속하면 '/user/mypage/course'로 이동
    if (!is_tablet_or_mobile && !selected_category) {
      props.history.replace('/user/mypage/course');
    }
  }, [is_tablet_or_mobile]);

  return (
    <div className='my-page-wrap'>
      <Helmet>{title && <title>{title}</title>}</Helmet>

      <div className={`my-page ${is_tablet_or_mobile ? 'mobile' : 'pc'}`}>
        <>
          {!is_tablet_or_mobile || (!selected_category && is_tablet_or_mobile) ? (
            // 모바일일 때 마이페이지 메인, 웹일 땐 사이드 바
            <div className={`${is_tablet_or_mobile ? '' : 'side-bar'}`}>
              <MyPageMain
                is_side_bar={!is_tablet_or_mobile}
                is_modal={false}
                selected_category={selected_category}
              />
            </div>
          ) : (
            // 모바일일 때 헤드 내비
            <div className='category-select-nav-wrap'>
              <div className='category-select-nav'>
                <Link to='/user/mypage'>
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/ic_arrow_down_b.png`}
                    alt='홈 버튼'
                  />
                </Link>
                {selected_category_info.map((category, index) => (
                  <Link
                    className={`category ${selected_category === category.pathname ? 'selected' : ''}`}
                    to={`/user/mypage/${category.pathname}`}
                    key={index}
                  >
                    {category.name}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {/*카테고리 컨텐츠 영역*/}
          <div className='category-contents-container'>
            {/*카테고리 영역 맵핑*/}
            {selected_category_info.map(
              (category, index) =>
                selected_category === category.pathname && (
                  <category.component {...category.component_props} key={index} />
                ),
            )}
          </div>
        </>
      </div>

      {/*지금 인기 클래스*/}
      <div className='popular-class-wrap'>
        <div className='td-wrapper-v2'>
          <LazyLoad height={0} offset={0}>
            <PopularList2 list_type={1} with_product_set={true} promotion_type_id={2} />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default MyPage;
