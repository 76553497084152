import React, { useEffect, useRef, useState } from 'react';
import CourseModel from '../../../model/CourseModel';
import '../../../assets/scss/component/_qna_container.scss';
import Qna from '../component/Qna';
import { useSelector } from 'react-redux';
import QnaTimeInputs from '../component/QnaTimeInputs';
import PropTypes from 'prop-types';
import LoadingSpinner from '../component/LoadingSpinner';
import { useMediaQuery } from 'react-responsive';
import { useDialogContext } from '../dialog/DialogContextProvider';
import { moveRefToTop } from '../../course/CourseDetail';

const propTypes = {
  course_id: PropTypes.string,
  subject_index: PropTypes.string,
  subject_id: PropTypes.string,
  getPlayedTime: PropTypes.func,
  getMaxDuration: PropTypes.func,
  visible_question_time_input: PropTypes.bool,
  page_size: PropTypes.number,
  resetSwiperHeight: PropTypes.func,
  default_textarea_height: PropTypes.number,
  onSecChange: PropTypes.func,
  is_mine: PropTypes.bool,
};

const QnaContainer = (props) => {
  const [qna_list, setQnaList] = useState(null);
  const [visible_more_button, setVisibleMoreButton] = useState(true);
  const [question_time, setQuestionTime] = useState(0);
  const [is_request_loading, setIsRequestLoading] = useState(false);

  //Dom ref
  const new_qna_textarea_ref = useRef(null);
  const qna_wrap_ref = useRef(null);

  //ref
  const is_writing_qna = useRef(false);
  const comment = useRef('');

  const AccountStore = useSelector((state) => state.AccountStore);
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 765px)' });
  const { alert } = useDialogContext();

  let visible_question_time_input = props.visible_question_time_input ?? false;
  let input_placeholder = props.input_placeholder || '내용을 입력하세요.';
  let qna_size = props.qna_size || 10;
  let default_textarea_height = props.default_textarea_height || 0;

  useEffect(() => {
    if (props.resetSwiperHeight && is_tablet_or_mobile) {
      props.resetSwiperHeight();
    }
  }, [qna_wrap_ref.current?.scrollHeight]);

  useEffect(async () => {
    await requestNextPageQnaList(true);
  }, [props.course_id]);

  useEffect(async () => {
    resetSizeTextarea(new_qna_textarea_ref, default_textarea_height);
  }, [is_tablet_or_mobile]);

  const requestNextPageQnaList = async (is_reset = false) => {
    let last_visible_id = qna_list === null ? null : qna_list[qna_list?.length - 1].id;

    let { code, qna_set } = await CourseModel.courseQNAList({
      course_id: props.course_id,
      size: qna_size,
      parent_id: null,
      last_visible_id: last_visible_id,
    });

    if (code === 200) {
      let new_qna_list = is_reset ? [] : qna_list;

      setVisibleMoreButton(qna_set.length >= qna_size);
      setQnaList([...new_qna_list, ...qna_set]);
      setIsRequestLoading(false);
    } else {
      alert('댓글을 불러오지 못했습니다.ㅠ');
    }
  };

  const writeQna = async () => {
    let { code, qna } = await CourseModel.courseQNAWrite({
      course_id: props.course_id,
      content: comment.current.replace(/(\n\n|\n\n\n|\n\n\n\n)/gm, '\n'),
      question_video_second: question_time,
      subject_id: props.subject_id,
    });

    if (code === 200) {
      comment.current = '';
      new_qna_textarea_ref.current.value = '';
      is_writing_qna.current = false;

      resetSizeTextarea(new_qna_textarea_ref);

      setQnaList([qna, ...qna_list]);
      setQuestionTime(0);
    } else {
      alert('댓글 등록에 실패했습니다 ㅠㅠ');
    }
  };

  const createQnaList = (qna) => {
    let user_nickname = qna.account_nickname;

    if (qna.account_type_id === 5) {
      user_nickname = '관리자';
    } else if (!qna.account_nickname) {
      user_nickname = '익명의 수강생';
    }

    return (
      <Qna
        qna={qna}
        key={qna.id}
        user_nickname={user_nickname}
        is_mine={props.is_mine}
        getPlayedTime={props.getPlayedTime}
        resetSwiperHeight={props.resetSwiperHeight}
        onSecChange={props.onSecChange && props.onSecChange}
        is_viewer_ver={props.is_viewer_ver}
        visible_edit_buttons={
          AccountStore !== null && //로그인한 사람만
          qna.reply_status_id < 3 && // 삭제되지 않은 댓글만
          (AccountStore.id === qna.account_id || AccountStore.account_type_id === 5) //작성자, 관리자만
        }
      />
    );
  };

  const createSubmitButton = () => {
    return (
      <button
        className='submit-save-btn'
        disabled={AccountStore === null}
        onClick={async () => {
          if (!comment.current) {
            alert('질문을 작성해주세요');
          } else if (!is_writing_qna.current) {
            is_writing_qna.current = true;
            await writeQna();
          }
        }}
      >
        등록
      </button>
    );
  };

  return (
    <div className='reply-wrap' ref={qna_wrap_ref}>
      {props.visible_top_row ? (
        <div className='top-row'>
          <p>Q&A</p>
        </div>
      ) : null}

      {/*댓글 입력 영역 start*/}
      <div className='content textarea-wrap'>
        <div className='writing-area'>
          {!is_tablet_or_mobile && AccountStore !== null ? (
            <div className='pc-profile'>
              <ProfileImage
                img_src={AccountStore?.info?.picture_thumbnail_image_url}
                auth_check={AccountStore}
              />
            </div>
          ) : null}

          <div className='main-column-wrap'>
            {AccountStore !== null ? (
              <div className='profile-info-row write-reply'>
                <ProfileImage
                  img_src={AccountStore?.info?.picture_thumbnail_image_url}
                  auth_check={AccountStore}
                />
                {visible_question_time_input ? (
                  <QnaTimeInputs
                    subject_index={props.subject_index}
                    getPlayedTime={props.getPlayedTime}
                    played_time={props.played_time}
                    getMaxDuration={props.getMaxDuration}
                    next_focus_ref={new_qna_textarea_ref}
                    question_time={question_time}
                    setQuestionTime={setQuestionTime}
                  />
                ) : (
                  <h4>
                    {AccountStore?.account_type_id === 5
                      ? '관리자'
                      : AccountStore?.info?.nickname || '익명의 수강생'}
                  </h4>
                )}
              </div>
            ) : null}

            <span>
              <textarea
                placeholder={
                  AccountStore === null ? '로그인 후 작성이 가능합니다' : input_placeholder
                }
                maxLength={300}
                onKeyDown={(e) => ableSelectAll(e)}
                ref={new_qna_textarea_ref}
                disabled={AccountStore === null}
                onFocus={() => {
                  if (is_tablet_or_mobile) {
                    moveRefToTop(new_qna_textarea_ref, props.is_viewer_ver ? 330 : 0);
                  }
                }}
                onChange={(e) => {
                  comment.current = e.target.value;
                  resetSizeTextarea(new_qna_textarea_ref, default_textarea_height);
                  props.resetSwiperHeight && props.resetSwiperHeight();
                }}
              />

              {is_tablet_or_mobile ? <>{createSubmitButton()}</> : null}
            </span>
          </div>
        </div>
        {is_tablet_or_mobile ? null : <>{createSubmitButton()}</>}
      </div>
      {/*댓글 입력 영역 end*/}

      {/*댓글 표시 영역 start*/}
      {qna_list === null ? (
        <LoadingSpinner />
      ) : (
        <>
          {qna_list.length === 0 ? (
            <div className='empty-list'>
              <p>아직 등록된 질문이 없어요</p>
            </div>
          ) : (
            <>
              {qna_list.map((qna) => {
                return createQnaList(qna);
              })}
              {visible_more_button ? (
                <div className='more-button-wrap'>
                  {is_request_loading ? (
                    <LoadingSpinner />
                  ) : (
                    <button
                      onClick={async () => {
                        if (!is_request_loading) {
                          setIsRequestLoading(true);
                          await requestNextPageQnaList();
                        }
                      }}
                    >
                      댓글 더보기
                    </button>
                  )}
                </div>
              ) : null}
            </>
          )}
        </>
      )}
      {/*댓글 표시 영역 end*/}
    </div>
  );
};
QnaContainer.propTypes = propTypes;

export default QnaContainer;

export const resetSizeTextarea = (ref, default_height = 0) => {
  if (ref.current) {
    ref.current.style.height = default_height + 'px';
    ref.current.style.height = 2 + ref.current.scrollHeight + 'px';
  }
};

export const ableSelectAll = (e) => {
  if (e.keyCode === 65 && e.metaKey) {
    e.target.select();
  }
};

export const ProfileImage = ({ img_src, auth_check }) => {
  let is_master = false;
  let is_manager = false;
  let is_author = false;
  if (auth_check.account_type_id === 2) {
    is_master = true;
  } else if (auth_check.account_type_id === 5) {
    is_manager = true;
  } else if (auth_check.account_type_id === 6) {
    is_author = true;
  }

  return (
    <div className={`profile ${is_master ? 'master' : ''} ${is_author ? 'author' : ''}`}>
      <img
        className='profile-img'
        src={
          is_manager || !img_src
            ? `${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/logo_bear_m3.png`
            : img_src
        }
        alt='프로필이미지'
      />
      {is_master && (
        <img
          className='master-badge'
          alt='마스터 뱃지'
          src={`${process.env.REACT_APP_IMG_URL}/static/png/common/badge-master.png`}
        />
      )}
      {is_author && (
        <img
          className='author-badge'
          alt='작가 뱃지'
          src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/common/badge-author.svg`}
        />
      )}
    </div>
  );
};
