import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  input_props: PropTypes.object, // input attributes
  is_error: PropTypes.bool, // 유효성 검사 에러
  error_message: PropTypes.string, // 인풋창 하단 에러 메세지
};

const TemplateInput = (props) => {
  const worker = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(worker.current);
    };
  }, []);

  return (
    <>
      <div className='input-wrap'>
        <input
          style={props.is_error ? { borderColor: '#ff2828' } : { borderColor: '#d8d8d8' }}
          {...props.input_props}
          onBlur={(event) => {
            worker.current = setTimeout(() => {
              props.on_validate && props.on_validate(event.target.value);
            }, 150);
            props.input_props?.onBlur && props.input_props?.onBlur(event.target.value);
          }}
          onChange={(event) => {
            props.input_props?.onChange && props.input_props?.onChange(event.target.value);
          }}
          onPaste={(event) => {
            props.input_props?.onPaste && props.input_props.onPaste(event);
          }}
        />

        <p className={'default-msg' + (props.is_error ? '' : ' success')}>{props.error_message}</p>
      </div>
    </>
  );
};

TemplateInput.propTypes = propTypes;

export default TemplateInput;
