import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../../assets/scss/page/email_id_find.scss';
import AccountModel from '../../model/AccountModel';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch } from 'react-redux';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import NameInput from '../_common/Input/NameInput';
import PhoneInput from '../_common/Input/PhoneInput';
import BirthInput from '../_common/Input/BirthInput';
import { useMount } from '../../util/BearuHooks';

const EmailIdFind = (props) => {
  const dispatch = useDispatch();
  const { alert } = useDialogContext();
  const [isMount] = useMount();

  const [find_email, setFindEmail] = useState(null);
  const [step, setStep] = useState(1);

  const input_values = useRef({
    name: '',
    phone: '',
    birth: '',
  });

  const input_list_ref = useRef({
    name: null,
    phone: null,
    birth: null,
  });

  const is_values_check = useRef({
    name: false,
    phone: false,
    birth: false,
  });

  useEffect(() => {
    dispatch(
      set_header_type(0, 2, {
        title: '아이디 찾기',
        no_footer: true,
        no_top_btn: true,
      }),
    );
    dispatch(set_footer_type(0));
    props.ready();
  }, []);

  const checkValidate = () => {
    return Object.values(is_values_check.current).every((value) => value);
  };

  const onAccountValidate = async () => {
    if (!checkValidate()) {
      Object.values(input_list_ref.current).forEach((el) => {
        el.focus();
        el.blur();
      });
      return;
    }

    let { code, email } = await AccountModel.emailFind({
      name: input_values.current.name,
      birth: input_values.current.birth,
      phone: input_values.current.phone.replace(/-/g, ''),
    });

    if (isMount()) {
      if (code === 200) {
        setFindEmail(email);
        setStep(2);
      } else if (code === 4505) {
        await alert('탈퇴한 계정입니다.');
      } else if (code === 4506) {
        await alert('이용정지 계정입니다.');
      } else {
        await alert('해당정보의 회원을 찾을 수 없습니다.');
      }
    }
  };

  return (
    <div className='user-container login id-find-container'>
      <Helmet>
        <title>아이디 찾기</title>
      </Helmet>

      <div className='td-wrapper'>
        <div className='content'>
          <h2 className='common-center-title type1'>아이디 찾기</h2>

          {step === 1 && (
            <>
              <p className='desc'>
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/icon-information-outline.svg`}
                  alt='가입 시 입력하신 정보를 정확히 입력 해주세요. (베어유 이메일 계정만 찾을 수 있습니다)'
                />
                가입 시 입력하신 정보를 정확히 입력해주세요.
                <br />
                (베어유 이메일 계정만 찾을 수 있습니다)
              </p>

              {/* template-input */}
              <div className='template-input-wrap'>
                <NameInput
                  input_list_ref={(el) => (input_list_ref.current.name = el)}
                  require={false}
                  auto_focus='autoFocus'
                  on_data_changed={(text, validated) => {
                    if (validated) {
                      input_values.current.name = text;
                      is_values_check.current.name = true;
                    } else {
                      input_values.current.name = '';
                      is_values_check.current.name = false;
                    }
                  }}
                />

                <PhoneInput
                  input_list_ref={(el) => (input_list_ref.current.phone = el)}
                  require={false}
                  on_data_changed={(text, validated) => {
                    if (validated) {
                      input_values.current.phone = text;
                      is_values_check.current.phone = true;
                    } else {
                      input_values.current.phone = '';
                      is_values_check.current.phone = false;
                    }
                  }}
                />

                <BirthInput
                  input_list_ref={(el) => (input_list_ref.current.birth = el)}
                  require={false}
                  on_data_changed={(text, validated) => {
                    if (validated) {
                      input_values.current.birth = text;
                      is_values_check.current.birth = true;
                    } else {
                      input_values.current.birth = '';
                      is_values_check.current.birth = false;
                    }
                  }}
                />
              </div>

              <div className='td-btn submit-btn' onClick={onAccountValidate}>
                확인
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <p className='result-id-find'>
                가입하신 이메일 계정은
                <br />
                {find_email} 입니다.
              </p>

              <div
                className='td-btn'
                onClick={() => {
                  props.history.push({
                    pathname: '/user/signin',
                    state: { step: 2 },
                  });
                }}
              >
                로그인하러 가기
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailIdFind;
