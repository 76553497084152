import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import PopularList from '../_common/section/PopularList';
import SubBanner from '../_common/component/SubBanner';
import ProductModel from '../../model/ProductModel';
import { useObserver } from '../../util/BearuHooks';
import update from 'react-addons-update';
import CommonModel from '../../model/CommonModel';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux';
import KitInvoice from './component/KitInvoice';
import {
  alert_and_redirct,
  init_comma,
  replace_cloudfront_url,
  set_favorite_icon,
} from '../../common';
import { useMediaQuery } from 'react-responsive';
import { set_account_favorite_count } from '../../redux/account/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';

const KitDetail = (props) => {
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [kit_set, setKitSet] = useState([]);
  const [is_sticky, setIsSticky] = useState(false);
  const [_is_enable, setIsEnable] = useState(false);
  const _is_mount = useRef(false);
  const fixed_content_ref = useRef(null);
  const [tag_set, setTagSet] = useState([]);
  const dispatch = useDispatch();
  const [is_purchase, setIsPurchase] = useState(false);
  const AccountStore = useSelector((state) => state.AccountStore);
  const { confirm, alert } = useDialogContext();

  const fixed_on_ref = useObserver(
    {},
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: false,
        }),
      );
      setIsEnable(false);
    },
    () => {
      setIsSticky((is_sticky) =>
        update(is_sticky, {
          $set: true,
        }),
      );
      setIsEnable(true);
    },
    [],
  );

  useEffect(() => {
    _is_mount.current = true;
    let append_kit_id = new URLSearchParams(props.location.search).get('append_kit_id');
    ProductModel.kitDetail({
      kit_id: props.match.params.id,
      append_kit_id,
    }).then(({ code, kit, append_kit_set }) => {
      if (_is_mount.current) {
        if (code === 200) {
          if (append_kit_set) {
            setKitSet([kit].concat(append_kit_set));
          } else {
            setKitSet([kit]);
          }
        } else {
          alert_and_redirct(
            alert,
            props.history,
            '구매가 불가능한 조건의 상품이 포함되어 있습니다.',
          );
        }
        props.ready();
      }
    });

    CommonModel.tagList({ page: 1, size: 7 }).then(({ tag_set }) => {
      if (_is_mount.current) {
        setTagSet(tag_set);
      }
    });

    return () => {
      _is_mount.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch(
      set_header_type(0, 2, {
        title: kit_set.length ? kit_set[0].title : null,
      }),
    );
    dispatch(set_footer_type(0));
  }, [kit_set]);

  useEffect(() => {
    if (is_purchase) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'overlay';
    };
  }, [is_purchase]);
  const _favorite_pending = useRef(false);
  const onFavorite = async () => {
    if (AccountStore) {
      if (!_favorite_pending.current) {
        _favorite_pending.current = true;
        ProductModel.kitFavorite({
          kit_id: kit_set[0]?.id,
        }).then(({ is_favorite, favorite_count, account_favorite_count }) => {
          dispatch(set_account_favorite_count(account_favorite_count));
          set_favorite_icon('kit', kit_set[0]?.id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  return (
    <div className='td-container detail-container' style={{ overflowAnchor: 'none' }}>
      <input name='courseId' type='hidden' value='' />
      <Helmet>
        <title>{kit_set[0]?.title}</title>
      </Helmet>
      {!is_tablet_or_mobile && (
        <div className='detail-top-blur-img-wrap'>
          <div
            style={
              kit_set[0]?.main_image_url
                ? {
                    background: `url(${replace_cloudfront_url(kit_set[0]?.main_image_url)}) no-repeat center 10%/cover`,
                  }
                : {
                    background: `url("${process.env.REACT_APP_IMG_URL}/static/png/common/img_bearu_kit_placeholder.png") no-repeat center 10%/cover`,
                  }
            }
          />
        </div>
      )}
      <div className='content-wrap'>
        {!is_tablet_or_mobile && kit_set.length ? <h2>{kit_set[0].title}</h2> : null}
        {is_tablet_or_mobile && kit_set.length ? (
          <>
            <div
              className='detail-top-preview'
              style={
                kit_set[0].main_image_url
                  ? {
                      background: `url(${replace_cloudfront_url(kit_set[0].main_image_url)}) no-repeat center top/cover`,
                    }
                  : {
                      background: `url("${process.env.REACT_APP_IMG_URL}/static/png/common/img_bearu_kit_placeholder.png") no-repeat center top/cover`,
                    }
              }
            />
            <div className='mo-detail-header-wrap'>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/label_product.png`}
                alt='키트 상품'
              />
              <h2>{kit_set[0].title}</h2>
              <p>{kit_set[0].description}</p>
              <div className='course-price-promotion-wrap'>
                <div className='month-price-box'>
                  <h4>{`${init_comma(kit_set[0].price_info[0].our_sale_price)}원`}</h4>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className='td-wrapper'>
          <div className='detail-content'>
            {!is_tablet_or_mobile && (
              <div
                className='detail-top-preview'
                style={
                  kit_set[0]?.main_image_url
                    ? {
                        background: `url(${kit_set[0]?.main_image_url}) no-repeat center top/cover`,
                      }
                    : {
                        background: `url("${process.env.REACT_APP_IMG_URL}/static/png/common/img_bearu_kit_placeholder.png") no-repeat center top/cover`,
                      }
                }
              />
            )}
            <div className='course-info-wrap'>
              <p>{kit_set[0]?.description}</p>
              {kit_set[0]?.content_html ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: kit_set[0]?.content_html,
                  }}
                />
              ) : (
                <img
                  alt='kit_thumb'
                  src={`${process.env.REACT_APP_IMG_URL}/assets/%E1%84%8F%E1%85%B5%E1%84%90%E1%85%B3_%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB.jpg`}
                />
              )}
            </div>
            <div className='detail-popular-slide-wrap'>
              {/*<LazyLoad>*/}
              <PopularList list_type={1} />
              {/*</LazyLoad>*/}
            </div>
          </div>
          <div
            className='pc-visible'
            style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '1px' }}
            ref={fixed_on_ref}
          />
          <div
            className='mo-purchase-btn-wrap gtm-detail gtm-data'
            data-ai-pnm={kit_set[0]?.title.replace(/\r\n/g, ' ')}
            data-ai-pid={`k${kit_set[0]?.id.toString().padStart(8, '0')}`}
            data-ai-ppc={kit_set[0]?.price_info.map((price) => price.our_sale_price)}
            data-ai-ppb=''
            data-ai-pca={'옵션상품'}
            style={is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
          >
            <div className='icon-wrap'>
              <img
                className={`kit-detail-id-${kit_set[0]?.id} ${kit_set[0]?.is_favorite ? '' : ' enp_mobon_wish'}`}
                src={
                  kit_set[0]?.is_favorite
                    ? `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detailpage-active-2.png`
                    : `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detail-2.png`
                }
                alt='저장하기'
                onClick={onFavorite}
              />
            </div>
            <button
              className='td-btn'
              onClick={() => {
                setIsPurchase(true);
              }}
            >
              구매하기
            </button>
          </div>
          <div
            className={
              'fixed-content td-scroll' +
              (!is_tablet_or_mobile && is_sticky
                ? ' sticky'
                : is_purchase
                  ? ' is-purchase-active'
                  : '')
            }
            ref={fixed_content_ref}
            style={
              !is_tablet_or_mobile && _is_enable
                ? { height: '100vh', overflowY: 'auto' }
                : {
                    height: 'inherit',
                    overflowY: 'hidden',
                  }
            }
          >
            {is_purchase && (
              <div
                className='dimmed-layer'
                onClick={() => {
                  if (is_tablet_or_mobile) setIsPurchase(false);
                }}
              />
            )}
            {kit_set && (
              <KitInvoice
                kit_info_set={kit_set}
                invoice_type={1}
                setIsPurchase={setIsPurchase}
                onFavorite={onFavorite}
              />
            )}

            {!is_tablet_or_mobile && (
              <>
                <div className='popular-keyword-wrap'>
                  <h4 className='keyword-title'>실시간 인기 키워드</h4>
                  <ul>
                    {tag_set.map((tag, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            props.history.push(`/search?q=${tag}`);
                          }}
                        >
                          #{tag}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <LazyLoad>
                  <SubBanner />
                </LazyLoad>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitDetail;
