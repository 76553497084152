import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import LazyLoad from 'react-lazyload';
import ReactPlayer from 'react-player/youtube';
import update from 'react-addons-update';

import CourseModel from '../../model/CourseModel';
import { set_footer_type, set_header_type, set_tmp } from '../../redux/common/action';
import '../../assets/scss/page/detail.scss';
import { useInterval, useObserver } from '../../util/BearuHooks';
import SubBanner from '../_common/component/SubBanner';
import CommonModel from '../../model/CommonModel';
import PixelModel from '../../model/PixelModel';
import CourseInvoice from './component/CourseInvoice';
import {
  alert_and_redirct,
  init_comma,
  is_string_empty,
  replace_cloudfront_url,
  set_favorite_icon,
} from '../../common';
import { set_account_favorite_count } from '../../redux/account/action';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import PopularList2 from '../_common/section/PopularList2';
import QnaContainer from '../_common/section/QnaContainer';
import ReviewContainer from '../_common/section/ReviewContainer';
import RefundPolicyForProductDetail from '../_common/RefundPolicyForProductDetail';
import PurchaseModel from '../../model/PurchaseModel';

// 결제 네비 컴포넌트로 만들기
// kit-> is_default == 1 => 미리 선택하기
// inventory_item = 0 => 디스에이블해보리기
// single_kit = true/false -> 키트를 하나만 선택할 여부
// require_kit = true/false -> is_default == 1 키트 선택해제 불가
const CourseDetail = (props) => {
  const is_tablet_or_mobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();
  const AccountStore = useSelector((state) => state.AccountStore);
  const { confirm, alert } = useDialogContext();

  const [course, setCourse] = useState(null);
  const [event, setEvent] = useState(null);
  const [tag_set, setTagSet] = useState([]);
  const [is_sticky, setIsSticky] = useState({
    nav_sticky: false,
    invoice_sticky: false,
  });
  const [_is_view, setIsView] = useState([false, false, false, false]);
  const [_is_enable, setIsEnable] = useState(false);
  const [subject_set, setSubjectSet] = useState([]);
  const [expire_time, setExpireTime] = useState(null);
  const [discount_set, setDiscountSet] = useState([]);
  const [purchase_price, setPurchasePrice] = useState(null);
  const [is_purchase, setIsPurchase] = useState(false);
  const [selected_sale, setSelectedSale] = useState(null);

  const _is_mount = useRef(false);
  const fixed_content_ref = useRef(null);
  const error_message = useRef(null);
  const _favorite_pending = useRef(false);

  const [todayStart, setTodayStart] = useState('');
  const [todayEnd, setTodayEnd] = useState('');

  const nav_set = [
    { name: '강의소개' },
    { name: '커리큘럼' },
    { name: '수강후기' },
    { name: 'Q&A' },
  ];

  const nav_ref = useObserver(
    {},
    () => {
      setIsSticky((state) =>
        update(state, {
          nav_sticky: { $set: false },
        }),
      );
    },
    () => {
      setIsSticky((state) =>
        update(state, {
          nav_sticky: { $set: true },
        }),
      );
    },
    [],
  );

  const fixed_on_ref = useObserver(
    {},
    () => {
      setIsSticky((state) =>
        update(state, {
          invoice_sticky: { $set: false },
        }),
      );
      setIsEnable(false);
    },
    () => {
      setIsSticky((state) =>
        update(state, {
          invoice_sticky: { $set: true },
        }),
      );
      setIsEnable(true);
    },
    [],
  );

  const info_ref = useObserver(
    {},
    () => {
      setIsView((state) =>
        update(state, {
          [0]: { $set: true },
        }),
      );
    },
    () => {
      setIsView((state) =>
        update(state, {
          [0]: { $set: false },
        }),
      );
    },
    [],
  );

  const curriculum_ref = useObserver(
    {},
    () => {
      setIsView((state) =>
        update(state, {
          [1]: { $set: true },
        }),
      );
    },
    () => {
      setIsView((state) =>
        update(state, {
          [1]: { $set: false },
        }),
      );
    },
    [],
  );

  const review_ref = useObserver(
    {},
    () => {
      setIsView((state) =>
        update(state, {
          [2]: { $set: true },
        }),
      );
    },
    () => {
      setIsView((state) =>
        update(state, {
          [2]: { $set: false },
        }),
      );
    },
    [],
  );

  const qna_ref = useObserver(
    {},
    () => {
      setIsView((state) =>
        update(state, {
          [3]: { $set: true },
        }),
      );
    },
    () => {
      setIsView((state) =>
        update(state, {
          [3]: { $set: false },
        }),
      );
    },
    [],
  );

  useInterval(
    () => {
      let today = moment();
      if (event && event.event_code) {
        let tmp_expire_time = moment(event.expire_time).diff(today);
        let day = Math.floor(moment.duration(tmp_expire_time).asDays());
        let hour = moment.duration(tmp_expire_time).hours().toString().padStart(2, '0');
        let minute = moment.duration(tmp_expire_time).minutes().toString().padStart(2, '0');
        let second = moment.duration(tmp_expire_time).seconds().toString().padStart(2, '0');

        if (day <= 0 && hour <= 0 && minute <= 0 && second <= 0) {
          alert_and_redirct(
            alert,
            props.history,
            '이벤트가 마감 되었습니다.',
            `/course/${course.id}`,
          );
        } else {
          setExpireTime({ day, hour, minute, second });
        }
      }
    },
    event && course ? 1000 : null,
  );

  useEffect(() => {
    _is_mount.current = true;
    return () => {
      _is_mount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!course) return;
    PixelModel.postConversionAPI({
      eventName: 'ViewContent',
      AccountStore: AccountStore,
      customDataOptions: {
        content_name: `[${course.short_title}] ${course.title}`,
      },
    });
  }, [course]);

  useEffect(() => {
    let event_code = new URLSearchParams(props.location.search).get('event_code');
    CourseModel.courseDetail({ course_id: props.match.params.id, event_code }).then(
      ({ code, course, event }) => {
        if (_is_mount.current) {
          if (code === 200) {
            error_message.current = event?.event_msg;
            setEvent(event);

            if (event) {
              if (event?.event_msg === '존재하지 않는 이벤트입니다.') {
                alert_and_redirct(alert, props.history, event?.event_msg);
              }

              setTodayStart(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
              if (event?.expire_time) {
                setTodayEnd(moment(event?.expire_time).format('YYYY-MM-DD HH:mm:ss'));
              } else {
                setTodayEnd(moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'));
              }
            } else {
              setTodayStart(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
              setTodayEnd(moment().startOf('day').endOf('day').format('YYYY-MM-DD HH:mm:ss'));
            }

            dispatch(
              set_tmp('recent_course_set', {
                id: course.id,
                circle_image_url: replace_cloudfront_url(course.circle_image_url),
                title: course.title,
              }),
            );

            ReactPixel.trackCustom('강의 상세 페이지 조회', {
              course_title: course?.title,
            });

            setCourse(course);
            if (course.subject_set) {
              setSubjectSet(transSubject(course.subject_set));
            }
            if (review_ref.current && qna_ref.current) {
              review_ref.current.style.minHeight = 'inherit';
              qna_ref.current.style.minHeight = 'inherit';
            }
            props.ready();
          } else {
            alert_and_redirct(alert, props.history, '존재하지 않는 강의입니다.');
          }
        }
      },
    );

    CommonModel.tagList({ page: 1, size: 7 }).then(({ tag_set }) => {
      if (_is_mount.current) {
        setTagSet(tag_set);
      }
    });
  }, [props.match.params.id]);

  useEffect(() => {
    dispatch(
      set_header_type(0, 2, {
        title: course && `[${course.short_title}] ${course.title}`,
      }),
    );
    dispatch(set_footer_type(0));
  }, [course]);

  ///mobon script start///
  const [kit_set, setKitSet] = useState([]);
  const [total_price, setTotalPrice] = useState(0);
  const [sale_set, setSaleSet] = useState(null);
  const [purchaseSuccess, setPurchaseSuccess] = useState(0);
  const [jsAppend, setJsAppend] = useState(0);

  useEffect(() => {
    if (course !== null) {
      let sale = course?.sale_info_set[0];
      setSaleSet(sale);

      let validate_kit_set = [];
      for (let i = 0; i < kit_set.length; i++) {
        let check = false;
        for (let j = 0; j < validate_kit_set.length; j++) {
          if (validate_kit_set[j].id === kit_set[i].id) {
            validate_kit_set[j].order_count++;
            check = true;
            break;
          }
        }
        if (!check) {
          validate_kit_set.push({ id: kit_set[i].id, order_count: 1 });
        }
      }

      if (sale_set && course && purchaseSuccess === 0) {
        PurchaseModel.priceValidate({
          currency_code: 'KRW',
          course_sale_info_id: sale_set.id,
          extend_month: null,
          kit_set: validate_kit_set,
          event_code: event?.event_code,
        }).then(async ({ code, discount_set, total_price, purchase_price }) => {
          if (_is_mount.current) {
            if (code === 200) {
              setTotalPrice(total_price);
              setPurchasePrice(purchase_price);
              setDiscountSet(discount_set);
              setPurchaseSuccess(1);
            } else if (code === 4805) {
              await alert('최대 수량 이상 선택할 수 없습니다.');
            } else if (code === 4701) {
              await alert('품절된 상품입니다.');
              setKitSet((prevState) => {
                prevState.pop();
                return [...prevState];
              });
            } else if (code === 4812) {
              alert_and_redirct(
                alert,
                props.history,
                '해당 이벤트는 첫 구매시에만 참여가능합니다.',
                `/course/${course.id}`,
              );
            } else if (code === 4813) {
              alert_and_redirct(
                alert,
                props.history,
                '이미 참여하신 이벤트입니다.',
                `/course/${course.id}`,
              );
            } else {
              await alert('잘못된 요청입니다.');
            }
          }
        });
      }

      const device = 'B';
      if (purchaseSuccess === 1 && jsAppend === 0) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerText =
          'var ENP_VAR = {' +
          '    collect: {},' +
          '    conversion: { product: [] }' +
          '    };' +
          '    ENP_VAR.collect.productCode = "' +
          course.id +
          '";' +
          '    ENP_VAR.collect.productName = "' +
          course.title +
          '";' +
          '    ENP_VAR.collect.price = "' +
          total_price +
          '";' +
          '    ENP_VAR.collect.dcPrice = "' +
          purchase_price +
          '";' +
          "    ENP_VAR.collect.imageUrl = '" +
          course.main_thumbnail_image_url +
          "';" +
          '    ENP_VAR.collect.topCategory = "' +
          course.category_name +
          '";' +
          '    ENP_VAR.conversion.product.push({' +
          '        productCode : "' +
          course.id +
          '",' +
          '        productName : "' +
          course.title +
          '",' +
          '        price : "' +
          total_price +
          '",' +
          '        dcPrice : "' +
          purchase_price +
          '",' +
          "        imageUrl : '" +
          course.main_thumbnail_image_url +
          "'," +
          '        topCategory : "' +
          course.category_name +
          '",' +
          '    });' +
          "(function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src='https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js';t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,\"script\");enp('create', 'collect', 'tendominc', { device: '" +
          device +
          "' });" +
          "enp('create', 'wish', 'tendominc', { device: '" +
          device +
          "', btnSelector: '.course-detail-id-" +
          course?.id +
          "' })";
        document.body.appendChild(script);
        setJsAppend(1);
      }
    }
  }, [course, purchase_price, discount_set]);
  ///mobon script end///

  useEffect(() => {
    if (is_purchase) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'overlay';
    };
  }, [is_purchase]);

  const onRefToScrollClick = (target_index) => {
    if (target_index === 0) {
      window.scrollTo(0, 0);
    } else if (target_index === 1) {
      window.scrollTo(0, curriculum_ref.current.getBoundingClientRect().top + window.scrollY - 60);
    } else if (target_index === 2) {
      window.scrollTo(0, review_ref.current.getBoundingClientRect().top + window.scrollY - 80);
    } else if (target_index === 3) {
      window.scrollTo(0, qna_ref.current.getBoundingClientRect().top + window.scrollY - 80);
    }
  };

  const onKitBannerClick = () => {
    props.history.push(
      `/kit/${course?.kit_info_set[0].id}?append_kit_id=${course?.kit_info_set
        .map((kit) => {
          return kit.id;
        })
        .slice(1, course?.kit_info_set.length)
        .join(',')}`,
    );
  };

  const onFavorite = async () => {
    if (AccountStore) {
      if (!_favorite_pending.current) {
        _favorite_pending.current = true;
        CourseModel.courseFavorite({
          course_id: course.id,
        }).then(({ is_favorite, favorite_count, account_class_favorite_count }) => {
          dispatch(set_account_favorite_count(account_class_favorite_count));
          set_favorite_icon('course', course.id, is_favorite, favorite_count);
          _favorite_pending.current = false;
        });
      }
    } else {
      if (await confirm('로그인이 필요한 서비스입니다.\n로그인 하시겠습니까?')) {
        props.history.push({
          pathname: '/user/signin',
          state: {
            step: 1,
            redirect_url: `${props.location.pathname}${props.location.search}`,
          },
        });
      }
    }
  };

  const getCoverView = (chapter) => {
    let cover_view = null;
    try {
      let subject = chapter.subject_set[0];
      if (!is_string_empty(subject.preview_youtube_id, true)) {
        cover_view = (
          <ReactPlayer
            className='youtube-player'
            url={`https://www.youtube.com/watch?v=${subject.preview_youtube_id}`}
          />
        );
      } else if (!is_string_empty(subject.thumbnail_image_url, true)) {
        cover_view = (
          <img
            src={replace_cloudfront_url(subject.thumbnail_image_url)}
            style={{ width: '100%' }}
            alt=''
          />
        );
      }
    } catch (e) {}
    return cover_view;
  };

  const PurchaseInfo = useCallback(() => {
    if (!selected_sale || !purchase_price) return null;

    if (selected_sale.rental_time_in_day >= 30) {
      return (
        <>
          <div className='month-price-box'>
            <h4>{`월 ${init_comma(Math.floor(purchase_price / (selected_sale?.rental_time_in_day / 30)) || 0)}원`}</h4>
          </div>
          <p>*수강기간 기준 월 금액, 무이자 최대 6개월 가능</p>
        </>
      );
    } else {
      return (
        <>
          <div className='month-price-box'>
            <h4>{`월 ${init_comma(purchase_price)}원`}</h4>
          </div>
        </>
      );
    }
  }, [purchase_price, selected_sale]);

  return (
    <div className='td-container detail-container' style={{ overflowAnchor: 'none' }}>
      <input name='courseId' type='hidden' value={course?.id || ''} />
      <input name='courseTitle' type='hidden' value={course?.title || ''} />
      <input name='isSoldOut' type='hidden' value={0} />

      <Helmet>
        {course && (
          <title>
            [{course.short_title}] {course.title}
          </title>
        )}
      </Helmet>

      <div
        className={'detail-sticky-nav' + (is_sticky.nav_sticky ? ' sticky' : '')}
        ref={nav_ref}
        style={
          is_purchase
            ? { pointerEvents: 'none', opacity: 0 }
            : { pointerEvents: 'inherit', opacity: 1 }
        }
      >
        {course && (
          <ul className='td-wrapper'>
            {nav_set.map((nav, index) => {
              let is_disable = false;
              if (course?.course_sale_status_id === 1 && nav_set.length % index === 0) {
                is_disable = true;
              }
              return (
                <li
                  key={index}
                  style={is_disable ? { opacity: 0.3, pointerEvents: 'none' } : {}}
                  className={index === _is_view.findIndex((ele) => ele) ? ' selected' : ''}
                  onClick={() => {
                    onRefToScrollClick(index);
                  }}
                >
                  {nav.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className='content-wrap'>
        <div className='td-wrapper'>
          <div className='detail-content'>
            <div ref={nav_ref} style={{ paddingBottom: 40 }}>
              {course &&
                (is_tablet_or_mobile ? (
                  <>
                    {course.youtube_id ? (
                      <ReactPlayer
                        className='youtube-player detail-top-preview'
                        url={`https://www.youtube.com/watch?v=${course.youtube_id}`}
                      />
                    ) : (
                      <div
                        className='detail-top-preview'
                        style={{
                          background: `url(${replace_cloudfront_url(
                            course.main_image_url,
                          )}) no-repeat center center/cover`,
                        }}
                      />
                    )}

                    <div className='mo-detail-header-wrap'>
                      {course.course_sale_status_id === 1 ? (
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/label-opensoon.png`}
                          alt='오픈예정 클래스'
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/label-vod.png`}
                          alt={course.short_title + ' 클래스'}
                        />
                      )}
                      <h2>
                        [{course.short_title}] {course.title}
                      </h2>
                      <p>{course.description}</p>
                      <div className='img-group'>
                        {course.include_vod ? (
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/course-ticket-2.png`}
                            alt='수강권'
                          />
                        ) : null}
                        {course.include_secret_note ? (
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/masternote-2.png`}
                            alt='비법노트 제공'
                          />
                        ) : null}
                        {course.include_document ? (
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/course-data-2.png`}
                            alt='강의자료 포함'
                          />
                        ) : null}
                      </div>

                      <div className='course-price-promotion-wrap'>
                        <div className='promotion-text-wrap'>
                          {discount_set.map((discount, index) => {
                            if (discount.title === '프로모션 할인') {
                              return (
                                <div className='mo-promotion-text' key={index}>
                                  <h4>{discount.title}</h4>
                                  <h5>{`-${init_comma(discount.price)}원`}</h5>
                                  <h6>{`${init_comma(Math.floor(purchase_price))}원`}</h6>
                                </div>
                              );
                            } else {
                              return (
                                <div key={index}>
                                  {discount.price !== 0 && (
                                    <div className='mo-time-event-box'>
                                      <span>{discount.title}</span>
                                      <span>{`-${init_comma(discount.price)}원`}</span>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          })}
                        </div>

                        <PurchaseInfo />
                      </div>

                      {course.kit_info_set.length && !course.kit_info_set[0].is_private ? (
                        <LazyLoad>
                          <div
                            className='kit-banner'
                            style={{ marginTop: 24 }}
                            onClick={onKitBannerClick}
                          >
                            <div className='icon-wrap'>
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-option-purchase.png`}
                                alt='키트 배너'
                              />
                            </div>
                            <div className='banner-title'>
                              <h4>
                                <span>지금 이 클래스</span>
                                키트/도서만 구매하기
                              </h4>
                            </div>
                            <img
                              src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/main-right-arrow.svg`}
                              alt='키트페이지로 이동'
                            />
                          </div>
                        </LazyLoad>
                      ) : null}
                    </div>
                  </>
                ) : course.youtube_id ? (
                  <ReactPlayer
                    className='youtube-player detail-top-preview'
                    url={`https://www.youtube.com/watch?v=${course.youtube_id}`}
                  />
                ) : (
                  <div
                    className='detail-top-preview'
                    style={{
                      background: `url(${replace_cloudfront_url(course.main_image_url)}) no-repeat center center/cover`,
                    }}
                  />
                ))}
            </div>

            <ul className='detail-nav'>
              {course && (
                <>
                  {nav_set.map((nav, index) => {
                    let is_disable = false;
                    if (course?.course_sale_status_id === 1 && nav_set.length % index === 0) {
                      is_disable = true;
                    }
                    return (
                      <li
                        key={index}
                        style={is_disable ? { opacity: 0.3, pointerEvents: 'none' } : {}}
                        className={index === 0 ? ' selected' : ''}
                        onClick={() => {
                          onRefToScrollClick(index);
                        }}
                      >
                        {nav.name}
                      </li>
                    );
                  })}
                </>
              )}
            </ul>

            <div className='course-info-wrap' ref={info_ref}>
              <p className='pc-visible'>{course?.description}</p>
              <ul className='course-info-content-wrap'>
                {course?.subject_set?.length ? (
                  <li>
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/detail/ic_video_3.svg`}
                      alt='포함 강좌 갯수'
                    />
                    <span>총 {course?.subject_set?.length}강</span>
                  </li>
                ) : null}

                {selected_sale?.rental_time_in_day ? (
                  <li>
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/detail/ic_month.svg`}
                      alt='대여 기간 정보'
                    />
                    <span>{Math.floor(selected_sale?.rental_time_in_day / 30)}개월 수강</span>
                  </li>
                ) : null}

                {course?.total_running_sec ? (
                  <li>
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}/static/v2/svg/detail/ic_time.svg`}
                      alt='총 강의 수강 시간'
                    />
                    <span>{Math.floor(course?.total_running_sec / 60)}분</span>
                  </li>
                ) : null}
              </ul>

              {course?.course_body?.content_html && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: replace_cloudfront_url(course.course_body.content_html),
                  }}
                />
              )}
            </div>

            <div className='course-curriculum-wrap' ref={curriculum_ref}>
              <h3>커리큘럼</h3>
              <div className='curriculum'>
                {subject_set.map((chapter, index) => {
                  return (
                    <div className='curriculum-item' key={index}>
                      {getCoverView(chapter)}
                      <div className='curriculum-detail'>
                        <h4>{chapter.chapter_description}</h4>
                        <ul>
                          {chapter.subject_set.map((subject, index) => {
                            return (
                              <li key={index}>
                                <div className='chapter-content-number'>{index + 1}</div>
                                <h5>{subject.title}</h5>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='course-review-wrap' ref={review_ref}>
              {course && (
                <ReviewContainer
                  type='course'
                  course_id={props.match.params.id}
                  input_placeholder={
                    course.is_mine ? '클래스 후기를 남겨주세요.' : '수강 후 작성이 가능합니다.'
                  }
                  is_mine={course.is_mine}
                  review_size={5}
                  visible_top_row={true}
                  default_textarea_height={!is_tablet_or_mobile ? 30 : 80}
                />
              )}
            </div>

            <div className='course-qna-wrap' ref={qna_ref}>
              {course && (
                <QnaContainer
                  course_id={props.match.params.id}
                  input_placeholder={`질문을 입력하세요. ${
                    !is_tablet_or_mobile ? '강의 재생 페이지에서도 질문할 수 있어요' : ''
                  }`}
                  qna_size={5}
                  is_mine={course.is_mine}
                  visible_top_row={true}
                  default_textarea_height={!is_tablet_or_mobile ? 30 : 80}
                />
              )}
            </div>

            <RefundPolicyForProductDetail />

            <div className='detail-popular-slide-wrap'>
              <PopularList2 list_type={1} />
            </div>
          </div>

          <div
            className='pc-visible'
            style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '1px' }}
            ref={fixed_on_ref}
          />

          {course && (
            <div
              className='mo-purchase-btn-wrap'
              style={is_tablet_or_mobile ? { display: '' } : { display: 'none' }}
            >
              <div
                className='icon-wrap gtm-detail gtm-data'
                data-ai-pnm={course?.title.replace(/\r\n/g, ' ')}
                data-ai-pid={`c${course?.id.toString().padStart(8, '0')}`}
                data-ai-ppc={course?.min_price}
                data-ai-ppb={course?.brand_name}
                data-ai-pca={course?.category_name}
              >
                <img
                  className={`course-detail-id-${course.id} td-favorite ${
                    course?.is_favorite ? '' : ' enp_mobon_wish'
                  }`}
                  src={
                    course?.is_favorite
                      ? `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detailpage-active-2.png`
                      : `${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-star-detail-2.png`
                  }
                  onClick={onFavorite}
                  alt='저장하기'
                />
              </div>

              {course.course_sale_status_id === 1 ? (
                <button className='coming-soon-btn td-btn'>오픈예정</button>
              ) : !course.is_mine ? (
                <>
                  <button
                    className={'td-btn' + (expire_time ? ' time-evnet' : '')}
                    onClick={(e) => {
                      setIsPurchase(true);
                    }}
                  >
                    {event?.is_display_time && expire_time ? (
                      <>
                        <img
                          className='animate__animated animate__tada'
                          src={`${process.env.REACT_APP_IMG_URL}/static/png/common/icon-thunder%402x.png`}
                          alt='타임특가'
                        />
                        <span>
                          {`${expire_time.day + '일'} ${expire_time.hour}:${expire_time.minute}:${
                            expire_time.second
                          } 수강 신청`}
                          {/* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */}
                          {/*<span className="seat-count">남은자리 <span>{seatCount}</span>건</span>*/}
                        </span>
                      </>
                    ) : (
                      <p className='flex-all-center'>
                        {/* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */}
                        수강 신청{' '}
                        {/*<span className="seat-count">남은자리 <span>{seatCount}</span>건</span>*/}
                      </p>
                    )}
                  </button>
                </>
              ) : (
                <button
                  className='td-btn'
                  onClick={() => {
                    props.history.push(`/course/${course.id}/viewer`);
                  }}
                >
                  강의 바로가기
                </button>
              )}
            </div>
          )}

          <div
            className={
              'fixed-content td-scroll' +
              (!is_tablet_or_mobile && is_sticky.invoice_sticky
                ? ' sticky'
                : is_purchase
                  ? ' is-purchase-active'
                  : '')
            }
            ref={fixed_content_ref}
            style={
              !is_tablet_or_mobile && _is_enable
                ? { height: '100vh', overflowY: 'auto' }
                : {
                    height: 'inherit',
                    overflowY: 'hidden',
                  }
            }
          >
            {is_purchase && (
              <div
                className='dimmed-layer'
                onClick={() => {
                  if (is_tablet_or_mobile) setIsPurchase(false);
                }}
              />
            )}

            {course && (
              <CourseInvoice
                course={course}
                invoice_type={1}
                event={event}
                /* //TODO:  2023-08-11, 11:1 -kwak: 마케팅전략 주석처리 요청 */
                //seat_count={seatCount}
                setIsPurchase={setIsPurchase}
                set_purchase_price={setPurchasePrice}
                set_discount_price={setDiscountSet}
                onFavorite={onFavorite}
                on_selected_sale={(_sale) => {
                  setSelectedSale(_sale);
                }}
                error_message={error_message.current}
                moveToPurchase={() => {
                  props.history.push('/purchase');
                }}
              />
            )}

            {!is_tablet_or_mobile && expire_time && event?.is_display_time ? (
              <div className='event-msg-wrap'>
                <p>
                  <img
                    className='animate__animated animate__tada'
                    src={`${process.env.REACT_APP_IMG_URL}/static/png/common/thunder%402x.png`}
                    alt='타임특가'
                  />
                  {expire_time.day}일 {expire_time.hour}:{expire_time.minute}:{expire_time.second}
                </p>
              </div>
            ) : null}

            {!is_tablet_or_mobile && event?.event_msg ? (
              <div className='event-msg-wrap'>
                <p className='event-finish-text'>{event.event_msg}</p>
              </div>
            ) : null}

            {!is_tablet_or_mobile &&
            course?.kit_info_set.length &&
            !course.kit_info_set[0].is_private ? (
              <LazyLoad>
                <div className='kit-banner' onClick={onKitBannerClick}>
                  <div className='icon-wrap'>
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}/static/png/product_detail/icon-option-purchase.png`}
                      alt='키트 배너'
                    />
                  </div>
                  <div className='banner-title'>
                    <h4>
                      <span>지금 이 클래스</span>
                      키트/도서만 구매하기
                    </h4>
                  </div>
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}/static/svg/common/main-right-arrow.svg`}
                    alt='키트페이지로 이동'
                  />
                </div>
              </LazyLoad>
            ) : null}

            {!is_tablet_or_mobile && (
              <LazyLoad>
                <SubBanner />
              </LazyLoad>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;

export const moveRefToTop = (ref, detail_opt) => {
  window.scrollTo({
    top: ref.current.offsetTop + (detail_opt ? detail_opt : -110),
    behavior: 'smooth',
  });
};

export const transSubject = (trans_subject_set) => {
  let response_subject_set = [];
  for (let i = 0; i < trans_subject_set.length; i++) {
    let is_chapter = true;
    if (i) {
      if (
        trans_subject_set[i - 1].chapter_description === trans_subject_set[i].chapter_description
      ) {
        is_chapter = false;
      }
    }
    if (is_chapter) {
      response_subject_set.push({
        chapter_description: trans_subject_set[i].chapter_description,
        subject_set: [
          {
            index_name: trans_subject_set[i].index_name,
            title: trans_subject_set[i].title,
            image_url: trans_subject_set[i].image_url,
            thumbnail_image_url: trans_subject_set[i].thumbnail_image_url,
            preview_youtube_id: trans_subject_set[i].preview_youtube_id,
          },
        ],
      });
    } else {
      response_subject_set[response_subject_set.length - 1].subject_set.push({
        index_name: trans_subject_set[i].index_name,
        title: trans_subject_set[i].title,
        image_url: trans_subject_set[i].image_url,
        thumbnail_image_url: trans_subject_set[i].thumbnail_image_url,
        preview_youtube_id: trans_subject_set[i].preview_youtube_id,
      });
    }
  }

  return response_subject_set;
};
