import React, { useEffect, useState } from 'react';
import { alert_and_redirct, replace_cloudfront_url } from '../../common';
import '../../assets/scss/page/time_event.scss';
import EventModel from '../../model/EventModel';
import { set_footer_type, set_header_type, stored_static_code } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';
import { useMount } from '../../util/BearuHooks';
import PackageCard2 from '../_common/component/PackageCard2';
import CourseCard2 from '../_common/component/CourseCard2';
import CommonModel from '../../model/CommonModel';

const TimeEventForTm = (props) => {
  const dispatch = useDispatch();
  const { alert } = useDialogContext();
  const [isMount] = useMount();
  const AccountStore = useSelector((state) => state.AccountStore);

  // eslint-disable-next-line no-unused-vars
  const [is_expire, setIsExpire] = useState(null);
  const [event, setEvent] = useState(null);
  const [category_list, setCategoryList] = useState([]);
  const [course_list, setCourseList] = useState({});
  const [package_list, setPackageList] = useState({});

  useEffect(() => {
    CommonModel.staticCode({ code_name: 'course_category_set' }).then(({ code_set }) => {
      if (isMount()) {
        dispatch(stored_static_code('course_category_set', code_set));
        setCategoryList(code_set);
      }
    });
  }, []);

  useEffect(() => {
    props.ready();
    dispatch(set_header_type(5, null, { no_bottom_nav: true }));
    dispatch(set_footer_type(0, { read_only: true }));

    if (isMount()) {
      if (props.match.params?.id) {
        EventModel.eventTime({ event_id: props.match.params.id }).then(
          ({ code, event, is_expire, course_replace_sale_info }) => {
            if (code === 200) {
              if (event.is_trial_version && AccountStore?.course_count) {
                alert_and_redirct(
                  alert,
                  props.history,
                  '최초 강의 보유자에게만 제공되는 이벤트입니다.',
                );
                return;
              } else {
                // 대체 판매항목 있을 경우 대체시키기
                if (course_replace_sale_info.length > 0) {
                  if (event.course_set.length > 0) {
                    event.course_set.forEach((course, index) => {
                      course_replace_sale_info.forEach((sale_info) => {
                        if (sale_info.course_id === course.id) {
                          event.course_set[index].min_price = sale_info.price;
                          event.course_set[index].original_price = sale_info.original_price;
                          event.course_set[index].rental_time_in_day = sale_info.rental_time_in_day;
                        }
                      });
                    });
                  }
                }

                setPackageList(event.product_set);
                setCourseList(event.course_set);
                setEvent(event);
                setIsExpire(is_expire);
              }
            } else {
              setIsExpire(true);
            }
          },
        );
      } else {
        alert_and_redirct(alert, props.history, '잘못된 접근입니다.');
      }
    }
  }, []);

  // 커스텀 카테고라이징 func
  const getCategoryName = () => {
    return category_list.map((category) => category.name);
  };

  const getSubCategoryId = () => {
    return getCategoryName(category_list).map((_name) => {
      const find_category = category_list.find((category) => category.name === _name);

      if (find_category?.sub_category_set.length === 0) {
        return [find_category.id];
      } else {
        return find_category.sub_category_set.map((sub) => sub.id);
      }
    });
  };

  const matchSubCategory = () => {
    return getSubCategoryId().map((sub) => {
      return course_list
        .filter((course) => sub.includes(course.category_id))
        .map((course) => {
          return course;
        });
    });
  };

  const makeCourseSet = () => {
    const course_set = [];

    [...Array(category_list.length)].map((_, index) => {
      return course_set.push({
        title: getCategoryName()[index],
        data_set: matchSubCategory()[index],
      });
    });
    return course_set;
  };

  return (
    <>
      <div className='time-event-container tm-event'>
        <Helmet>
          <title>{event?.title}</title>
        </Helmet>

        <div className='time-event-top-header'>
          {event?.main_image_url === null ? (
            <div className='making-banner-image default' />
          ) : (
            <div
              className='making-banner-image'
              style={{
                background:
                  'url(' +
                  replace_cloudfront_url(event?.main_image_url) +
                  ') no-repeat center top/cover',
              }}
            />
          )}
        </div>

        <div className='td-wrapper'>
          <div className='time-event-list'>
            <div className='overview-course-list'>
              {/* package */}
              {event && package_list.length > 0 && (
                <>
                  <h3 className='content-title curation'>추천패키지</h3>
                  <div className='course-list overview-list'>
                    {package_list.map((product) => {
                      return (
                        <PackageCard2
                          product={product}
                          key={product.id}
                          hide_bookmark={false}
                          redirect_to_url={`/package/${product.id}?event_code=${props.match.params.id}`}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              {/* course */}
              {event &&
                makeCourseSet().map((list, index) => {
                  return (
                    <React.Fragment key={index}>
                      {list.data_set.length > 0 && (
                        <>
                          <h3 className='content-title curation'>{list.title}</h3>
                          <div className='course-list overview-list'>
                            {list.data_set.map((course) => {
                              return (
                                <CourseCard2
                                  course={course}
                                  key={course.id}
                                  hide_bookmark={false}
                                  redirect_to_url={`/course/${course.id}?event_code=${props.match.params.id}`}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeEventForTm;
