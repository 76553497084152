import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { init_comma } from '../../../common';
import { useMount } from '../../../util/BearuHooks';
import { MYPAGE_COUPON } from '../../_collections/mypage';
import { dialog_type_custom, useDialogContext } from '../../_common/dialog/DialogContextProvider';
import moment from 'moment';
import EmptyList from './EmptyList';
import AccountModel from '../../../model/AccountModel';
import CouponInfoModal from '../../_common/dialog/CouponInfoModal';

const MyCouponList2 = () => {
  //Modal
  const { showDialog } = useDialogContext();

  const [isMount] = useMount();
  const AccountStore = useSelector((state) => state.AccountStore);
  const [coupon_set, setCouponSet] = useState(null);

  const requestGetCouponSet = async () => {
    let { coupon_set = [] } = await AccountModel.myCouponList();
    if (isMount()) {
      setCouponSet(coupon_set);
    }
  };

  useEffect(() => {
    requestGetCouponSet();
  }, [AccountStore?.coupon_count]);

  return (
    <>
      {coupon_set === null ? null : (
        <>
          {coupon_set?.length > 0 ? (
            <div className='my-category-wrap coupon'>
              {coupon_set?.map((coupon, index) => {
                const couponAllow = [
                  ...(coupon.allow_set
                    ?.filter((allow) => allow.product_type_id <= 4)
                    .map((allow) => `전체 ${allow.product_type_name?.replace(/\s/g, '')}`) || []),
                  coupon.coupon_course_set?.length > 0 ? '단과강의' : '',
                  coupon.coupon_ebook_set?.length > 0 ? '전자책' : '',
                  coupon.coupon_product_set?.length > 0 ? '패키지' : '',
                ]
                  .filter(Boolean)
                  .join(', ');

                return (
                  <div className='coupon-card' key={index}>
                    <div className='coupon-main-info'>
                      <div className='coupon-main-content'>
                        <p className='coupon-name'>{coupon.title}</p>
                        {coupon.price_display_name ? (
                          <h3>{coupon.price_display_name}</h3>
                        ) : (
                          <h2>
                            {init_comma(coupon.discounts)}
                            <p>원</p>
                          </h2>
                        )}
                      </div>

                      <div className='coupon-sub-content'>
                        {couponAllow.length > 0 && (
                          <div className='coupon-target'>{couponAllow} 전용</div>
                        )}

                        <div className='coupon-extra-info'>
                          <h5>{`${moment(coupon.expire_time).format('YYYY-MM-DD HH:mm')} 까지`}</h5>
                          {coupon.allow_set.length ||
                          coupon.coupon_course_set.length ||
                          coupon.can_use_at_extend_course === 0 ||
                          coupon.coupon_product_set.length ? (
                            <div
                              className='coupon-guide'
                              onClick={() => {
                                showDialog({
                                  type: dialog_type_custom,
                                  component: CouponInfoModal,
                                  component_props: {
                                    coupon: coupon,
                                  },
                                });
                              }}
                            >
                              <p>쿠폰 안내</p>
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}/static/v2/png/mypage/ic_info_18px.png`}
                                alt='쿠폰 사용처 안내'
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className='coupon-amount-info'>
                      {coupon.coupon_count === 1 ? (
                        <p>사용가능</p>
                      ) : (
                        <>
                          {coupon.left_count > 1000 ? <p>1000+</p> : <p>{coupon.left_count}장</p>}
                          <p>남음</p>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <EmptyList category={MYPAGE_COUPON} />
          )}
        </>
      )}
    </>
  );
};

export default MyCouponList2;
