import React, { useEffect, useRef, useState } from 'react';
import '../../assets/scss/page/notice_detail.scss';
import CommonModel from '../../model/CommonModel';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { set_footer_type, set_header_type } from '../../redux/common/action';
import { useDispatch } from 'react-redux';
import { alert_and_redirct, convert_redirect_url, replace_cloudfront_url } from '../../common';
import { Helmet } from 'react-helmet';
import { useDialogContext } from '../_common/dialog/DialogContextProvider';

const NoticeDetail = (props) => {
  const dispatch = useDispatch();
  const [seo_title, setSeoTitle] = useState('');
  const [state, setState] = useState({
    notice: {},
    next_notice: {},
  });
  const notice_detail_ref = useRef(null);
  const _is_mount = useRef(false);
  const { alert } = useDialogContext();

  useEffect(() => {
    _is_mount.current = true;
    dispatch(set_footer_type(0));
    return () => {
      _is_mount.current = false;
    };
  }, []);

  useEffect(() => {
    CommonModel.noticeDetail({ notice_id: props.match.params.notice_id }).then(
      ({ code, notice, next_notice }) => {
        if (_is_mount.current) {
          if (code === 200) {
            dispatch(set_header_type(0, 2, { title: notice.notice_category_name }));
            setSeoTitle(`[${notice.notice_category_name}] ${notice.title}`);
            setState({ notice, next_notice });
            notice_detail_ref.current.style.minHeight = 'inherit';
            props.ready();
          } else {
            alert_and_redirct(alert, props.history, '존재하지 않는 게시물입니다.', '/');
          }
        }
      },
    );
  }, [props.match.params.notice_id]);

  useEffect(() => {
    if (state.notice) {
      notice_detail_ref.current.querySelectorAll('a').forEach((item) => {
        let href = item.getAttribute('href');
        item.style.cursor = 'pointer';
        item.onclick = () => {
          if (href.indexOf('bear-u.com') !== -1 || href.indexOf('http') === -1) {
            props.history.push(convert_redirect_url(href));
          } else {
            window.open(href);
          }
        };
        item.removeAttribute('href');
      });
    }
  }, [state.notice]);

  const goToList = () => {
    if (state?.notice.notice_category_name === '이벤트') {
      props.history.push('/service/event');
    } else {
      props.history.push('/service/notice');
    }
  };

  return (
    <div className='notice-detail-container'>
      <Helmet>
        <title>{seo_title}</title>
      </Helmet>
      <div className='td-wrapper'>
        <div className='notice-title-wrapper'>
          <div className='notice-info'>
            <p className={state.notice.notice_category_id === 2 ? 'category event' : 'category'}>
              {state.notice.notice_category_name}
            </p>
            <p className='notice-title'>{state.notice.title}</p>
            <p className='notice-date'>{moment(state.notice.write_time).format('YYYY.MM.DD')}</p>
          </div>
          <div className='notice-btn-wrapper pc-visible'>
            <button onClick={goToList}>목록으로</button>
          </div>
        </div>
        <div className='notice-detail-wrapper'>
          <div
            className='notice-detail'
            dangerouslySetInnerHTML={{ __html: replace_cloudfront_url(state.notice?.content) }}
            ref={notice_detail_ref}
            style={{ minHeight: 800 }}
          />
          <button className='notice-list-btn' onClick={() => props.history.push('/service/notice')}>
            목록으로
          </button>
        </div>
        <div className='notice-next-wrapper'>
          {state.next_notice ? (
            <Link className='next-wrapper' to={`/service/notice/${state.next_notice.id}`}>
              <p className='next'>다음글</p>
              <p className='next-title'>{state.next_notice.title}</p>
              <p className='next-date'>
                {moment(state.next_notice.write_time).format('YYYY.MM.DD')}
              </p>
            </Link>
          ) : (
            <div className='next-wrapper'>
              <p className='next'>다음글</p>
              <p className='next-title'>다음글이 없습니다.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoticeDetail;
