import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter, useLocation } from 'react-router-dom';
import '../../../assets/scss/page/bgd.scss';

const propTypes = {
  typeform_id: PropTypes.string,
  title: PropTypes.string,
  recruitment_start_time: PropTypes.string,
  recruitment_end_time: PropTypes.string,
  reserve_message: PropTypes.string, // !is_not_started 일때 사용
  reserve_button_message: PropTypes.string, // !is_not_started 일때 사용
  reserve_url: PropTypes.string, // !is_not_started 일때 사용
};

const HardStudyTab = () => {
  const location = useLocation().pathname;
  return (
    <ul className='bgd-tab-nav'>
      <li className={`item ${location === '/bgd' && 'active'}`}>
        <Link to={'/bgd'}>빡공단</Link>
      </li>
      <li className={`item ${location === '/hardstudyX' && 'active'}`}>
        <Link to={'/hardstudyX'}>빡공단X</Link>
      </li>
    </ul>
  );
};

HardStudyTab.propTypes = propTypes;

export default withRouter(HardStudyTab);
